"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("@formily/vue");
var composition_api_1 = require("@vue/composition-api");
var COLOR_MAP = {
    primary: '#409EFF',
    danger: '#F56C6C',
    success: '#67C23A',
    info: '#909399',
    warning: '#E6A23C',
};
var Link = (0, composition_api_1.defineComponent)({
    name: 'Link',
    props: ['value', 'content', 'type', 'href'],
    setup: function (curProps, _a) {
        var attrs = _a.attrs;
        var field = (0, vue_1.useField)();
        field.value.decoratorType = field.value.title ? 'FormItem' : null;
        return function () {
            var _a;
            var props = __assign(__assign({}, attrs), curProps);
            var href = '';
            if (props.href) {
                href = props.href;
            }
            else {
                var value = [props.value, props.content].filter(function (item) { return typeof item === 'string'; })[0];
                href = (value === null || value === void 0 ? void 0 : value.startsWith('http')) ? value : "http://".concat(value);
            }
            return (0, vue_1.h)('a', {
                attrs: __assign(__assign({}, props), { href: href, type: props.type || 'primary', target: '_blank' }),
                style: __assign(__assign({}, props.style), { color: "".concat(COLOR_MAP[(_a = props.type) !== null && _a !== void 0 ? _a : 'primary']) }),
            }, {
                default: function () { return [props.content || props.value]; },
            });
        };
    },
});
exports.default = Link;
