import { formatApi, postRelay, fetchScf } from '../baseApi';
import { Message } from 'element-ui';

/**
 * 获取流程定义数据
 * @param {Object} params
 */
export async function getRawFlowData(params) {
  const result = await formatApi({
    Action: 'FlowScheme',
    Method: 'GetRawFlowData',
    Data: {
      InstanceId: params,
    },
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`获取起单时流程数据：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

/**
 * 获取流程引擎列表
 */
export async function getFlowServiceList() {
  const result = await formatApi({
    Action: 'FlowScheme',
    Method: 'GetFlowableSvcList',
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`获取流程引擎列表：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

/**
 * 获取
 * @param {Object} params
 */
export async function getSchemeDefinition(params) {
  const result = await formatApi({
    Action: 'QueryData',
    Method: 'Run',
    SchemaId: 'scheme',
    ReturnTotalRows: 1,
    Data: params,
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`获取流程定义数据接口出错 (Run_scheme)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

/**
 * 新增流程
 * @param {Object} params 流程数据
 * @param {String} user 操作用户
 */
export async function addSchemeDefinition(params, user) {
  const result = await formatApi({
    Action: 'OperateData',
    Method: 'Run',
    SchemaId: 'scheme',
    OpType: 'Add',
    Columns: params,
    AppendInfo: {
      Operator: user,
    },
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`新增流程接口出错 (Add_scheme)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

/**
 * 删除流程
 * @param {String} id 流程Id
 * @param {String} user 操作用户
 */
export async function delSchemeDefinition(id, user) {
  const result = await formatApi({
    Action: 'OperateData',
    Method: 'Run',
    SchemaId: 'scheme',
    OpType: 'Delete',
    Conditions: {
      Id: id,
    },
    AppendInfo: {
      Operator: user,
    },
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`删除流程接口出错 (Delete_scheme)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

/**
 * 更新流程数据
 * @param {String} id 流程Id
 * @param {Object} columns 更新数据
 * @param {String} user 操作用户
 */
export async function updateSchemeDefinition(id, columns, user) {
  const result = await formatApi({
    Action: 'OperateData',
    Method: 'Run',
    SchemaId: 'scheme',
    OpType: 'Update',
    Conditions: {
      Id: id,
    },
    Columns: columns,
    AppendInfo: {
      Operator: user,
    },
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`更新流程数据接口出错 (Update_scheme)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}


/**
 * 批量更新场景目录
 * @param {Array} ids
 * @param {Object} columns
 * @param {String} user
 * @returns {Promise<void>}
 */
export async function batchUpdateSchemeSceneType(columns) {
  const result = await formatApi({
    Action: 'OperateData',
    Method: 'Batch',
    SchemaId: 'scheme',
    OpType: 'Update',
    SystemId: '1',
    BatchData: columns,
  });
  const { data } = result;
  if (data.status !== 0) {
    Message.error(`更新流程数据接口出错 (Update_scheme)：${data.msg}`);
    throw new Error(data.msg);
  }
  return data;
}

/**
 * 更新流程数据到正式环境
 * @param {String} scheme 流程名称
 * @param {String} user 操作用户
 */
export async function updateSchemeToProdEnv(scheme, user) {
  const result = await formatApi({
    Action: 'FlowScheme',
    Method: 'UploadToProductEnv',
    Data: {
      Operator: user,
      SchemeName: scheme,
    },
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`更新流程数据到正式环境 (UploadToProductEnv)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

// 流程转化XML
export async function schemeToParseXml(params) {
  const result = await formatApi({
    Action: 'FlowScheme',
    Data: {
      FlowJsonData: params,
    },
    Method: 'ParseXml',
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`流程转化XML接口出错 (DeployFlowScheme)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

// 部署流程
export async function deployFlowScheme(params) {
  const result = await formatApi({
    Action: 'FlowScheme',
    Data: {
      FlowJsonData: params,
    },
    Method: 'Deploy',
  });

  const { data } = result;
  if (data.status !== 0) {
    throw new Error(data.msg);
  }

  return data;
}

// 查询流程修改日志
export async function getSchemeChangeLog(params) {
  const result = await formatApi({
    Action: 'QueryData',
    Method: 'Run',
    SchemaId: 'scheme_change_log',
    ReturnTotalRows: 1,
    Data: params,
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`查询流程修改日志接口出错 (scheme_change_log)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

/**
 * 根据流程ID查询流程执行日志
 * @param {String} InstanceId 流程ID
 * @param {Boolean} IsFetchChild 是否需要树形结构
 */
export async function getFlowLogById(InstanceId, IsFetchChild, IgnoreDetail) {
  const result = await formatApi({
    Action: 'TaskCenter',
    Data: {
      InstanceId,
      IsFetchChild,
      IgnoreDetail,
    },
    Method: 'QueryWorkflowLog',
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`查询流程任务执行日志出错 (QueryWorkflowLog)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

/**
 * 根据Tnc ID查询模板信息
 * @param {String} TplId Tnc模板ID
 */
export async function getTncInfoById(TplId) {
  const result = await formatApi({
    Action: 'Tnc',
    Data: { TplId },
    Method: 'SearchTncParam',
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`查询TNC模板详情 (SearchTncParam)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

/**
 * 根据id查询发布评审状态
 * @param {String} id 发布评审id
 */
export async function getTapdLaunch(id) {
  const result = await postRelay('/api/tapd/launch', { id });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`查询发布评审状态 (getTapdLaunch)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

/**
 * 新建发布评审
 * @param {String} id 发布评审id
 */
export async function createTapdLaunch(params) {
  const result = await postRelay('/api/tapd/createLaunch', {
    user: params.user,
    title: params.title,
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`新建发布评审 (createTapdLaunch)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

/**
 * 获取支持处理流程任务的云函数列表
 */
export async function getScfFlowBizSupportList() {
  const result = await formatApi({
    Action: 'Nbroker',
    Method: 'ScfFlowBizSupportList',
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`获取云函数列表 (ScfFlowBizSupportList)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

/**
 * 获取云函数列表
 * @param {String} name
 */
export async function getScfFunctionList(name) {
  const result = await fetchScf({
    Action: 'Scf',
    CloudFunctionName: name,
    CloudFunctionReqData: {
      Data: {},
      Method: 'flowbiz_discovery',
      Module: 'buildin',
    },
    RequestEnvironment: process.env.VUE_APP_CONFIG_GROUP === 'prod' ? 'product' : 'test',
    Method: 'Invoke',
    RequestMode: 'sync',
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`获取云函数列表 (flowbiz_discovery)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data.data.FuncResult;
}

// ------------------- 自定义流程变量输入参数api ------------------- //

/**
 *  获取自定义流程变量输入参数
 * @param {Object} conditions 查询条件
 * @param {Object} limit 翻页
 */
export async function getSchemeVariables(conditions, limit) {
  const result = await formatApi({
    Action: 'QueryData',
    Method: 'Run',
    SchemaId: 'scheme_custom_variables',
    ReturnTotalRows: 1,
    Data: {
      ResultColumns: {
        BindLogic: '',
        DataOptions: '',
        DefaultValue: '',
        Id: '',
        IsPageShow: '',
        IsRequired: '',
        Memo: '',
        Operator: '',
        Placeholder: '',
        SchemeName: '',
        Type: '',
        UpdateTime: '',
        VarKey: '',
        VarName: '',
      },
      SearchCondition: conditions,
      Sorts: [
        {
          Column: 'Id',
          SortType: 'desc',
        },
      ],
      Limit: limit,
    },
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`获取自定义流程变量输入参数接口出错 (scheme_custom_variables)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

/**
 * 新增自定义流程变量输入参数
 * @param {Object} columns
 */
export async function addSchemeVariables(columns) {
  const result = await formatApi({
    Action: 'OperateData',
    Method: 'Run',
    SchemaId: 'scheme_custom_variables',
    OpType: 'Add',
    Columns: columns,
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`新增自定义流程变量输入参数接口出错 (Add_scheme_custom_variables)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

/**
 * 修改自定义流程变量输入参数
 * @param {Object} conditions
 * @param {Object} columns
 */
export async function updateSchemeVariables(conditions, columns) {
  const result = await formatApi({
    Action: 'OperateData',
    Method: 'Run',
    SchemaId: 'scheme_custom_variables',
    OpType: 'Update',
    Columns: columns,
    Conditions: conditions,
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`修改流程变量输入参数接口出错 (Update_scheme_custom_variables)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

/**
 * 删除自定义流程变量输入参数
 * @param {String} id 流程id
 */
export async function delSchemeVariables(id) {
  const result = await formatApi({
    Action: 'OperateData',
    Method: 'Run',
    SchemaId: 'scheme_custom_variables',
    OpType: 'Delete',
    Conditions: { Id: id },
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`删除自定义流程变量输入参数接口出错 (Delete_scheme_custom_variables)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

/**
 * 调用自定义流程变量绑定逻辑BindLogic
 * @param {Object} params
 */
export async function getVarsByBindLogic(params) {
  const result = await formatApi({
    Action: 'FlowScheme',
    Data: params,
    Method: 'VarCallBindLogic',
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`调用自定义流程变量绑定逻辑BindLogic接口出错 (VarCallBindLogic)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

// ------------------- 目录api ------------------- //

// 获取场景类型数结构数据
export async function getSceneTypeTreeData() {
  const result = await formatApi({
    Action: 'Query',
    Method: 'GetSceneTypeTree',
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`获取场景类型数结构数据（GetSceneTypeTree）出错：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

// 获取场景类型数据
export async function getSchemeSceneTypeData(params) {
  const result = await formatApi({
    Action: 'QueryData',
    Method: 'Run',
    SchemaId: 'scheme_scene_type',
    ReturnTotalRows: 1,
    Data: params,
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`获取场景类型数据（scheme_scene_type）出错：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

/**
 * 新增场景类型数据
 * @param {Object} columns 数据
 * @param {String} user 操作用户
 */
export async function addSchemeSceneType(columns, user) {
  const result = await formatApi({
    Action: 'OperateData',
    Method: 'Run',
    SchemaId: 'scheme_scene_type',
    OpType: 'Add',
    Columns: columns,
    AppendInfo: {
      Operator: user,
    },
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`新增场景类型数据（scheme_scene_type）出错：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

/**
 * 更新场景类型数据
 * @param {String} id 场景id
 * @param {Object} columns 场景数据
 * @param {String} user 操作用户
 */
export async function updateSchemeSceneType(id, columns, user) {
  const result = await formatApi({
    Action: 'OperateData',
    Method: 'Run',
    SchemaId: 'scheme_scene_type',
    OpType: 'Update',
    Columns: columns,
    AppendInfo: {
      Operator: user,
    },
    Conditions: {
      Id: id,
    },
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`更新场景类型数据（scheme_scene_type）出错：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

/**
 * 场景目录剪切移动
 * @param {String} id
 * @param {String} parentId
 * @param {String} user
 * @returns {Promise<any>}
 */
export async function moveCatalog(id, parentId, user) {
  const result = await formatApi({
    Action: 'OperateData',
    Method: 'Run',
    SystemId: '1',
    SchemaId: 'scheme_scene_type',
    OpType: 'Update',
    AppendInfo: {
      Operator: user,
    },
    Columns: {
      ParentId: parentId,
    },
    Conditions: {
      Id: id,
    },
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`更新场景类型数据（scheme_scene_type）出错：${data.msg}`);
    throw new Error(data.msg);
  }
  return data;
}

/**
 * 删除场景类型数据
 * @param {String} id 场景id
 * @param {String} user 操作用户
 */
export async function delSchemeSceneType(id, user) {
  const result = await formatApi({
    Action: 'OperateData',
    Method: 'Run',
    SchemaId: 'scheme_scene_type',
    OpType: 'Delete',
    Conditions: {
      Id: id,
    },
    AppendInfo: {
      Operator: user,
    },
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`删除场景类型数据（scheme_scene_type）出错：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

// ------------------- SLA/OLA api ------------------- //

// 获取计算时间算法列表

/**
 * 查询SLA配置列表
 * @param {Object} conditions 查询条件
 * @param {Object} limit 翻页
 */
export async function getSlaConfigList(conditions, limit) {
  const result = await formatApi({
    Action: 'QueryData',
    Method: 'Run',
    SchemaId: 'tm_config_sla',
    ReturnTotalRows: 1,
    Data: {
      ResultColumns: {
        Arithmetic: '',
        Id: '',
        LevelId: '',
        LevelName: '',
        LimitedArithmetic: '',
        LimitedHours: '',
        ModifyTime: '',
        ProcessDefinitionKey: '',
        Source: '',
      },
      SearchCondition: conditions,
      Sorts: [
        {
          Column: 'Id',
          SortType: 'desc',
        },
      ],
      Limit: limit,
    },
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`查询SLA配置列表 (tm_config_sla) 出错：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

/**
 * 查询OLA配置列表
 * @param {Object} conditions 查询条件
 * @param {Object} limit 翻页
 */
export async function getOlaConfigList(conditions, limit) {
  const result = await formatApi({
    Action: 'QueryData',
    Method: 'Run',
    SchemaId: 'tm_config_ola',
    ReturnTotalRows: 1,
    Data: {
      ResultColumns: {
        Arithmetic: '',
        Id: '',
        IsManualTask: '',
        LevelId: '',
        LevelName: '',
        LimitedArithmetic: '',
        LimitedHours: '',
        ModifyTime: '',
        ProcessDefinitionKey: '',
        Source: '',
        TaskKey: '',
        TaskName: '',
      },
      SearchCondition: conditions,
      Sorts: [
        {
          Column: 'Id',
          SortType: 'desc',
        },
      ],
      Limit: limit,
    },
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`查询OLA配置列表 (tm_config_ola) 出错：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

// 获取计算时间算法列表
export async function getCalcArithmetic(params) {
  const result = await formatApi({
    Action: 'Ola',
    Method: 'CalcArithmetic',
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`获取计算时间算法列表 (CalcArithmetic) 出错：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

// 获取分级级别字典表
export async function getDicLevels(params) {
  const result = await formatApi({
    Action: 'Ola',
    Method: 'DicLevels',
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`获取分级级别字典表 (DicLevels) 出错：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

// 获取取时限算法列表
export async function getLimitedArithmetic(params) {
  const result = await formatApi({
    Action: 'Ola',
    Method: 'LimitedArithmetic',
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`获取取时限算法列表 (LimitedArithmetic) 出错：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

// OLA数据上报接口
export async function receiveOLAData(params) {
  const result = await formatApi({
    Action: 'Ola',
    Data: params,
    Method: 'ReceiveOLAData',
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`OLA数据上报接口 (ReceiveOLAData) 出错：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

// OLA催办可用抄送对象列表
export async function getUrgeCCList() {
  const result = await formatApi({
    Action: 'Ola',
    Method: 'UrgeCCList',
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`OLA催办可用抄送对象列表 (UrgeCCList) 出错：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

export async function addSchemeOutVariables(params, user) {
  const result = await formatApi({
    Action: 'OperateData',
    Method: 'Run',
    SchemaId: 'scheme',
    OpType: 'Update',
    Columns: params.columns,
    Conditions: params.conditions,
    AppendInfo: {
      Operator: user,
    },
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`新增自定义流程变量输输出参数接口出错 (scheme)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

// 获取设备类型
export async function getObmDevices() {
  const currentDevicesTypes = JSON.parse(sessionStorage.getItem('currentDevicesTypes'));
  if (currentDevicesTypes) {
    return currentDevicesTypes;
  }
  const result = await formatApi({
	  Action: 'Idcdb',
	  Method: 'Query',
    SystemId: 2,
    table: 'devicetypes',
  	fields: ['devicetypes_name', 'devicetypes_enAbbreviation', 'devicetypes_belongprofession', 'devicetypes_id'],
  	conditions: [],
  	start: 0, limit: 0,
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`获取设备列表：${data.msg}`);
    throw new Error(data.msg);
  }
  sessionStorage.setItem('currentDevicesTypes', JSON.stringify(data.data));
  return data.data;
}

// // 获取事件、信号列表
export async function getEventOrSignal(params) {
  const { methods } = params;
  const result = await formatApi({
    SystemId: '2',
    Action: 'Nbroker',
    Method: 'ThingModeldData',
    // methods: 'BAT/event',
    methods,
    isThingModel: 'true',
    ServiceUrl: 'http://nbroker-common:8080/nBroker/api/v1/task',
    VueOperateUser: 'kerwinzlin',
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`获取设备列表：${data.msg}`);
    throw new Error(data.msg);
  }

  return data.data;
}
