import { h, useField, useParentForm } from '@formily/vue';
import { observer } from '@formily/reactive-vue';
import { defineComponent, onMounted, ref } from '@vue/composition-api';
import { Button as ElButton } from 'element-ui';
import { Sticky as VanSticky } from 'vant';

export const Button = observer(defineComponent({
  name: 'FSubmit',
  props: ['onClick', 'value', 'style'],
  setup(props, { attrs, listeners }) {
    const formRef = useParentForm();
    const el = ref();
    const isFixed = ref(false);
    onMounted(() => {
      el.value = document.querySelector('.formily-todo');
    });
    const field = useField().value;
    const { style } = field.componentProps;
    field.componentProps.style = {};
    return () => {
      const {
        onClick = listeners?.click,
        value,
      } = props;
      console.log(attrs);

      const form = formRef?.value;
      const field = useField().value;
      console.log(field);
      return h(
        VanSticky,
        {
          attrs: {
            // ...attrs,
            container: el.value,
            offsetTop: 20,
            zIndex: 8,
            ref: 'sticky',
          },
          style: null,
          on: {
            ...listeners,
            change: (fixed) => {
              console.log(el.value.offsetLeft);
              isFixed.value = fixed;
            },
          },
        },
        {
          default: () => [
            h(
              ElButton,
              {

                attrs: {
                  loading:
                  attrs.loading !== undefined ? attrs.loading : form?.submitting,
                  type: 'primary',

                },
                style: {
                  marginLeft: isFixed.value ? `${el.value.getBoundingClientRect().left}px` : '',
                  ...style,
                },

                on: {
                  ...listeners,
                  click: (e) => {
                    e.stopPropagation();
                    if (onClick) {
                      if (onClick(e, value) === false) return;
                    }
                  },
                },
              },
              {
                default: () => [attrs.content || props.value || '按钮'],
              },
            )],
        },
      );
    };
  },
}));

export default Button;
