<template>
  <div>
    <div v-for="viewItem in todoViewsData" :key="viewItem.name">
      <!-- kv类型 兼容流程中间数据 -->
      <van-cell
        v-if="
          viewItem.type === 'KVField' ||
          (!viewItem.Type && !viewItem.type) ||
          viewItem.Type === 'KVField'
        "
        :title="viewItem.name ? viewItem.name + ':' : viewItem.KeyName + ':'"
        label-width="120px"
        :value="viewItem.data || viewItem.Value"
      />

      <!-- input类型 -->
      <van-field
        v-else-if="viewItem.type === 'TextArea'"
        v-model="viewItem.data"
        readonly
        type="textarea"
        autosize
        :label="viewItem.name + ':'"
      />

      <!-- link类型 兼容流程中间数据 -->
      <van-cell
        is-link
        v-else-if="viewItem.type === 'Link' || viewItem.Type === 'Link'"
        :title="viewItem.KeyName ? viewItem.KeyName + ':' : viewItem.name + ':'"
      >
        <a v-if="viewItem.Value" :href="viewItem.Value" class="f12" type="primary" target="_blank">
          {{ viewItem.Value }}
        </a>
        <a v-else :href="viewItem.data" class="f12" type="primary" target="_blank">
          {{ viewItem.data }}
        </a>
      </van-cell>

      <!-- json类型 -->
      <van-field
        v-else-if="viewItem.type === 'Json' || viewItem.Type === 'Json'"
        readonly
        label-width="0"
      >
        <template #input>
          <div class="w">
            <div class="mb10">{{ viewItem.KeyName ? viewItem.KeyName : viewItem.name }}</div>
            <MonacoJsonEditor
              style="width: 100%; height: 100px"
              :content="getJSON(viewItem.Value ? viewItem.Value : viewItem.data)"
              :editorOption="{
                theme: 'vs',
                readOnly: true,
                fontSize: 13,
              }"
            />
          </div>
        </template>
      </van-field>

      <!-- 无序table类型 -->
      <van-field
        v-else-if="viewItem.type === 'Table' || viewItem.Type === 'Table'"
        label-width="0"
        style="padding: 0"
      >
        <template #input>
          <van-collapse
            class="w"
            accordion
            :value="viewItem.openStatus ? 'detail' : ''"
            @change="handleTableChange($event, viewItem)"
          >
            <van-collapse-item name="detail">
              <template #title>
                <span class="mb10 mr20">{{
                  viewItem.KeyName ? viewItem.KeyName : viewItem.name
                }}</span>

                <span class="color-brand">查看详情</span>
              </template>
              <div class="w">
                <el-table
                  v-if="Array.isArray(viewItem.data) && !viewItem.Value"
                  :data="viewItem.data"
                  :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
                  border
                  class="w"
                  width="100%"
                >
                  <el-table-column
                    v-for="(val, key) in viewItem.data[0]"
                    :key="key"
                    :prop="key"
                    :label="key"
                    align="center"
                  >
                    <template v-slot="{ row }">
                      <div :class="checkLineWrap(row[key])">
                        {{ row[key] }}
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <el-table
                  v-else
                  :data="JSON.parse(viewItem.Value)"
                  :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
                  border
                >
                  <el-table-column
                    v-for="(val, key) in JSON.parse(viewItem.Value)[0]"
                    :key="key"
                    :prop="key"
                    :label="key"
                    align="center"
                  >
                    <template v-slot="{ row }">
                      <div :class="checkLineWrap(row[key])">
                        {{ row[key] }}
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </van-collapse-item>
          </van-collapse>
        </template>
      </van-field>

      <!-- 有序OrderTable类型 -->
      <van-field
        v-else-if="viewItem.type === 'OrderTable' || viewItem.Type === 'OrderTable'"
        label-width="0"
      >
        <template #input>
          <van-collapse
            class="w"
            accordion
            :value="viewItem.openStatus ? 'detail' : ''"
            @change="handleTableChange($event, viewItem)"
          >
            <van-collapse-item name="detail">
              <template #title>
                <span class="mb10 mr20">{{
                  viewItem.KeyName ? viewItem.KeyName : viewItem.name
                }}</span>

                <span class="color-brand">查看详情</span>
              </template>
              <div class="w">
                <el-table
                  v-if="Array.isArray(viewItem.data) && !viewItem.Value"
                  :data="viewItem.data"
                  :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
                  border
                  class="w"
                  width="100%"
                >
                  <el-table-column
                    v-for="(key, index) in viewItem.header"
                    :key="index"
                    :prop="key"
                    :label="key"
                    align="center"
                  >
                    <template v-slot="{ row }">
                      <div :class="checkLineWrap(row[key])">
                        {{ row[key] }}
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <el-table
                  v-else
                  :data="JSON.parse(viewItem.Value).detail"
                  :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
                  border
                >
                  <el-table-column
                    v-for="(key, index) in JSON.parse(viewItem.Value).header"
                    :key="index"
                    :prop="key"
                    :label="key"
                    align="center"
                  >
                    <template v-slot="{ row }">
                      <div :class="checkLineWrap(row[key])">
                        {{ row[key] }}
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </van-collapse-item>
          </van-collapse>
        </template>
      </van-field>
      <!-- <el-form-item
          v-else-if="viewItem.type === 'OrderTable' || viewItem.Type === 'OrderTable'"
          :label="viewItem.KeyName ? viewItem.KeyName + ':' : viewItem.name + ':'">
        <el-col class="left-collapse" :span="20">
          <el-collapse
            :value="viewItem.openStatus ? 'detail' : '' || viewItem.IsHidden === '0' ? 'detail' : ''"
            @change="handleOrderTableChange($event, viewItem)"
          >
            <el-collapse-item name="detail">
              <template v-slot:title>
                <span class="color-brand">查看详情</span>
              </template>
              <el-table
                v-if="Array.isArray(viewItem.data) && !viewItem.Value"
                :data="viewItem.data"
                :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
                border
              >
                <el-table-column
                  v-for="(key, index) in viewItem.header"
                  :key="index"
                  :prop="key"
                  :label="key"
                  align="center"
                >
                  <template v-slot="{ row }">
                    <div :class="checkLineWrap(row[key])">
                      {{ row[key] }}
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <el-table
                  v-else
                  :data="JSON.parse(viewItem.Value).detail"
                  :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
                  border
              >
                <el-table-column
                    v-for="(key, index) in (JSON.parse(viewItem.Value).header)"
                    :key="index"
                    :prop="key"
                    :label="key"
                    align="center"
                >
                  <template v-slot="{ row }">
                    <div :class="checkLineWrap(row[key])">
                      {{ row[key] }}
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </el-collapse-item>
          </el-collapse>
        </el-col>
      </el-form-item> -->

      <!-- 单图表类型 -->
      <!-- <el-form-item
          v-else-if="viewItem.type === 'Plot' || viewItem.Type === 'Plot'"
          :label="viewItem.KeyName ? viewItem.KeyName + ':' : viewItem.name + ':'">
        <el-col class="left-collapse" :span="20">
          <el-collapse
              accordion
              @change="handlePlotChange($event, viewItem, todoIndex)"
          >
            <el-collapse-item name="detail">
              <template v-slot:title>
                <span class="color-brand">查看详情</span>
              </template>
              <div v-if="viewItem.msg" align="center" class="color-stext">
                {{ viewItem.msg }}
              </div>
              <div v-else class="base-charts">
                <div class="chart-title">
                  {{ viewItem.name }}
                </div>
                <div :id="`${viewItem.name}_${todoIndex}_0_chart`" class="h"></div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </el-col>
      </el-form-item> -->

      <!-- 多图表类型 -->
      <!-- <el-form-item
          v-else-if="viewItem.type === 'MultiPlot' || viewItem.Type === 'MultiPlot'"
          :label="viewItem.KeyName ? viewItem.KeyName + ':' : viewItem.name + ':'">
        <el-col class="left-collapse" :span="20">
          <el-collapse
              accordion
              @change="handleMultiPlotChange($event, viewItem, todoIndex)"
          >
            <el-collapse-item name="detail">
              <template v-slot:title>
                <span class="color-brand">查看详情</span>
              </template>
              <div v-if="viewItem.msg" align="center" class="color-stext">
                {{ viewItem.msg }}
              </div>
              <div v-else>
                <div
                  v-for="(graphItem, graphIndex) in (viewItem.Value ? JSON.parse(viewItem.Value) : viewItem.data)"
                  :key="graphIndex"
                  class="base-charts mt15"
                >
                  <div class="chart-title">
                    {{ graphItem.graph_name }}
                  </div>
                  <div
                    :id="`${graphItem.graph_name}_${todoIndex}_${graphIndex}_chart`"
                    class="h"
                  ></div>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </el-col>
      </el-form-item> -->
    </div>
  </div>
</template>

<script>
// import echarts from 'echarts';
import MonacoJsonEditor from '@/components/MonacoJsonEditor';
// import JsonDialog from '@/components/JsonDialog';
import { debounce } from 'lodash';

export default {
  name: 'TodoView',
  components: {
    MonacoJsonEditor,
    // JsonDialog,
  },
  props: {
    todoIndex: {
      type: Number,
    },
    todoViewsData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      charts: [],
      resizeHandler: null,
    };
  },
  mounted() {
    this.resizeHandler = debounce(() => {
      this.charts.length && this.charts.forEach((chart) => chart && chart.resize());
    }, 100);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  methods: {
    // 返回JSON
    getJSON(str) {
      try {
        const newStr = str
          .replace(/'/g, '"')
          .replace(/True/g, true)
          .replace(/False/g, false)
          .replace(/None/g, null);
        return JSON.stringify(JSON.parse(newStr), null, 2);
      } catch (err) {}
    },

    // 展开Table详情
    handleTableChange(val, todoItem) {
      todoItem.openStatus = !todoItem.openStatus;
      if (!val || todoItem.initStatus) {
        return;
      }
      if (!todoItem.data) {
        todoItem.data = [];
      }
      if (!todoItem.header) {
        const [headerData = {}] = todoItem.data;
        this.$set(todoItem, 'header', todoItem.data.length ? headerData : []);
      }
      todoItem.initStatus = true;
    },

    // 展开OrderTable详情
    handleOrderTableChange(val, todoItem) {
      if (!val || todoItem.initStatus) {
        return;
      }
      if (!todoItem.data) {
        todoItem.data = [];
      }
      if (!todoItem.header) {
        const [headerData = {}] = todoItem.data;
        const headerMap = {};
        Object.keys(headerData).forEach((key, index) => {
          headerMap[index] = key;
        });
        this.$set(todoItem, 'header', todoItem.data.length ? headerMap : []);
      }
      todoItem.initStatus = true;
    },

    // 展开chart详情 - 此处有改动
    handlePlotChange(val, todoItem, todoIndex) {
      if (val) {
        if (!Array.isArray(todoItem.data) && !Array.isArray(JSON.parse(todoItem.Value))) {
          this.$set(todoItem, 'msg', '暂无数据');
          return;
        }
        this.handleChart(todoItem, 0, todoIndex);
      }
    },

    // 展开chart详情
    handleMultiPlotChange(val, todoItem, todoIndex) {
      if (val) {
        if (!Array.isArray(todoItem.data) && !Array.isArray(JSON.parse(todoItem.Value))) {
          this.$set(todoItem, 'msg', '暂无数据');
          return;
        }
        if (todoItem.Value) {
          JSON.parse(todoItem.Value).forEach((item, graphIndex) => {
            this.handleChart(item, graphIndex, todoIndex);
          });
        } else {
          todoItem.data.forEach((item, graphIndex) => {
            this.handleChart(item, graphIndex, todoIndex);
          });
        }
      }
    },

    // 图表渲染 - 此处有改动
    handleChart(chartData, graphIndex, todoIndex) {
      try {
        const series = [];
        // 这里需要对传入的JSON字符串进行解析
        const dataDetail = chartData.Value ? JSON.parse(chartData.Value) : chartData.data;
        // chartData.data.forEach((item) => {
        dataDetail.forEach((item) => {
          series.push({
            name: item.name ? item.name : 'test',
            data: this.handleChartData(item.data),
            type: 'line',
            showSymbol: false,
            lineStyle: {
              width: 2,
              opacity: 0.8,
            },
          });
        });

        // todo 改动 ：const seriesName = chartData.data.map(item => item.name);
        const seriesName = dataDetail.map((item) => item.name);
        this.$nextTick(() => {
          const graphName = chartData.name || chartData.graph_name;
          const chartDom = document.getElementById(`${graphName}_${todoIndex}_${graphIndex}_chart`);
          let chart = echarts.getInstanceByDom(chartDom);
          if (!chart) {
            chart = echarts.init(chartDom);
            this.charts.push(chart);
            chart.setOption({
              // color: ['#5793f3', '#d14a61', '#675bba'],
              legend: {
                data: seriesName,
                // right: '4%',
                bottom: 10,
              },
              grid: {
                left: '5%',
                right: '5%',
                bottom: '22%',
                containLabel: true,
              },
              tooltip: {
                trigger: 'axis',
                extraCssText: 'min-width: 400px; max-width: 550px',
                appendToBody: true,
                axisPointer: {
                  type: 'cross',
                  snap: true,
                  label: {
                    backgroundColor: '#6a7985',
                    formatter: (params) => {
                      if (params.seriesData.length < 1) {
                        return this.convertUnit(params.value);
                      }
                      return this.formatTime(params.value);
                    },
                  },
                },
                formatter: (params) => {
                  let html = params[0].axisValueLabel;
                  params.forEach((series) => {
                    html += this.handleTooltipHtml(series);
                  });
                  return html;
                },
              },
              xAxis: [
                {
                  type: 'time',
                  splitLine: { show: false },
                },
              ],
              yAxis: [
                {
                  name: this.yAxisName,
                  type: 'value',
                  // boundaryGap: [0, '80%'],
                  min: 0,
                  max(val) {
                    return val.max * 1.5;
                  },
                  minInterval: 0,
                  axisLabel: {
                    formatter: this.convertUnit,
                  },
                },
              ],
              toolbox: {
                show: true,
                feature: {
                  mark: { show: true },
                  dataZoom: { show: true },
                  // dataView: { show: true },
                  // magicType: { show: true, type: ['line', 'bar', 'stack', 'tiled'] },
                  restore: { show: true },
                },
              },
              series,
            });

            // 绑定resize事件
            window.addEventListener('resize', this.resizeHandler);
          }
        });
      } catch (err) {}
    },

    // 图表数据格式化
    handleChartData(data) {
      const chartData = [];
      data.forEach((item) => {
        chartData.push([item.value_x, item.value_y, item.tooltip]);
      });
      return chartData;
    },

    // 格式化时间（time \n date）
    formatTime(time) {
      const date = new Date(time);
      return `${`0${date.getHours()}`.slice(-2)}:${`0${date.getMinutes()}`.slice(-2)}\n${`0${
        date.getMonth() + 1
      }`.slice(-2)}-${`0${date.getDate()}`.slice(-2)}`;
    },

    // 流量数据单位转化
    convertUnit(value) {
      if (!value) {
        return value;
      }
      if (value < 1000) {
        return value.toFixed(2);
      }
      if (value < 1000 * 1000) {
        return `${(value / 1000).toFixed(2)}K`;
      }
      if (value < 1000 * 1000 * 1000) {
        return `${(value / 1000 / 1000).toFixed(2)}M`;
      }
      return `${(value / 1000 / 1000 / 1000).toFixed(2)}G`;
    },

    // 是否有tooltip
    addTooltip(data) {
      const tooltip = data[2];

      return tooltip ? `${this.convertUnit(data[1])}  ${tooltip}` : this.convertUnit(data[1]);
    },

    // tooltip文本渲染
    handleTooltipHtml(data) {
      let html = '';
      html += '<br>';
      html += `<div class="echarts-tooltips-spot" style="background-color:${data.color}"></div>`;
      html += `<span class="line-wrap" style="background-color: ${data.color}">${
        data.seriesName
      } ${this.addTooltip(data.value)}</span>`;
      return html;
    },

    // pre-line
    checkLineWrap(val) {
      if (typeof val === 'string') {
        return val.includes('\n') ? 'pre-line' : '';
      }
      return '';
    },
  },
};
</script>

<style lang="less">
.base-charts {
  position: relative;
  width: 100%;
  height: 400px;
  border: 1px solid #dcdcdc;
  background-color: white;
}

.chart-title {
  position: absolute;
  top: 15px;
  width: 100%;
  text-align: center;
}

.echarts-tooltips-spot {
  display: inline-block;
  margin-right: 5px;
  border-radius: 5px;
  width: 10px;
  height: 10px;
}
</style>
