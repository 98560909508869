// /**
//  * @author krisjczhang
//  * @email krisjczhang@tencent.com
//  * @create date 2020-03-30 15:59:05
//  * @modify date 2020-03-30 15:59:05
//  * @desc [人员信息处理]]
//  */

import { formatApi, newformatApi } from '../baseApi';
import { Message } from 'element-ui';

// 获取人员列表
export async function getPersonList(params) {
  try {
    const result = await formatApi({
      Action: 'QueryData',
      Method: 'Run',
      SchemaId: 'hm_hc_info',
      ReturnTotalRows: 1,
      Data: params,
    });

    return result.data;
  } catch (error) {
  }
}

// 新增人员信息
export async function addPerson(params, user) {
  try {
    const result = await formatApi({
      Action: 'OperateData',
      Method: 'Run',
      SchemaId: 'contact_person_info',
      OpType: 'Add',
      Columns: params,
      AppendInfo: {
        Operator: user,
      },
    });
    return result.data;
  } catch (error) {
  }
}

// 更新人员信息
export async function updatePerson(id, data, operator) {
  if (data.Id) {
    delete data.Id;
  }
  try {
    const params = {
      Action: 'OperateData',
      Method: 'Run',
      SystemId: '2',
      SchemaId: 'hm_hc_info',
      OpType: 'Update',
      AppendInfo: {
        Operator: operator,
      },
      Columns: data,
      Conditions: {
        id,
      },
    };
    const result = await formatApi(params);
    return result.data;
  } catch (error) {
  }
}

// 更新计费信息
export async function updateBillingPosts(data) {
  try {
    const params = {
      Action: 'Ticket',
      Method: 'Create',
      OpType: 'Update',
      Data: data,
    };
    const result = await formatApi(params);

    return result.data;
  } catch (error) {
  }
}

// 删除人员信息
export async function deletePerson(id, operator) {
  try {
    const params = {
      Action: 'OperateData',
      Method: 'Run',
      SchemaId: 'contact_person_info',
      OpType: 'Delete',
      AppendInfo: {
        Operator: operator,
      },
      Conditions: {
        Id: id,
      },
    };
    const result = await formatApi(params);
    return result.data;
  } catch (error) {
  }
}

// 新增组信息
export async function addGroup(params, user) {
  try {
    const result = await formatApi({
      Action: 'OperateData',
      Method: 'Run',
      SchemaId: 'contact_group_info',
      OpType: 'Add',
      Columns: params,
      AppendInfo: {
        Operator: user,
      },
    });

    return result.data;
  } catch (error) {
  }
}

// 更新组信息
export async function updateGroup(id, data, operator) {
  if (data.Id) {
    delete data.Id;
  }
  try {
    const params = {
      Action: 'OperateData',
      Method: 'Run',
      SchemaId: 'contact_group_info',
      OpType: 'Update',
      AppendInfo: {
        Operator: operator,
      },
      Columns: data,
      Conditions: {
        Id: id,
      },
    };
    const result = await formatApi(params);
    return result.data;
  } catch (error) {
  }
}

// 删除组信息
export async function deleteGroup(id, operator) {
  try {
    const params = {
      Action: 'OperateData',
      Method: 'Run',
      SchemaId: 'contact_group_info',
      OpType: 'Delete',
      AppendInfo: {
        Operator: operator,
      },
      Conditions: {
        Id: id,
      },
    };
    const result = await formatApi(params);
    return result.data;
  } catch (error) {
  }
}

// 获取人员组列表
export async function getGroupList(params) {
  try {
    const result = await formatApi({
      Action: 'QueryData',
      Method: 'Run',
      SchemaId: 'contact_group_info',
      ReturnTotalRows: 1,
      Data: params,
    });

    return result.data;
  } catch (error) {
  }
}

// 新增供应商信息
export async function addSupplier(params, user) {
  try {
    const result = await formatApi({
      Action: 'OperateData',
      Method: 'Run',
      SchemaId: 'contact_supplier_info',
      OpType: 'Add',
      Columns: params,
      AppendInfo: {
        Operator: user,
      },
    });

    return result.data;
  } catch (error) {
  }
}

// 更新供应商信息
export async function updateSupplier(id, data, operator) {
  if (data.Id) {
    delete data.Id;
  }
  try {
    const params = {
      Action: 'OperateData',
      Method: 'Run',
      SchemaId: 'hm_supply_info',
      OpType: 'Update',
      AppendInfo: {
        Operator: operator,
      },
      Columns: data,
      Conditions: {
        id,
      },
    };
    const result = await formatApi(params);
    return result.data;
  } catch (error) {
  }
}

// 查询供应商信息
export async function getSupplierList(params) {
  try {
    const result = await formatApi({
      Action: 'QueryData',
      Method: 'Run',
      SchemaId: 'hm_supply_info',
      ReturnTotalRows: 1,
      Data: params,
    });

    return result.data;
  } catch (error) {
  }
}

// 删除供应商信息
export async function deleteSupplier(id, operator) {
  try {
    const params = {
      Action: 'OperateData',
      Method: 'Run',
      SchemaId: 'contact_supplier_info',
      OpType: 'Delete',
      AppendInfo: {
        Operator: operator,
      },
      Conditions: {
        Id: id,
      },
    };
    const result = await formatApi(params);
    return result.data;
  } catch (error) {
  }
}

// 新增人员组关系
export async function addRelation(params, user) {
  try {
    const result = await formatApi({
      Action: 'OperateData',
      Method: 'Run',
      SchemaId: 'contact_person_group_relation',
      OpType: 'Add',
      Columns: params,
      AppendInfo: {
        Operator: user,
      },
    });
    return result.data;
  } catch (error) {
  }
}

// 批量添加人员和组的关系
export async function batchAddRelation(params) {
  try {
    const result = await formatApi({
      Action: 'OperateData',
      BatchData: params,
      Method: 'Batch',
      OpType: 'Add',
      SchemaId: 'contact_person_group_relation',
    });

    return result.data;
  } catch (error) {
  }
}

// 批量添加人员和组的关系
export async function batchDelRelation(params) {
  try {
    const result = await formatApi({
      Action: 'OperateData',
      BatchData: params,
      Method: 'Batch',
      OpType: 'Delete',
      SchemaId: 'contact_person_group_relation',
    });

    return result.data;
  } catch (error) {
  }
}

// 查询所属组信息
export async function getGroupRelation(pid) {
  try {
    const result = await formatApi({
      Action: 'QueryData',
      Method: 'Run',
      SchemaId: 'contact_person_group_relation',
      ReturnTotalRows: 1,
      Data: {
        ResultColumns: {
          Id: '',
          GroupName: '',
          Mail: '',
          GroupId: '',
        },
        SearchCondition: {
          PersonId: pid,
        },
      },
    });

    return result.data;
  } catch (error) {
  }
}

// 查询组内成员信息
export async function getPersonRelation(params) {
  try {
    const result = await formatApi({
      Action: 'QueryData',
      Method: 'Run',
      SchemaId: 'contact_person_group_relation',
      ReturnTotalRows: 1,
      Data: {
        ResultColumns: {
          Id: '',
          PersonName: '',
          Mail: '',
          GroupName: '',
          GroupId: '',
          PersonId: '',
          PersonType: '',
        },
        SearchCondition: params,
      },
    });

    return result.data;
  } catch (error) {
  }
}

// 从人员组关系中删除
export async function deletePersonRelation(id, operator) {
  try {
    const params = {
      Action: 'OperateData',
      Method: 'Run',
      SchemaId: 'contact_person_group_relation',
      OpType: 'Delete',
      AppendInfo: {
        Operator: operator,
      },
      Conditions: {
        Id: id,
      },
    };
    const result = await formatApi(params);
    return result.data;
  } catch (error) {
  }
}

// 批量删除人员组关系表中的内容
export async function batchDeletePersonRelation(params) {
  try {
    const result = await formatApi({
      Action: 'OperateData',
      BatchData: params,
      Method: 'Batch',
      OpType: 'Delete',
      SchemaId: 'contact_person_group_relation',
    });

    return result.data;
  } catch (error) {
  }
}

// 根据关键字拉取员工信息
export async function searchStaffList(keyword, limit) {
  const result = await formatApi({
    Action: 'Tof',
    Method: 'SearchStaffList',
    Keyword: keyword,
    Limit: parseInt(limit, 10),
  });
  const { data } = result;
  if (data.status !== 0) {
    Message.error(`根据关键字拉取员工信息失败：${data.msg}`);
    throw new Error(data.msg);
  }

  return data.data;
}

// 根据关键字拉取员工信息
export async function newSearchStaffList(v, Limit) {
  const result = await formatApi({
    // RequestJson: {
      Action: 'Tof',
      Method: 'StaffInfoKeyValue',
      SystemId: '2',
      Keyword: v,
      Limit: parseInt(Limit, 10),
  
    });
    const { data } = result;

  console.log(data.data, 'ggg');
  return data.data;
}

// 获取园区数据
export async function getList() {
  try {
    const result = await formatApi({
      Action: 'Idcdb',
      Method: 'Query',
      SystemId: '2',
      table: 'campus',
      fields: ['city_name', 'campus_name', 'campus_shortname', 'campus_id'],
      conditions: [],
      start: 0,
      limit: 0,
    });

    return result;
  } catch (error) {
  }
}

// 获取HC额度
export async function getHcList(params) {
  try {
    const result = await formatApi({
      Action: 'QueryData',
      Method: 'Run',
      SystemId: '2',
      SchemaId: 'machineroom_dimension_hc',
      ReturnTotalRows: 1,
      Data: params,
    });

    console.log(result.data, '555555');
    return result.data;
  } catch (error) {
  }
}

// 获取账单信息
export async function BillList(params) {
  try {
    const result = await formatApi({
      Action: 'QueryData',
      Method: 'Run',
      SystemId: '2',
      SchemaId: 'hm_bill_info',
      Data: params,
      ReturnTotalRows: 1,
    });

    return result.data;
  } catch (error) {
  }
}

// 单个账单信息
export async function OneBillList(params) {
  try {
    const result = await formatApi({
      Action: 'Human',
      Method: 'GetBillDetail',
      SystemId: '2',
      Data: params,
    });

    return result.data;
  } catch (error) {
  }
}

// 外包人员选择器数据源
export async function personList(params) {
  try {
    const result = await formatApi({
      Action: 'QueryData',
      Method: 'Run',
      SystemId: '2',
      SchemaId: 'hm_account',
      Data: params,
    });

    return result.data;
  } catch (error) {
  }
}

// 数据中心信息
export async function dataList() {
  try {
    const result = await formatApi({
      Action: 'Human',
      Method: 'GetDepartmentTree',
      SystemId: '2',
    });

    return result.data;
  } catch (error) {
  }
}

// 人员组织架构页面成员列表数据
export async function Directory(params) {
  try {
    const result = await formatApi({
      Action: 'Human',
      Method: 'GetDepartmentUser',
      SystemId: '2',
      Data: params,
    });

    return result.data;
  } catch (error) {
  }
}

// 园区数据列表
export async function CenterGroup(params) {
  try {
    const result = await formatApi({
      Action: 'Human',
      Method: 'GetAllOrgRelation',
      SystemId: '2',
      Data: params,
    });

    return result.data;
  } catch (error) {
  }
}

// 获取企业微信临时票据
export async function getWeWorkJsapiTicket() {
  try {
    const result = await formatApi({
      Action: 'WeWork',
      Method: 'GetWeWorkJsapiTicket',
      SystemId: '2'
    });

    return result.data;
  } catch (error) {
  }
}

// 获取企业微信应用签名
export async function getWeWorkAppJsapiTicket(params) {
  try {
    const result = await formatApi({
      Action: 'WeWork',
      Method: 'GetWeWorkAppJsapiTicket',
      SystemId: '2',
      Data: params
    });

    return result;
  } catch (error) {
  }
}


// 查询当前用户的加班单 
export async function getOtList(params) {
  try {
    const result = await formatApi({
      SystemId: "2",
      Action: "Nbroker",
      Method: "UrlProxy",
      NbrokerData: {
        context: {
          service_name: "OutsourcingApiController",
          method_name: "get_own_ot_ticket"
        },
        args: params
      },
      ServiceUrl: "http://ibroker-human:8080/nBroker/api/v1/task"
    });

    return result.data;
  } catch (error) {
  }
}