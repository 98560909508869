import { formatApi, postAny } from '../baseApi';
import { Message } from 'element-ui';
import store from '@/store';

// 获取待办下拉组件数据源
export async function getSelectRemoteData(url) {
  const result = await postAny(url, '');
  const { data } = result;
  if (data.status !== 0) {
    Message.error(`获取待办列表接口出错：${data.msg}`);
    throw new Error(data.msg);
  }

  console.log(data, 'uuuuu');
  return data;
}

/**
 * 获取待办列表
 * @param {Object} conditions 查询条件
 * @param {Object} limit 分页条件
 */
export async function getTodoList(conditions, limit) {
  const result = await formatApi({
    Action: 'QueryData',
    Method: 'Run',
    SchemaId: 'task_center_todo_runtime',
    ReturnTotalRows: 1,
    Data: {
      ResultColumns: {
        AjaxService: '',
        CorrelationId: '',
        CreateTime: '',
        ForbidForceEndInstance: '',
        HandleUrl: '',
        Id: '',
        InstanceId: '',
        ParentInstanceId: '',
        ParentTaskId: '',
        Pinned: '',
        ProcessDefinitionKey: '',
        ProcessUsers: '',
        RoleGroup: '',
        RoleIdentity: '',
        TaskId: '',
        TaskKey: '',
        TicketId: '',
        TodoKey: '',
        TodoName: '',
      },
      SearchCondition: conditions,
      Sorts: [
        {
          Column: 'Id',
          SortType: 'desc',
        },
      ],
      Limit: limit,
    },
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`获取待办列表接口出错：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

/**
 * 获取历史待办列表
 * @param {Object} conditions 查询条件
 * @param {Object} limit 分页条件
 */
export async function getHistoryTodoList(conditions, limit) {
  const result = await formatApi({
    Action: 'QueryData',
    Method: 'Run',
    SchemaId: 'task_center_todo_history',
    ReturnTotalRows: 1,
    Data: {
      ResultColumns: {
        AjaxService: '',
        CorrelationId: '',
        CreateTime: '',
        EndTime: '',
        ForbidForceEndInstance: '',
        HandleUrl: '',
        Id: '',
        InstanceId: '',
        ParentInstanceId: '',
        ParentTaskId: '',
        Pinned: '',
        ProcessDefinitionKey: '',
        ProcessUsers: '',
        RealOperator: '',
        RoleGroup: '',
        RoleIdentity: '',
        TaskId: '',
        TaskKey: '',
        TicketId: '',
        TodoKey: '',
        TodoName: '',
      },
      SearchCondition: conditions,
      Sorts: [
        {
          Column: 'Id',
          SortType: 'desc',
        },
      ],
      Limit: limit,
    },
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`获取历史待办列表接口出错：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

/**
 * 获取按工单聚合的待办列表
 * @param {Object} conditions 查询条件
 * @param {Object} limit 分页条件
 */
export async function getTodoListByTicket(conditions, limit) {
  const result = await formatApi({
    Action: 'QueryData',
    Method: 'Run',
    SchemaId: 'task_center_todo_runtime_aggs',
    ReturnTotalRows: 1,
    Data: {
      ResultColumns: {
        CreateTime: '',
        ProcessDefinitionKey: '',
        SLALevel: '',
        SceneType: '',
        TicketId: '',
        Title: '',
        TodoList: '',
      },
      SearchCondition: conditions,
      Sorts: [
        {
          Column: 'TicketId',
          SortType: 'desc',
        },
      ],
      Limit: limit,
    },
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`获取按工单聚合的待办列表接口出错：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

export async function getTodoListByTicketIdc(conditions, limit) {
  const result = await formatApi({
    Action: 'QueryData',
    Method: 'Run',
    SchemaId: 'task_center_todo_runtime_idc',
    ReturnTotalRows: 1,
    Data: {
      ResultColumns: {
        CreateTime: '',
        ProcessDefinitionKey: '',
        SLALevel: '',
        SceneType: '',
        TicketId: '',
        Title: '',
        TodoList: '',
      },
      SearchCondition: conditions,
      Sorts: [
        {
          Column: 'TicketId',
          SortType: 'desc',
        },
      ],
      Limit: limit,
    },
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`获取按工单聚合的待办列表接口出错：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

/**
 * 获取按工单聚合的历史待办列表
 * @param {Object} conditions 查询条件
 * @param {Object} limit 分页条件
 */
export async function getTodoHistoryListByTicket(conditions, limit) {
  const result = await formatApi({
    Action: 'QueryData',
    Method: 'Run',
    SchemaId: 'task_center_todo_history_idc',
    ReturnTotalRows: 1,
    Data: {
      ResultColumns: {
        CreateTime: '',
        ProcessDefinitionKey: '',
        SLALevel: '',
        SceneType: '',
        SchemeNameCn: '',
        TicketId: '',
        Title: '',
        HistoryList: '',
      },
      SearchCondition: conditions,
      Sorts: [
        {
          Column: 'TicketId',
          SortType: 'desc',
        },
      ],
      Limit: limit,
    },
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`获取按工单聚合的待办列表接口出错：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

/**
 * 获取按工单聚合的待办列表
 * @param {Object} conditions 查询条件
 * @param {Object} limit 分页条件
 */
export async function getHistoryTodo(conditions, limit) {
  const result = await formatApi({
    Action: 'QueryData',
    Method: 'run',
    SystemId: '1',
    SchemaId: 'task_center_todo_history_aggs',
    ReturnTotalRows: 0,
    Data: {
      ResultColumns: {
        CreateTime: '',
        HistoryList: '',
        ProcessDefinitionKey: '',
        // SchemeName: '',
        SLALevel: '',
        SceneType: '',
        TicketId: '',
        Title: '',
      },
      SearchCondition: conditions,
      Sorts: [],
      Limit: limit,
    },
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`获取按工单聚合的待办列表接口出错：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

/**
 * 获取按工单聚合的历史待办列表
 * @param {Object} conditions 查询条件
 * @param {Object} limit 分页条件
 */
export async function getHistoryTodoListByTicket(conditions, limit) {
  const result = await formatApi({
    Action: 'QueryData',
    Method: 'Run',
    SchemaId: 'task_center_todo_history_aggs',
    ReturnTotalRows: 1,
    Data: {
      ResultColumns: {
        CreateTime: '',
        HistoryList: '',
        ProcessDefinitionKey: '',
        SLALevel: '',
        SceneType: '',
        TicketId: '',
        Title: '',
      },
      SearchCondition: conditions,
      Sorts: [
        {
          Column: 'TicketId',
          SortType: 'desc',
        },
      ],
      Limit: {
        Size: 20,
        Start: 0,
      },
    },
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`获取聚合的历史待办列表接口出错：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

/**
 * 待办转派
 * @param {String} newUser 新责任人
 * @param {String} user 操作用户
 * @param {String} taskId 任务Id
 */
export async function handoverTodo(newUser, user, taskId) {
  const result = await formatApi({
    Action: 'TaskCenter',
    Data: {
      NewPrincipals: newUser,
      OperateUser: user,
      TaskId: taskId,
    },
    Method: 'Reassign',
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`待办转派接口出错 (Reassign)：${data.msg}`);
    throw new Error(data.msg);
  }

  console.log(data, 'uuu');
  return data;
}

/**
 * 查看用户所在的工作组
 * @param {String} newUser 新责任人
 * @param {String} user 操作用户
 * @param {String} taskId 任务Id
 */
export async function queryRoleGroup(user) {
  const result = await formatApi({
    Action: 'TaskCenter',
    Data: {
      Username: user,
    },
    Method: 'QueryRoleGroup',
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`查看用户所在的工作组 (QueryRoleGroup)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

/**
 * 获取待办筛选按钮列表
 * @param {Object} conditions 查询条件
 * @param {Object} limit 分页条件
 */
export async function agencyData(conditions) {
  const result = await formatApi({
    Action: 'QueryData',
    Method: 'run',
    SystemId: '1',
    SchemaId: 'task_center_todo_history_aggs',
    ReturnTotalRows: 0,
    Data: {
      ResultColumns: {
        CreateTime: '',
        ProcessDefinitionKey: '',
        SLALevel: '',
        SceneType: '',
        SchemeNameCn: '',
        TicketId: '',
        Title: '',
      },
      SearchCondition: conditions,
      Sorts: [],
      Limit: {
        Size: 1000,
        Start: 0,
      },
    },
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`获取待办筛选按钮列表接口出错：${data.msg}`);
    throw new Error(data.msg);
  }

  console.log(data, 'ooooo');
  return data;
}

// 运营管理页业务工单中的我的待办的按钮
export async function SearchTodoTypeRuntimeList() {
  const result = await formatApi({
    Action: 'Ticket',
    Method: 'SearchTodoTypeList',
    SystemId: '2',
    SceneName: ['Principal'],
    Type: 'runtime',
    User: store.state.user.name,
  });

  const { data } = result;
  return data;
}

export async function SearchTodoTypeHistoryList() {
  const result = await formatApi({
    Action: 'Ticket',
    Method: 'SearchTodoTypeList',
    SystemId: '2',
    SceneName: ['Principal'],
    Type: 'history',
    User: store.state.user.name,
  });

  const { data } = result;
  return data;
}

// 运营管理页业务工单中的我的待办的按钮
// export async function SearchTodoTypeList() {
//   const result = await formatApi({
//     Action: 'Ticket',
//     Method: 'SearchTodoTypeList',
//     SystemId: '2',
//     SceneName: [
//       'Principal'
//     ],
//     Type: "history",
//     User: localStorage.getItem('UserId'),
//   });

//   const { data } = result;
//   // if (data.status !== 0) {
//   //   Message.error(`查看用户所在的工作组 (QueryRoleGroup)：${data.msg}`);
//   //   throw new Error(data.msg);
//   // }

//   console.log(data, "hhhhh");
//   return data;
// }

/**
 * 获取按工单聚合的待办列表
 * @param {Object} conditions 查询条件
 * @param {Object} limit 分页条件
 */
export async function getHistory(conditions, limit) {
  const result = await formatApi({
    Action: 'QueryData',
    Method: 'run',
    SchemaId: 'task_center_todo_history_aggs',
    ReturnTotalRows: 1,
    Data: {
      ResultColumns: {
        CreateTime: '',
        ProcessDefinitionKey: '',
        SLALevel: '',
        SceneType: '',
        TicketId: '',
        Title: '',
        SchemeNameCn: '',
        // TodoList: '',
      },
      SearchCondition: conditions,
      Sorts: [
        {
          Column: 'TicketId',
          SortType: 'desc',
        },
      ],
      Limit: limit,
    },
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`获取按工单聚合的待办列表接口出错：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}
