import { formatApi, formatOtobApi, fetchScf, formatNewOtobApi } from '../baseApi';
import { Toast } from 'vant';

/**
 * 获取工单数据
 */
export async function getTicketData(params) {
  const result = await formatApi({
    Action: 'QueryData',
    Method: 'Run',
    SchemaId: 'ticket_base',
    ReturnTotalRows: 1,
    Data: params,
  });

  const { data } = result;
  if (data.status !== 0) {
    Toast.fail(`获取工单数据接口出错 (ticket_base)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

/**
 * 获取云函数返回详情数据
 */
export async function getScfDetailData(params) {
  const result = await formatApi({
    Action: 'QueryData',
    Method: 'Run',
    SchemaId: 'flow_tasks',
    ReturnTotalRows: 1,
    Data: {
      ResultColumns: {
        Args: '',
        ConsumeSecs: '',
        CreateTime: '',
        FinishedTime: '',
        Id: '',
        InstName: '',
        InstanceId: '',
        LastCode: '',
        LastErrMsg: '',
        LastLogId: '',
        LastMainThreadLogId: '',
        LastResult: '',
        LastRunTime: '',
        MethodName: '',
        ParentInstanceId: '',
        ParentTaskId: '',
        RetryTimes: '',
        ServiceName: '',
        Status: '',
        System: '',
        TaskId: '',
        TaskName: '',
      },
      SearchCondition: {
        TaskId: params,
      },
    },
  });

  const { data } = result;
  if (data.status !== 0) {
    Toast.fail(`获取云函数返回结果详情接口出错 (flow_tasks)：${data.msg}`);
    throw new Error(data.msg);
  }
  return data;
}

/**
 * 获取云函数返回详情数据
 */
export async function getAutoDetailData(params) {
  const result = await formatApi({
    Action: 'TaskCenter',
    Method: 'QueryTaskDetail',
    Data: params,
  });

  const { data } = result;
  if (data.status !== 0) {
    Toast.fail(`获取自动任务详情的接口出错：${data.msg}`);
    throw new Error(data.msg);
  }
  return data;
}

// 新建工单
export async function addNewTicket(params) {
  const result = await formatApi({
    Action: 'Ticket',
    Data: params,
    Method: 'Create',
  });

  const { data } = result;
  if (data.status !== 0) {
    Toast.fail(`新建工单接口出错 (TicketCreate)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

// 批量建单
export async function batchCreateTicket(DataList) {
  const result = await formatApi({
    Action: 'Ticket',
    DataList,
    Method: 'BatchCreate',
  });

  const { data } = result;
  if (data.status !== 0) {
    Toast.fail(`批量建单接口出错 (BatchCreateTicket)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

// 更新工单数据
export async function updateTicketInfo(columns, conditions) {
  const result = await formatApi({
    Action: 'OperateData',
    Method: 'Run',
    SchemaId: 'ticket_base',
    OpType: 'Update',
    Columns: columns,
    Conditions: conditions,
  });

  const { data } = result;
  if (data.status !== 0) {
    Toast.fail(`更新工单接口出错 (Update_ticket_base)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

// 批量更新工单数据
export async function batchUpdateTicketInfo(params) {
  const result = await formatApi({
    Action: 'OperateData',
    BatchData: params,
    Method: 'Batch',
    OpType: 'Update',
    SchemaId: 'ticket_base',
  });

  const { data } = result;
  if (data.status !== 0) {
    Toast.fail(`批量更新工单接口出错 (BatchUpdate_ticket_base)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

// 强制结单
export async function forceFinshTicket(TicketId, Reason) {
  const result = await formatApi({
    Action: 'Ticket',
    Data: { TicketId, Reason },
    Method: 'ForceFinsh',
  });

  const { data } = result;
  if (data.status !== 0) {
    Toast.fail(`强制结单接口出错 (ForceFinsh)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

// 人工EOP
export async function eopManualRollback(params) {
  const result = await formatApi({
    Action: 'FlowScheme',
    Data: params,
    Method: 'EOPManualRollback',
  });

  const { data } = result;
  if (data.status !== 0) {
    Toast.fail(`人工EOP操作出错 (EOPManualRollback)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

// 获取工单sniper_netchange_id信息
export async function getSniperNetchangeId(params) {
  const result = await formatApi({
    Action: 'FlowScheme',
    Data: params,
    Method: 'QuerySniperNetchangeId',
  });

  const { data } = result;
  if (data.status !== 0) {
    Toast.fail(`人工EOP操作出错 (EOPManualRollback)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

// 获取人工处理历史
export async function getTicketProcessHistory(TicketId) {
  const result = await formatApi({
    Action: 'QueryData',
    Method: 'Run',
    SchemaId: 'ticket_process_history',
    ReturnTotalRows: 1,
    Data: {
      ResultColumns: {
        Content: '',
        CreateTime: '',
        Id: '',
        Operator: '',
        TicketId: '',
      },
      SearchCondition: { TicketId },
      Sorts: [
        {
          Column: 'CreateTime',
          SortType: 'desc',
        },
      ],
    },
  });

  const { data } = result;
  if (data.status !== 0) {
    Toast.fail(`获取人工处理历史出错 (ticket_process_history)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

/**
 * 新增人工处理历史
 * @param {String} TicketId 工单ID
 * @param {String} Content 内容
 * @param {String} Operator 操作人
 */
export async function addTicketProcessHistory(TicketId, Content, Operator) {
  const result = await formatApi({
    Action: 'OperateData',
    Method: 'Run',
    SchemaId: 'ticket_process_history',
    OpType: 'Add',
    Columns: {
      Content,
      Operator,
      TicketId,
    },
  });

  const { data } = result;
  if (data.status !== 0) {
    Toast.fail(`新增人工处理历史出错 (ticket_process_history)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

/**
 * 根据工单ID获取流程定义数据
 * @param {String} TicketId 工单ID
 */
export async function getFlowInfoByTicketId(TicketId) {
  const result = await formatApi({
    Action: 'FlowScheme',
    Data: { TicketId },
    Method: 'GetFlowDefinitions',
  });

  const { data } = result;
  if (data.status !== 0) {
    Toast.fail(`获取流程定义数据接口出错 (GetFlowDefinitions)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

// 工单转派
export async function handoverTicket(params) {
  const result = await formatApi({
    Action: 'Ticket',
    Data: params,
    Method: 'Handover',
  });

  const { data } = result;
  if (data.status !== 0) {
    Toast.fail(`工单转派接口出错 (TicketHandover)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

/**
 * 待办转派
 * @param {String} newUser 新责任人
 * @param {String} user 操作用户
 * @param {String} taskId 任务Id
 */
export async function handoverTodo(newUser, user, taskId) {
  const result = await formatApi({
    Action: 'TaskCenter',
    Data: {
      NewPrincipals: newUser,
      OperateUser: user,
      TaskId: taskId,
    },
    Method: 'ReassignBack',
    // Method: 'Reassign',
  });

  const { data } = result;
  if (data.status !== 0) {
    Toast.fail(`待办转派接口出错 (ReassignBack)：${data.msg}`);
    throw new Error(data.msg);
  }

  console.log(data, 'uuu');
  return data;
}

/**
 * 重试任务
 * @param {String} InstanceId 流程id
 * @param {String} TaskId 任务id
 */
export async function retryFlowTask(InstanceId, TaskId) {
  const result = await formatApi({
    Action: 'FlowScheme',
    Data: { InstanceId, TaskId },
    Method: 'RetryFlowTask',
  });

  const { data } = result;
  if (data.status !== 0) {
    Toast.fail(`重试任务接口出错 (RetryFlowTask)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

// 从nbroker获取数据
export async function getDataByNbroker(params) {
  const result = await formatApi({
    Action: 'Nbroker',
    Method: 'CommonProxy',
    NbrokerData: params,
  });

  const { data } = result;
  if (data.status !== 0) {
    Toast.fail(`转发到Nbroker接口出错 (Nbroker_CommonProxy)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

// 从nbroker获取数据，带url
export async function getDataByNbrokerUrl(params, url) {
  const result = await formatApi({
    Action: 'Nbroker',
    Method: 'UrlProxy',
    NbrokerData: params,
    ServiceUrl: url,
  });

  const { data } = result;
  if (data.status !== 0) {
    Toast.fail(`转发到Nbroker接口出错 (Nbroker_UrlProxy)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

// 查询设备信息, key-value
export async function getNdvByKey(params) {
  const result = await formatApi({
    Action: 'Query',
    Data: params,
    Method: 'GetNdvByKey',
  });

  const { data } = result;
  if (data.status !== 0) {
    Toast.fail(`查询设备信息接口出错 (GetNdvByKey)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

// 查询事件信息, key-value
export async function getEventByKey(params) {
  const result = await formatApi({
    Action: 'Query',
    Data: params,
    Method: 'GetEventByKey',
  });

  const { data } = result;
  if (data.status !== 0) {
    Toast.fail(`查询事件信息接口出错 (GetEventByKey)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

// 查询员工基础信息
export async function getStaffInfo(params) {
  const result = await formatApi({
    Action: 'Query',
    Data: {
      Username: params,
    },
    Method: 'GetStaffInfo',
  });

  const { data } = result;
  if (data.status !== 0) {
    Toast.fail(`查询事件信息接口出错 (GetEventByKey)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

// ------------------- 邮件相关api ------------------- //

/**
 * 查询邮件列表
 * @param {Object} conditions 查询条件
 */
export async function getEmailList(conditions) {
  const result = await formatApi({
    Action: 'QueryData',
    Method: 'Run',
    SchemaId: 'mail_info',
    ReturnTotalRows: 1,
    Data: {
      ResultColumns: {
        Content: '',
        CreateTime: '',
        FormNo: '',
        Id: '',
        Receiver: '',
        Sender: '',
        Subject: '',
      },
      SearchCondition: conditions,
      Sorts: [
        {
          Column: 'Id',
          SortType: 'desc',
        },
      ],
    },
  });

  const { data } = result;
  if (data.status !== 0) {
    Toast.fail(`获取邮件列表接口出错 (mail_info)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

/**
 * 查询邮件附件列表
 * @param {Object} conditions 查询条件
 */
export async function getEmailAttachmentList(conditions) {
  const result = await formatApi({
    Action: 'QueryData',
    Method: 'Run',
    SchemaId: 'mail_attachment',
    ReturnTotalRows: 1,
    Data: {
      ResultColumns: {
        Id: '',
        IsEmbed: '',
        MailId: '',
        Name: '',
        Path: '',
        Url: '',
      },
      SearchCondition: conditions,
      Sorts: [
        {
          Column: 'Id',
          SortType: 'desc',
        },
      ],
    },
  });

  const { data } = result;
  if (data.status !== 0) {
    Toast.fail(`获取邮件附件列表接口出错 (mail_attachment)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

/**
 * 发送邮件
 * @param {Object} params 查询条件
 * Body: 邮件内容,
 * MailCC: 抄送人,
 * MailFrom: 发送人,
 * MailTo: 收件人,
 * Title: 标题
 * Attachment: 附件
 */
export async function sendEmail(params) {
  const result = await formatApi({
    Action: 'MailCenter',
    Data: params,
    Method: 'SendMail',
  });

  const { data } = result;
  if (data.status !== 0) {
    Toast.fail(`发送邮件接口出错 (mail_info)：${data.msg}`);
    throw new Error(data.msg);
  }

  return data;
}

/**
 *
 * 创建事件工单
 */
export async function createEventTicket(ticket, flowParams) {
  const result = await formatApi({
    Action: 'Ticket',
    Ticket: ticket,
    FlowParams: flowParams,
    ExtInfo: {},
    SceneName: '',
    Method: 'CreateEventTicket',
  });
  const { data } = result;
  if (data.status !== 0) {
    Toast.fail(`建单失败：${data.msg}`);
    throw new Error(data.msg);
  }
  return data;
}

/**
 * 校验建单权限
 */
export async function checkCreatePriv(ticketType) {
  const result = await formatApi({
    Action: 'TicketEvent',
    Data: {
      TicketType: ticketType,
    },
    Method: 'CheckCreatePriv',
  });
  const { data } = result;
  if (data.status !== 0) {
    Toast.fail(`校验建单权限失败：${data.msg}`);
    throw new Error(data.msg);
  }
  return data;
}

/**
 *
 * 查询工单的日志详情信息
 */
export async function getLogsApi(params) {
  try {
    const result = await formatApi({
      Action: 'Query',
      Data: params,
      Method: 'AggsLog',
    });
    return result.data.data;
  } catch (error) {}
}

/**
 *
 * 查询当前N4岗值班人员
 */
export async function queryNowN4() {
  try {
    const result = await fetchScf({
      Action: 'Scf',
      CloudFunctionName: 'tmprobot',
      CloudFunctionReqData: {
        Data: {},
        Method: 'get_current_n4',
        Module: 'api_get_duty_member',
      },
      Method: 'Invoke',
      RequestMode: 'sync',
    });

    return result.data;
  } catch (error) {}
}

/**
 *
 * 查询当月所有N4人员
 */
export async function queryAllN4() {
  try {
    const result = await fetchScf({
      Action: 'Scf',
      CloudFunctionName: 'tmprobot',
      CloudFunctionReqData: {
        Data: {},
        Method: 'get_n4',
        Module: 'api_get_duty_member',
      },
      Method: 'Invoke',
      RequestMode: 'sync',
    });

    return result.data;
  } catch (error) {}
}

/**
 *
 * 查询权限系统角色表中的待办权限属性值
 */
export async function queryPermRoleProp(PropertyNameId) {
  try {
    const result = await fetchScf({
      Action: 'Scf',
      CloudFunctionName: 'tmprobot',
      CloudFunctionReqData: {
        Data: {
          propertyNameId: PropertyNameId,
        },
        Method: 'get_perm_role_prop',
        Module: 'api_get_duty_member',
      },
      Method: 'Invoke',
      RequestMode: 'sync',
    });

    return result.data;
  } catch (error) {}
}

export const getTncTaskData = async (params) => {
  const result = await formatApi({
    Action: 'Tnc',
    Method: 'QueryTncTaskDetail',
    SystemId: '1',
    Data: params,
  });

  const { data } = result;
  if (data.status !== 0) {
    Toast.fail(`获取接口出错：${data.msg}`);
    throw new Error(data.msg);
  } else {
    return data.data || {};
  }
};

export const getVariables = async (Keylist, InstanceId) => {
  const result = await formatApi({
    Action: 'Ticket', // 原样填写
    InstanceId, // (*必填) 流程实例Id
    Keylist,
    Method: 'GetHistoryVariables', // 原样填写
    SystemId: 2, // (*必填) (可取值区间为[>0]) (要求数据类型[int]) 调用方系统标识
  });

  const { data } = result;
  if (data.status !== 0) {
    Toast.fail(`获取接口出错：${data.msg}`);
    throw new Error(data.msg);
  } else {
    return data.data || {};
  }
};

export const getOtobTicket = async (params) => {
  const result = await formatOtobApi({
    Action: 'OTaskOrder',
    Data: {
      Size: 999,
      Start: 1,
      OrderStatus: 'default',
      KeyWords: '',
      Type: '',
      SortBy: 'CreateTime DESC',
      ...params,
      UserId: sessionStorage.getItem('userid'),
    },
    Method: 'PageOrder',
  });

  const { data } = result;
  if (data.Return !== 0) {
    Toast.fail(`获取接口出错：${data.Details}`);
    throw new Error(data.Details);
  } else {
    return data.Data || {};
  }
};

export const getAllType = async (Type = 'default') => {
  const result = await formatOtobApi({
    Action: 'OTaskOrder',
    Type,
    Method: 'StatisticOrder',
    SystemId: '2',
    UserId: sessionStorage.getItem('userid'),
  });

  const { data } = result;
  if (data.Return !== 0) {
    Toast.fail(`获取接口出错：${data.Details}`);
    throw new Error(data.Details);
  } else {
    return data.Data || {};
  }
};

export async function getRelateTicket(TicketId) {
  try {
    const result = await formatApi({
      Action: 'Ticket',
      Method: 'GetParentSonRelation',
      SystemId: '2',
      TicketId,
    });

    return result.data;
  } catch (error) {}
}
// 总览页面统计接口
export const getOverAllType = async (Type = 'default') => {
  const result = await formatNewOtobApi({
    Action: 'OrderCenter',
    Type,
    Method: 'CenterStatisticOrder',
    SystemId: '2',
    UserId: sessionStorage.getItem('userid'),
  });
  const { data } = result;
  if (data.Return !== 0) {
    Toast.fail(`获取接口出错：${data.Details}`);
    throw new Error(data.Details);
  } else {
    return data.Data || {};
  }
};
// 总览页面分页查询接口
export const getOverTicket = async (params) => {
  const result = await formatNewOtobApi({
    Action: 'OrderCenter',
    Data: {
      Size: 999,
      Start: 1,
      OrderStatus: 'default',
      KeyWords: params.KeyWords || '',
      Type: params.Type,
      SortBy: 'CreateTime DESC',
      UserId: sessionStorage.getItem('userid'),
    },
    Method: 'CenterPageOrder',
  });
  const { data } = result;
  if (data.Return !== 0) {
    Toast.fail(`获取接口出错：${data.Details}`);
    throw new Error(data.Details);
  } else {
    return data.Data || {};
  }
};
// 获取 OLA时间接口
export async function getSLAdata(TaskId) {
  try {
    const result = await formatApi({
      Action: 'TaskCenter',
      Method: 'GetTaskOlaData',
      SystemId: '2',
      TaskId,
    });

    return result.data;
  } catch (error) {}
}

/**
 * 异常工单问题反馈
 */
export async function feedbackTicketInfo(params) {
  const result = await formatApi({
    SystemId: '2',
    Action: 'Ticket',
    Method: 'Create',
    Data: params,
  });
  const { data } = result;
  if (data.status !== 0) {
    Message.error(`批量更新工单接口出错 (BatchUpdate_ticket_base)：${data.msg}`);
    throw new Error(data.msg);
  }
  return data;
}
