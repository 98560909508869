"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Button = void 0;
var vue_1 = require("@formily/vue");
var reactive_vue_1 = require("@formily/reactive-vue");
var composition_api_1 = require("@vue/composition-api");
var vant_1 = require("vant");
exports.Button = (0, reactive_vue_1.observer)((0, composition_api_1.defineComponent)({
    name: 'FSubmit',
    props: ['onClick', 'value', 'type'],
    setup: function (props, _a) {
        var attrs = _a.attrs, listeners = _a.listeners;
        var formRef = (0, vue_1.useParentForm)();
        return function () {
            var _a, _b;
            var _c = props.onClick, onClick = _c === void 0 ? listeners === null || listeners === void 0 ? void 0 : listeners.click : _c, value = props.value;
            var form = formRef === null || formRef === void 0 ? void 0 : formRef.value;
            return (0, vue_1.h)(vant_1.Button, {
                attrs: __assign(__assign({ nativeType: (listeners === null || listeners === void 0 ? void 0 : listeners.submit) ? 'button' : 'submit', type: (_b = (_a = props.type) !== null && _a !== void 0 ? _a : attrs.type) !== null && _b !== void 0 ? _b : 'primary' }, attrs), { plain: props.type === 'text', hairline: true, loading: attrs.loading !== undefined ? attrs.loading : form === null || form === void 0 ? void 0 : form.submitting }),
                on: __assign(__assign({}, listeners), { click: function (e) {
                        if (onClick) {
                            if (onClick(e, value) === false)
                                return;
                        }
                    } }),
            }, {
                default: function () { return [attrs.content || props.value || '按钮']; },
            });
        };
    },
}));
exports.default = exports.Button;
