const state = {
    isTimeout: null
};

const getters = {};

const mutations = {
    setTimeStatus: (state, payload) => {
        state.isTimeout = payload;
    }
};

const actions = {
    setTimeStatus(context, payload) {
        context.commit('setTimeStatus', payload);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
