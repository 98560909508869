import { formatApi } from '../baseApi';
// 获取一级机房
export async function getMachineRoom(campusName) {
  try {
    const result = await formatApi({
      Action: 'Nbroker',
      Method: 'UrlProxy',
      NbrokerData: {
        context: {
          service_name: 'GoStorage',
          method_name: 'req_idcdb',
        },
        args: {
          campus_name: campusName,
        },
      },
      ServiceUrl: 'http://ibroker-asset:8080/nBroker/api/v1/task',
      SystemId: '2',
    });
    return result.data.data;
  } catch (error) {}
}

// 获取资产位置
export async function getAssetLocation(primaryMachineroom) {
  try {
    const result = await formatApi({
      Action: 'Nbroker',
      Method: 'UrlProxy',
      NbrokerData: {
        context: {
          service_name: 'EsdbLib',
          method_name: 'getPosInfo',
        },
        args: {
          type: 'name',
          data: primaryMachineroom || [],
        },
      },
      ServiceUrl: 'http://ibroker-corebiz:8080/nBroker/api/v1/task',
      SystemId: '2',
    });
    return result.data.data;
  } catch (error) {}
}
// 保存流程
export async function saveProcess(variablesDict, instanceId) {
  try {
    const result = await formatApi({
      Action: 'Nbroker',
      Method: 'UrlProxy',
      NbrokerData: {
        context: {
          service_name: 'GoStorage',
          method_name: 'variable_data_write',
        },
        args: {
          variables_dict: variablesDict,
          instance_id: instanceId,
        },
      },
      ServiceUrl: 'http://ibroker-asset:8080/nBroker/api/v1/task',
      SystemId: '2',
    });
    return result.data.data.result;
  } catch (error) {}
}
// 拉取流程
export async function pullOutProcess(variableNameList, instanceId) {
  try {
    const result = await formatApi({
      Action: 'Nbroker',
      Method: 'UrlProxy',
      NbrokerData: {
        context: {
          service_name: 'GoStorage',
          method_name: 'variable_data_read',
        },
        args: {
          variable_name_list: variableNameList,
          instance_id: instanceId,
        },
      },
      ServiceUrl: 'http://ibroker-asset:8080/nBroker/api/v1/task',
      SystemId: '2',
    });
    return result.data.data.result;
  } catch (error) {}
}
// 根据资产位置和实际PN找出对应的线材数量
export async function getWirequantity(assetType, pn, locationNameList, locationNameToIdList) {
  try {
    const result = await formatApi({
      Action: 'Nbroker',
      Method: 'UrlProxy',
      NbrokerData: {
        context: {
          service_name: 'GoStorage',
          method_name: 'wire_amdb_query',
        },
        args: {
          asset_type: assetType,
          pn,
          location_name_list: locationNameList,
          location_name_to_id_list: locationNameToIdList,
        },
      },
      ServiceUrl: 'http://ibroker-asset:8080/nBroker/api/v1/task',
      SystemId: '2',
    });
    return result.data.data.result;
  } catch (error) {}
}
