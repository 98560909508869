import { observer } from '@formily/reactive-vue';
import { connect, mapProps, mapReadPretty, h, useField } from '@formily/vue';
import { ref, defineComponent, computed, watch } from '@vue/composition-api';
import {
  Picker as VanPicker,
  Search as VanSearch,
  Popup as VanPopup,
  CheckboxGroup as VanCheckboxGroup,
  Checkbox as VanCheckbox,
} from 'vant';
import { formilyFormItem } from './index';
import { PreviewText } from '@formily/vant';

const BasePicker = observer(
  defineComponent({
    name: 'FBasePicker',
    props: ['options'],
    setup(props, { attrs, emit, slots }) {
      const field = useField();
      const {
        formItemProps = {},
        popupProps = {},
        pickerProps = {},
        fieldListeners = {},
        popupListeners = {},
        pickerListeners = {},
      } = attrs;
      const show = ref(false);
      const filtered = ref([]);
      const searchValue = ref('');
      const inputValue = ref('');
      const selected = ref(attrs.value || []);
      const showFiltered = computed(
        () => !!searchValue.value && field.value.componentProps.filterable
      );
      const dataSource = computed(() =>
        props.options ? props.options.map(({ label, value }) => ({ label, value })) : []
      );
      watch(
        () => attrs.value,
        (newVal) =>
          (selected.value =
            newVal && field.value.componentProps.multiple
              ? dataSource.value.filter(({ value }) => newVal.includes(value))
              : newVal || []),
        { deep: true }
      );
      const handleSearchNations = (val) => {
        searchValue.value = val;
        filtered.value = dataSource.value.filter(
          (item) =>
            item.label?.toLowerCase()?.includes(searchValue.value?.toLowerCase()) ||
            item.value?.toLowerCase()?.includes(searchValue.value?.toLowerCase())
        );
      };
      return () =>
        h(
          'div',
          {},
          {
            default: () => [
              h(
                formilyFormItem,
                {
                  attrs: {
                    isLink: field.value.pattern === 'editable',
                    value:
                      dataSource.value
                        .filter(({ value }) =>
                          Array.isArray(attrs.value)
                            ? attrs.value.includes(value)
                            : value === attrs.value
                        )
                        .map(({ label }) => label)
                        .toString() || field.value.value,
                    readonly: true,
                    ...formItemProps,
                    clearable: field.value.componentProps.clearable,
                  },
                  on: {
                    click: () => {
                      if (field.value.pattern !== 'editable') return;
                      show.value = true;
                    },
                    ...fieldListeners,
                  },
                },
                slots
              ),
              h(
                VanPopup,
                {
                  attrs: {
                    value: show.value,
                    round: true,
                    position: 'bottom',
                    ...popupProps,
                    'get-container': 'body',
                  },
                  on: {
                    input: (val) => {
                      show.value = val;
                    },
                    ...popupListeners,
                  },
                },
                {
                  default: () => [
                    field.value.componentProps.allowCreate && !dataSource.value.length
                      ? h(
                          VanSearch,
                          {
                            attrs: {
                              value: inputValue.value,
                              shape: 'round',
                              placeholder: '暂无数据，支持手动输入后回车确认',
                              clearable: true,
                            },
                            on: {
                              input(val) {
                                inputValue.value = val;
                              },
                              search(val) {
                                emit('change', inputValue.value);
                                show.value = false;
                                inputValue.value = '';
                              },
                              clear() {
                                inputValue.value = '';
                              },
                            },
                          },
                          {
                            default: () => [inputValue.value],
                          }
                        )
                      : null,
                    field.value.componentProps.filterable && dataSource.value.length
                      ? h(
                          VanSearch,
                          {
                            attrs: {
                              value: searchValue.value,
                              shape: 'round',
                              placeholder: '在这里搜索',
                              leftIcon: 'search',
                              clearable: true,
                            },
                            on: {
                              input(val) {
                                handleSearchNations(val);
                              },
                              search(val) {
                                handleSearchNations(val);
                              },
                              clear() {
                                searchValue.value = '';
                              },
                            },
                          },
                          {
                            default: () => [searchValue.value],
                          }
                        )
                      : null,
                    h(
                      VanPicker,
                      {
                        attrs: {
                          showToolbar: true,
                          title: field.value.title || '',
                          ...pickerProps,
                          'value-key': 'label',
                          defaultIndex:
                            dataSource.value.findIndex(
                              (item) => item.value === field.value.value
                            ) || 0,
                          columns: showFiltered.value ? filtered.value : dataSource.value,
                          ...field.value.componentProps,
                        },
                        on: {
                          cancel: () => {
                            show.value = false;
                            searchValue.value = '';
                          },
                          confirm: (val) => {
                            emit(
                              'change',
                              field.value.componentProps.multiple
                                ? selected.value.map(({ value }) => value)
                                : val.value
                            );
                            show.value = false;
                            searchValue.value = '';
                          },
                          ...pickerListeners,
                        },
                      },
                      {
                        option: (option) => {
                          if (!field.value.componentProps.multiple) return;
                          return [
                            h(
                              VanCheckboxGroup,
                              {
                                attrs: {
                                  value: selected.value.map(({ value }) => value),
                                  showToolbar: true,
                                  ...pickerProps,
                                  ...field.value.componentProps,
                                  // name: option,
                                },
                                on: {
                                  input(val) {
                                    selected.value = dataSource.value.filter(({ value }) =>
                                      val.includes(value)
                                    );
                                  },
                                },
                              },
                              {
                                default: () => [
                                  h(
                                    VanCheckbox,
                                    {
                                      attrs: {
                                        showToolbar: true,
                                        ...pickerProps,
                                        ...field.value.componentProps,
                                        name: option.value,
                                      },
                                    },
                                    {
                                      default: () => [option && option.label],
                                    }
                                  ),
                                ],
                              }
                            ),
                          ];
                        },
                      }
                    ),
                  ],
                }
              ),
            ],
          }
        );
    },
  })
);

export const Picker = connect(
  BasePicker,
  mapProps({ dataSource: 'options', readOnly: 'readonly' }),
  mapReadPretty(PreviewText.Picker)
);

export default Picker;
