<template>
  <el-select
    :value="selectedUser"
    class="w"
    ref="search"
    remote
    style="width: 100%"
    size="small"
    :disabled="disabled"
    :multiple="multiple"
    clearable
    filterable
    :allow-create="allowCreate"
    :placeholder="placeholder"
    :remote-method="searchUser"
    :loading="searchLoading"
    @change="onSelectChange"
    @paste.native="onPaste"
    :default-first-option="true"
    @focus="closeReadOnly"
    @hook:mounted="closeReadOnly"
    @visible-change="closeReadOnly"
  >
    <el-option
      v-for="(item, index) in filterUserList"
      :key="index"
      :label="item.fullName"
      :value="item.name"
    >
      <div class="user-item">
        <span class="f14 color-ntext">
          {{ item.fullName }}
        </span>
        <!-- <img
          :src="`${getConfigItem('dayu_avatar_url')}/${item.name}/avatar.jpg`"
          class="user-avatar"
        /> -->
      </div>
    </el-option>
  </el-select>
</template>

<script>
// import { getAllUsers } from '@/api/commonApi';
import { mapGetters } from 'vuex';
import { searchStaffList, newSearchStaffList } from '@/api/person/personApi';

export default {
  name: 'UserSelect',
  components: {
    searchStaffList,
    newSearchStaffList,
  },
  model: {
    prop: 'selected',
    event: 'user-select-change',
  },
  props: {
    selected: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    allowCreate: {
      type: Boolean,
      default: false,
    },
     allowPaste: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      searchLoading: false,
      allUserList: [],
      filterUserList: [],
    };
  },
  computed: {
    selectedUser() {
      if (this.multiple) {
        return this.selected ? this.selected.split(';').filter((name) => name) : [];
      }
      return this.selected;
    },
    ...mapGetters(['getConfigItem']),
  },
  mounted() {
    // this.getAllUsers(); 不再使用该接口获取人员数据
  },
  methods: {
    closeReadOnly(val) {
      this.$nextTick(() => {
        if (!val) {
          const input = this.$refs.search.$el.querySelector('.el-input__inner');
          console.log(input)
          const timer = setTimeout(() => {
            input.removeAttribute('readonly');
            clearTimeout(timer);
          }, 200);
        }
      });
    },

    // 搜索
    async searchUser(query) {
      this.searchLoading = false;
      const queryStr = query.trim();
      if (queryStr) {
        try {
          const staffNameList = await newSearchStaffList(queryStr, 20);
          this.filterUserList = staffNameList.map(item => ({
            fullName: item.label + (item.type === 'oa' ? '@腾讯' : '@腾讯IDC科技'),
            name: item.value,
          }));
        } catch (e) {}
        this.searchLoading = false;
      } else {
        this.filterUserList = [];
      }
      this.searchLoading = false;
    },

    // 选择人名
    onSelectChange(value) {
      let result = this.multiple ? value.join(';') : value.trim();
      if (typeof result === 'string') {
        result = result
          .split(';')
          .filter((name) => name)
          .join(';');
      }
      console.log(result, 'uuu');
      this.$emit('user-select-change', result);
    },

    // 支持批量粘贴
    onPaste(event) {
      if (!this.allowPaste) return
      event.preventDefault()
      let nowSelected = [];
      let pasteText = (event.clipboardData || window.clipboardData).getData('text');
      pasteText = pasteText
        .replace(/[,;\s]+/g, ';')
        .replace(/^;|;$/g, '')
        .replace(/\([\W]+\)/g, '');
      console.log(pasteText);
      if (this.multiple) {
        nowSelected = Array.from(new Set([...this.selectedUser, ...pasteText.split(';')]));
        if (nowSelected.length) {
          this.$emit('user-select-change', nowSelected.join(';'));
        }
      } else {
        nowSelected = Array.from(new Set([...pasteText.split(';')]));
        if (nowSelected.length) {
          this.$emit('user-select-change', nowSelected[0]);
          this.searchUser(nowSelected[0]);
        }
      }
    },
  },
};
</script>

<style lang="less">
.user-item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .user-avatar {
    width: 28px;
    height: 28px;
    border-radius: 14px;
  }
}
</style>
