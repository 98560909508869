"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Space = void 0;
var composition_api_1 = require("@vue/composition-api");
var vue_1 = require("@formily/vue");
var vant_1 = require("@formily/vant");
var stylePrefix = 'formily-vant';
exports.Space = (0, composition_api_1.defineComponent)({
    name: 'FSpace',
    props: ['size', 'direction', 'align', 'mobile'],
    setup: function (props, _a) {
        var slots = _a.slots;
        var layout = (0, vant_1.useFormLayout)();
        var field = (0, vue_1.useField)();
        field.value.decoratorType = null;
        return function () {
            var _a;
            var _b, _c, _d, _e, _f;
            var align = props.align, _g = props.size, size = _g === void 0 ? (_c = (_b = layout.value) === null || _b === void 0 ? void 0 : _b.spaceGap) !== null && _c !== void 0 ? _c : 'small' : _g;
            var direction = props.mobile ? props.direction : 'vertical';
            var prefixCls = "".concat(stylePrefix, "-space");
            var children = (_d = slots.default) === null || _d === void 0 ? void 0 : _d.call(slots);
            var items = [];
            if (Array.isArray(children)) {
                if (children.length === 1) {
                    if ((_e = children[0]['tag']) === null || _e === void 0 ? void 0 : _e.endsWith('Fragment')) {
                        // Fragment hack
                        items = (_f = children[0]['componentOptions']) === null || _f === void 0 ? void 0 : _f.children;
                    }
                    else {
                        items = children;
                    }
                }
                else {
                    items = children;
                }
            }
            var len = items.length;
            if (len === 0) {
                return null;
            }
            var mergedAlign = align === undefined ? 'center' : align;
            var someSpaceClass = (_a = {},
                _a[prefixCls] = true,
                _a["".concat(prefixCls, "-").concat(direction)] = true,
                _a["".concat(prefixCls, "-align-").concat(mergedAlign)] = mergedAlign,
                _a);
            var itemClassName = "".concat(prefixCls, "-item");
            var renderItems = items.map(function (child, i) {
                return (0, vue_1.h)('div', {
                    // class: itemClassName,
                    style: { width: '100%' },
                    key: "".concat(itemClassName, "-").concat(i),
                }, { default: function () { return [child]; } });
            });
            return (0, vue_1.h)('div', { class: someSpaceClass, style: { width: '100%' } }, { default: function () { return renderItems; } });
        };
    },
});
exports.default = exports.Space;
