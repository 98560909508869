"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Input = exports.inputValidate = exports.BaseInput = void 0;
var reactive_vue_1 = require("@formily/reactive-vue");
var core_1 = require("@formily/core");
var vue_1 = require("@formily/vue");
var composition_api_1 = require("@vue/composition-api");
var vant_1 = require("vant");
var shared_1 = require("@formily/vant/esm/__builtins__/shared");
var vant_2 = require("@formily/vant");
var stylePrefix = 'formily-vant';
var TransformVanInput = (0, shared_1.transformComponent)(vant_1.Field, {
    change: 'input',
});
exports.BaseInput = (0, reactive_vue_1.observer)((0, composition_api_1.defineComponent)({
    name: 'FBaseInput',
    setup: function (props, _a) {
        var attrs = _a.attrs, listeners = _a.listeners;
        var field = (0, vue_1.useField)();
        return function () {
            var _a;
            var _b;
            return (0, vue_1.h)(TransformVanInput, {
                class: (_a = {},
                    _a["".concat(stylePrefix, "-input-asterisk")] = attrs.asterisk || field.value.decoratorProps.asterisk,
                    _a),
                attrs: __assign(__assign(__assign({}, attrs), props), { 'data-grid-span': field.value.decoratorProps.gridSpan, clearable: (_b = field.value.componentProps.clearable) !== null && _b !== void 0 ? _b : false, autosize: true, type: field.value.componentType === 'TextArea' ? 'textarea' : '', disabled: field.value.pattern === 'disabled', readonly: field.value.pattern === 'readonly', placeholder: field.value.componentProps.placeholder || '请填写' + field.value.title }),
                on: listeners,
            }, {
                extra: function () { return [field.value.decoratorProps.addonAfter]; },
                label: field.value.description
                    ? function () { return [
                        (0, vue_1.h)(vant_1.Cell, {
                            attrs: {
                                label: field.value.description,
                                title: field.value.title,
                            },
                        }, {}),
                    ]; }
                    : undefined,
            });
        };
    },
}));
exports.inputValidate = (0, vue_1.mapProps)({ validateStatus: true, title: 'label', required: true }, function (props, field) {
    if ((0, core_1.isVoidField)(field))
        return props;
    if (!field)
        return props;
    var takeMessage = function () {
        var split = function (messages) {
            return messages.reduce(function (buf, text, index) {
                if (!text)
                    return buf;
                return index < messages.length - 1 ? buf.concat([text, ', ']) : buf.concat([text]);
            }, []);
        };
        if (field.validating)
            return;
        if (props.feedbackText)
            return props.feedbackText;
        if (field.selfErrors.length)
            return split(field.selfErrors);
        if (field.selfWarnings.length)
            return split(field.selfWarnings);
        if (field.selfSuccesses.length)
            return split(field.selfSuccesses);
    };
    var errorMessages = takeMessage();
    return {
        errorMessage: (0, shared_1.resolveComponent)(Array.isArray(errorMessages) ? errorMessages.join('') : errorMessages),
        extra: props.extra || field.description,
    };
}, function (props, field) {
    var _a;
    if ((0, core_1.isVoidField)(field))
        return props;
    if (!field)
        return props;
    return {
        feedbackStatus: field.validateStatus === 'validating'
            ? 'pending'
            : (Array.isArray(field.decorator) && ((_a = field.decorator[1]) === null || _a === void 0 ? void 0 : _a.feedbackStatus)) ||
                field.validateStatus,
    };
}, function (props, field) {
    if ((0, core_1.isVoidField)(field))
        return props;
    if (!field)
        return props;
    var asterisk = false;
    if (field.required && field.pattern !== 'readPretty') {
        asterisk = true;
    }
    if ('asterisk' in props) {
        asterisk = props.asterisk;
    }
    return {
        asterisk: asterisk,
    };
});
exports.Input = (0, vue_1.connect)(exports.BaseInput, (0, vue_1.mapReadPretty)(vant_2.PreviewText.Input), exports.inputValidate);
exports.default = exports.Input;
