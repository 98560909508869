<template>
  <div class="common-select">
    <el-select
      ref="select"
      v-model="selected"
      size="small"
      :remote="!!requestUrl"
      :class="['w', { collapsed }]"
      :placeholder="placeholder"
      :allow-create="allowCreate"
      :disabled="disabled"
      :multiple="multiple"
      filterable
      :loading="loading"
      reserve-keyword
      clearable
      :no-data-text="allowCreate ? '请手动输入，回车确认' : '无数据'"
      default-first-option
      :collapse-tags="collapsed"
      :filter-method="handleFilter"
      :remote-method="fetchDropdownData"
      @paste.native="(e) => allowPaste && multiple && onPaste(e)"
      @copy.native="(e) => allowPaste && multiple && onCopy(e)"
      @change="onChange"
      @visible-change="onVisibleChange"
      @remove-tag="onTagDelete"
      @focus="onFocus"
      @hook:mounted="closeReadOnly"
    >
      <el-option
        v-for="item in filteredOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />
    </el-select>
  </div>
</template>
<script>
import request from '@/http/request';

export default {
  name: 'CommonSelect',
  model: {
    prop: 'value',
    event: 'select-change',
  },
  props: {
    // 选中项，多选必须传array，单选必须传string
    value: {
      type: Array | String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    allowCreate: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    // 下拉选项列表：{ label: string, value: string }[]
    options: {
      type: Array,
      default: () => [],
    },
    // 需要远程搜索的，必传requestUrl，否则不传
    requestUrl: {
      type: String,
      default: '',
    },
    // 是否需要支持批量复制粘贴，仅在多选模式下生效
    allowPaste: {
      type: Boolean,
      default: false,
    },
    // 选项最大数量，用于避免选项过多导致卡顿，默认-1则展示全量选项数据
    maxOptionsLength: {
      type: Number,
      default: -1,
    },
    // 用于搜索的索引名称
    indexName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selected: [],
      dropdownOptions: [],
      filteredOptions: [],
      collapsed: true,
      loading: false,
    };
  },
  watch: {
    value: {
      handler(newValue) {
        this.selected = this.multiple ? [...(newValue || [])] : newValue;
        // 多选模式下，若既不远程搜索也不传选项列表，则直接用value作为选项列表
        if (!this.requestUrl && !this.options.length && this.multiple) {
          this.dropdownOptions = this.selected.map((item) => ({
            value: item,
            label: item,
          }));
          this.setFilteredOptions();
        }
      },
      immediate: true,
    },
    options: {
      handler(newValue) {
        if (!this.requestUrl && !newValue.length && this.multiple) return;
        this.dropdownOptions = [...newValue];
        this.setFilteredOptions();
      },
      immediate: true,
    },
  },
  methods: {
    onFocus(val) {
      this.closeReadOnly(val);
      !this.filteredOptions.length && !!this.requestUrl && this.fetchDropdownData('');    
    },
    onChange(newSelect) {
      this.$emit('select-change', newSelect);
    },
    closeReadOnly(val) {
      this.$nextTick(() => {
        if (!val) {
          const input = this.$refs.select.$el.querySelector('.el-input__inner');
          const timer = setTimeout(() => {
            input.removeAttribute('readonly');
            clearTimeout(timer);
          }, 200);
        }
      });
    },
    onVisibleChange(flag) {
      this.closeReadOnly(flag);
      this.collapsed = !flag;
      // 手动触发select组件高度样式更新
      this.$refs.select.resetInputHeight();
    },

    // 解决删除标签后select组件失去焦点的问题
    onTagDelete() {
      this.$refs.select.$refs.input?.focus();
    },

    // 支持批量复制
    onCopy(event) {
      event.preventDefault();
      try {
        (event.clipboardData || window.clipboardData).setData(
          'text/plain',
          this.selected.join(', ')
        );
        this.$message.success('已复制全部已选内容到剪切板');
      } catch (e) {
        this.$message.error('复制失败');
      }
    },

    // 支持批量粘贴
    onPaste(event) {
      event.preventDefault();
      try {
        const pasteText = (event.clipboardData || window.clipboardData)
          .getData('text')
          .replace(/[,;；，\s]+/g, ';')
          .replace(/^;|;$/g, '')
          .replace(/\([\W]+\)/g, '')
          .split(';');
        this.$emit('select-change', [...new Set([...this.selected, ...pasteText])]);
        if (this.allowCreate && !this.options.length) {
          // 把粘贴过来的内容同步到选项里去
          this.dropdownOptions = [...new Set([...this.value, ...pasteText])].map((item) => ({
            value: item,
            label: item,
          }));
          this.setFilteredOptions();
        }
        this.$message.success('批量粘贴成功');
      } catch (e) {
        this.$message.error('粘贴失败，请检查文字格式', e);
      }
    },

    // 支持模糊搜索
    handleFilter(query) {
      if (!!this.requestUrl) return;

      const keyword = query.trim();
      const filteredOptions = this.dropdownOptions.filter(
        (item) =>
          item.label.toLowerCase().includes(keyword.toLowerCase()) ||
          item.value.toLowerCase().includes(keyword.toLowerCase())
      );
      this.filteredOptions =
        this.maxOptionsLength === -1
          ? filteredOptions
          : filteredOptions.slice(0, this.maxOptionsLength);
    },

    // 支持远程搜索
    async fetchDropdownData(query) {
      const searchQuery = query ? query.trim() : '';
      this.filteredOptions = [];
      this.$refs.select.resetHoverIndex();
      this.loading = true;
      try {
        const { data } = await request({
          url: this.requestUrl,
          method: 'GET',
          params: {
            Keyword: searchQuery,
            Limit: this.maxOptionsLength,
            IndexName: this.indexName,
          },
        });
        if (data.status !== 0 || !Array.isArray(data.data)) {
          throw new Error(data.msg);
        } else {
          this.filteredOptions = data.data;
        }
      } catch (e) {
        this.filteredOptions = [];
      } finally {
        this.loading = false;
      }
    },

    setFilteredOptions() {
      this.filteredOptions =
        this.maxOptionsLength === -1
          ? this.dropdownOptions
          : this.dropdownOptions.slice(0, this.maxOptionsLength);
    },
  },
};
</script>

<style lang="less" scoped>
.common-select {
  position: relative;
  min-height: 32px;
  width: 100%;
}
.el-select {
  width: 100%;
  /deep/ &:not(.collapsed) {
    position: absolute;
    z-index: 10;
    .el-select__input {
      flex: 1 1 100%;
    }
  }
  /deep/ &.collapsed {
    .el-select__tags {
      flex-wrap: nowrap;
    }
  }
  /deep/ .el-select__tags {
    overflow: hidden;
    max-height: 20em;
    &:hover {
      overflow: overlay;
    }
  }
}
</style>
