import { observer } from '@formily/reactive-vue';
import { connect, mapProps, mapReadPretty, h, useField } from '@formily/vue';
import { defineComponent, ref, watch } from '@vue/composition-api';
import { Uploader as VanUploader, Toast } from 'vant';
import { PreviewText } from '@formily/vant';
import 'vant/lib/uploader/index.less';
import '@formily/vant/esm/preview-text/style';
import request from 'axios';

// const TransformVanUploader = transformComponent(VanUploader, {
//   change: 'input',
// });

const BaseUploader = observer(
  defineComponent({
    name: 'FBaseUploader',
    props: ['value'],
    setup(props, { attrs, slots, listeners, emit }) {
      const field = useField();
      field.value.decoratorType = field.value.title ? 'FormItem' : null;
      const valueList = ref([]);
      const filePathList = ref([]);
      watch(
        () => props.value,
        () => {
          filePathList.value = props.value || [];
          valueList.value = props.value ? props.value.map((item) => item.response.FileList[0]) : [];
        },
        { deep: true, immediate: true }
      );

      const afterRead = async (file) => {
        const userId = sessionStorage.getItem('userid');
        const fileList = file.length ? [...file] : [file];
        for (file of fileList) {
          valueList.value.push({ status: 'uploading', message: '上传中...' });
          try {
            const reqData = new FormData();
            reqData.append(field.value.componentProps?.name || 'file', file.file);
            if (field.value.componentProps?.data) {
              Object.entries(field.value.componentProps?.data).forEach(([key, value]) => {
                reqData.append(key, value);
              });
            }
            const { data } = await request.post(field.value.componentProps?.action || `/relay/cosfile/index`, reqData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                ...(field.value.componentProps?.headers || {}),
                authToken: sessionStorage.getItem('AuthToken'),
                userid: sessionStorage.getItem('userid'),
              },
            });
            if (!data?.FileList?.length) {
              throw new Error();
            } else {
              filePathList.value.push(
                ...data?.FileList?.map((item) => ({
                  name: file.file.name,
                  response: {
                    FileList: [{ ...item, name: file.file.name }],
                  },
                }))
              );
              valueList.value.splice(-1, 1, ...data?.FileList?.map(({ url }) => ({ url })));
            }
          } catch (e) {
            Toast.fail('上传失败，请稍后再试');
            valueList.value.pop();
          }
        }
        // field.value.setValue(filePathList.value);
        emit('change', filePathList.value);
        console.log(valueList);
      };

      const beforeDelete = ({ url }) => {
        const index = valueList.value.findIndex((item) => item.url === url);
        valueList.value.splice(index, 1);
        filePathList.value.splice(index, 1);
        // field.value.setValue(filePathList.value);
        emit('change', filePathList.value);
      };

      const oversize = () => Toast.fail('上传文件大小不能超过300M');

      return () =>
        h(
          VanUploader,
          {
            attrs: {
              accept: '*',
              ...attrs,
              maxCount: attrs.maxCount ?? undefined,
              fileList: valueList.value,
              maxSize: 300000 * 1024,
              afterRead,
              beforeDelete,
              'result-type': 'file',
            },
            on: {
              ...listeners,
              oversize,
              'click-preview': (file, detail) => {
                const fileType = file?.url?.split?.('.');
                console.log(file, detail, fileType);
                if (
                  Array.isArray(fileType) &&
                  !['jpg', 'jpeg', 'png', 'svg', 'bmp'].some((item) =>
                    fileType?.[fileType.length - 1].endsWith(item)
                  )
                ) {
                  window.open(file.url);
                }
              },
              click(e) {
                emit('emit', e);
              },
            },
          },
          slots
        );
    },
  })
);

export const Uploader = connect(
  BaseUploader,
  mapProps({ readOnly: 'readonly' }),
  mapReadPretty(PreviewText.Uploader)
);

export default Uploader;
