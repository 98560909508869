import { observer } from '@formily/reactive-vue';
import { connect, mapProps, mapReadPretty, h, useField } from '@formily/vue';
import { ref, defineComponent, watch } from '@vue/composition-api';
import { Cascader as VanCascader, Popup as VanPopup } from 'vant';
import { formilyFormItem } from './index';
import { PreviewText } from '@formily/vant';

const BaseCascader = observer(
  defineComponent({
    name: 'FBaseCascader',
    props: ['value', 'options'],
    setup(props, { attrs, emit, slots }) {
      const {
        formItemProps = {},
        popupProps = {},
        cascaderProps = {},
        fieldListeners = {},
        popupListeners = {},
        cascaderListeners = {},
      } = attrs;
      const show = ref(false);
      const field = useField();
      const fieldValue = ref([]);

      watch(
        () => props.value,
        (newVal) => {
          if (Array.isArray(newVal) && Array.isArray(props.options)) {
            let tmp = props.options;
            const res = [];
            newVal.forEach((item) => {
              const target = tmp.find((obj) => obj[cascaderProps?.fieldNames?.value || 'value'] === item);
              res.push(target[cascaderProps?.fieldNames?.text] || target.label || target.text);
              tmp = target.children || [];
            });
            fieldValue.value = res || [];
          }
        },
        { deep: true, immediate: true }
      );
      return () =>
        h(
          'div',
          {},
          {
            default: () => [
              h(
                formilyFormItem,
                {
                  attrs: {
                    ...formItemProps,
                    readonly: true,
                    isLink: field.value.pattern === 'editable',
                  },
                  on: {
                    click: () => {
                      if (field.value.pattern !== 'editable') return;
                      show.value = true;
                    },
                    ...fieldListeners,
                  },
                },
                {
                  ...slots,
                  default: () => [fieldValue.value ? fieldValue.value.join('/') : ''],
                }
              ),
              h(
                VanPopup,
                {
                  attrs: {
                    value: show.value,
                    round: true,
                    position: 'bottom',
                    ...popupProps,
                  },
                  on: {
                    input: (val) => {
                      show.value = val;
                    },
                    ...popupListeners,
                  },
                },
                {
                  default: () => [
                    h(
                      VanCascader,
                      {
                        attrs: {
                          fieldNames: { text: 'label', value: 'value', children: 'children' },
                          ...cascaderProps,
                          ...props,
                          value: Array.isArray(props.value)
                            ? props.value[props.value.length - 1]
                            : props.value,
                        },
                        on: {
                          close: () => {
                            show.value = false;
                          },
                          finish: (val) => {
                            const selected = val.selectedOptions
                              ? val.selectedOptions.map((item) => item[cascaderProps?.fieldNames?.value || 'value'])
                              : [];
                            console.log(selected);
                            emit('change', selected);
                            show.value = false;
                          },
                          ...cascaderListeners,
                        },
                      },
                      {}
                    ),
                  ],
                }
              ),
            ],
          }
        );
    },
  })
);

export const Cascader = connect(
  BaseCascader,
  mapProps({ readOnly: 'readonly', dataSource: 'options' }),
  mapReadPretty(PreviewText.Cascader)
);

export default Cascader;
