<template>
  <div class="todo-wrapper" v-loading.lock.fullscreen="taskLoading">
    <van-button
      ref="button"
      @click="scrollBottom"
      round
      style="position: fixed; z-index: 9999; right: 10px; bottom: 10px"
      size="small"
      plain
      type="primary"
      icon="arrow-down"
    ></van-button>
    <van-button
      ref="transfer"
      @click="showHandoverTodo(0)"
      round
      style="position: fixed; z-index: 9999; left: 10px; bottom: 10px"
      size="small"
      plain
      type="primary"
      icon="share-o"
    ></van-button>
    <van-button
      class="backBtn"
      size="small"
      style="position: fixed; z-index: 9999; right: 10px; bottom: 1.4rem"
      plain
      type="primary"
      @click="feedback"
      >?</van-button
    >
    <div v-for="(todoItem, todoIndex) in todoComponent" :key="todoItem.taskId">
      <p class="section-title ml10" style="display: inline-block">待办详情</p>
      <el-tag v-if="todoItem.realTime" class="ml20" type="warning"
        >该表单中的部分数据会实时更新</el-tag
      >
      <!-- 待办信息 -->
      <div>
        <!-- <van-cell title="处理人" :value="todoItem.ProcessUsers" /> -->
        <van-cell title="创建时间" :value="todoItem.CreateTime" />
        <!-- <div v-for="(infoItem) in todoItem.nodeBasicInfo" :key="infoItem.name"> -->
        <van-cell
          v-show="
            infoItem.data &&
            infoItem.name &&
            !infoItem.name.includes('流程ID') &&
            infoItem.name !== '任务ID'
          "
          v-for="infoItem in todoItem.nodeBasicInfo"
          :key="infoItem.name"
          :title="infoItem.name"
          :value="infoItem.data || '-'"
        />
        <!-- </div> -->
        <TodoView :todoIndex="todoIndex" :todoViewsData="todoItem.todoViewsData" />
      </div>
      <!-- 待办操作 -->
      <div v-if="todoItem.operateFormSchema && todoItem.operateFormSchema.schema">
        <todo-form
          :ref="'todo-form-' + todoItem.taskId"
          :schema="todoItem.operateFormSchema.schema"
          :values="{
            ...todoItem.operateFormData,
            $context: {
              instanceId: todoItem.instanceId,
              parent_id: todoItem.parentId,
              parent_task_id: todoItem.ParentTaskId,
              service_name: todoItem.AjaxService,
              taskId: todoItem.taskId,
              svckey: todoItem.FlowServiceKey || 'flowable',
              user: username,
              processUsers: todoItem.ProcessUsers,
              ticketId,
            },
          }"
        />
      </div>
      <van-form v-for="operItem in todoItem.operateViewsData" v-else :key="operItem.name">
        <van-cell
          v-if="
            operItem.type === 'KVField' ||
            (!operItem.Type && !operItem.type) ||
            operItem.Type === 'KVField'
          "
          :label="operItem.name ? operItem.name + ':' : operItem.KeyName + ':'"
          label-width="120px"
          :value="operItem.data || operItem.Value"
        />

        <!-- input类型 -->
        <van-field
          v-else-if="operItem.type === 'TextArea'"
          v-model="operItem.data"
          type="textarea"
          :label="operItem.name + ':'"
        />

        <!-- link类型 兼容流程中间数据 -->
        <van-cell
          v-else-if="operItem.type === 'Link' || operItem.Type === 'Link'"
          :label="operItem.KeyName ? operItem.KeyName + ':' : operItem.name + ':'"
        >
          <el-link
            v-if="operItem.Value"
            :href="operItem.Value"
            class="f12"
            type="primary"
            target="_blank"
          >
            {{ operItem.Value }}
          </el-link>
          <el-link v-else :href="operItem.data" class="f12" type="primary" target="_blank">
            {{ operItem.data }}
          </el-link>
        </van-cell>

        <van-field
          readonly
          v-else-if="operItem.type === 'Radio'"
          :label="operItem.name ? operItem.name + ':' : ''"
        >
          <template #input>
            <van-radio-group v-model="operItem.data" class="flex-h-between w">
              <van-radio v-for="radio in operItem.options" :key="radio.name" :name="radio.data">
                {{ radio.name }}
              </van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          readonly
          v-else-if="operItem.type === 'Checkbox'"
          :label="operItem.name ? operItem.name + ':' : ''"
        >
          <template #input>
            <van-checkbox-group v-model="operItem.data" class="flex-h-between w">
              <van-checkbox
                v-for="checkbox in operItem.options"
                :key="checkbox.name"
                :name="checkbox.data"
              >
                {{ checkbox.name }}
              </van-checkbox>
            </van-checkbox-group>
          </template>
        </van-field>
        <van-field
          v-else-if="operItem.type === 'RichEditor'"
          :label="operItem.name ? operItem.name + ':' : ''"
        >
          <quill-editor v-model="operItem.data" />
        </van-field>
      </van-form>
      <div class="m10 flex-h-between implementButton">
        <div v-for="nodeItem in todoItem.nodeButton" :key="nodeItem.type + nodeItem.name">
          <van-button
            v-loading.fullscreen.lock="fullscreenLoading"
            :type="
              nodeItem.buttonType
                ? nodeItem.buttonType === 'success'
                  ? 'primary'
                  : nodeItem.buttonType
                : 'primary'
            "
            :disabled="todoItem.disabled"
            @click="submitOperation(nodeItem, todoIndex)"
          >
            {{ nodeItem.name }}
          </van-button>
          <!-- <template>
            <div>
              <van-popup v-model="showPicker" round position="bottom">
                <van-picker
                  title="请选择超时原因"
                  show-toolbar
                  :columns="columns"
                  @cancel="showPicker = false"
                  @confirm="onConfirm"
                />
              </van-popup>
            </div>

            <van-dialog
              title="填写超时原因后会自动结单"
              v-model="outTimeStatus"
              show-cancel-button
              @confirm="submitOperation({ nodeItem, todoIndex, type: 'other' })"
              @cancel="outTimeStatus = false"
            >
              <el-form ref="transferForm" :model="outTimeInfo" label-width="100px" class="mt10">
                <el-form-item
                  label="超时原因:"
                  prop="names"
                  :rules="{ required: true, message: '超时原因不能为空', trigger: 'change' }"
                >
                  <el-col :span="18">
                    <van-field v-model="outTimeInfo" placeholder="请输入超时原因" />
                  </el-col>
                </el-form-item>
              </el-form>
            </van-dialog>
          </template> -->
        </div>
        <van-button
          v-if="!todoItem.hideReset && !!Object.keys(todoItem.operateFormSchema || {}).length"
          type="default"
          plain
          @click="onClearForm(todoIndex)"
        >
          重置
        </van-button>
        <van-button
          v-if="!todoItem.hideHandover"
          :disabled="todoItem.disabled"
          type="primary"
          @click="showHandoverTodo(todoIndex)"
        >
          待办转派
        </van-button>
        <van-button
          v-for="nodeItem in todoItem.extraButton"
          :key="nodeItem.type"
          v-loading.fullscreen.lock="fullscreenLoading"
          :type="nodeItem.buttonType ? nodeItem.buttonType : 'danger'"
          :disabled="todoItem.disabled"
          @click="submitOperation(nodeItem, todoIndex, nodeItem.type === 'cancel')"
        >
          {{ nodeItem.name || '取消' }}
        </van-button>
      </div>
      <!-- 转派模态框 -->
      <van-dialog
        title="转派待办给他人"
        v-model="transferDialog"
        show-cancel-button
        append-to-body
        @confirm="submitTransferForm"
        @cancel="transferDialog = false"
      >
        <el-form ref="transferForm" :model="transferPerson" label-width="100px" class="mt10">
          <el-form-item
            label="接收人:"
            prop="name"
            :rules="{ required: true, message: '接收人不能为空', trigger: 'change' }"
          >
            <el-col :span="18">
              <user-select
                v-model="transferPerson.name"
                placeholder="请输入"
                multiple
                :allowPaste="false"
              ></user-select>
            </el-col>

            <el-col :span="4" style="margin-left: 5px">
              <van-icon name="add-o" @click="allProcessor"/>
            </el-col>
          </el-form-item>
        </el-form>
      </van-dialog>

      <!-- 问题反馈框 -->
      <van-dialog title="工单问题反馈" v-model="questionDialog" :show-confirm-button="false">
        <van-form @submit="onSubmit">
          <van-field
            readonly
            clickable
            name="问题分类"
            label="问题分类"
            :value="formData.problem_type"
            placeholder="选择问题分类"
            @click="showPicker = true"
            required
            :rules="[{ required: true, message: '请填写问题分类' }]"
          />
          <van-popup v-model="showPicker" round position="bottom">
            <van-picker
              show-toolbar
              :columns="troubleData"
              @cancel="showPicker = false"
              @confirm="onConfirms"
            />
          </van-popup>
          <van-field
            v-model="formData.problem_desc"
            name="问题描述"
            label="问题描述"
            type="textarea"
            placeholder="请输入问题描述"
            required
            :rules="[{ required: true, message: '请填写问题描述' }]"
          />
          <van-field name="uploader" label="文件上传">
            <template #input>
              <van-uploader
                v-model="formData.fileList"
                :max-size="100 * 1024 * 1024"
                @oversize="onOversize"
                @delete="onDelete"
                :after-read="afterRead"
                :before-delete="beforeDelete"
                :max-count="6"
                accept="image/*"
                :beforeRead="beforeRead"
                multiple
              />
            </template>
          </van-field>
          <div class="footerDialog">
            <van-button block @click="onClose">取消</van-button>
            <van-button block type="info" native-type="submit">确定</van-button>
          </div>
        </van-form>
      </van-dialog>
      <div v-if="!todoComponent.length && !taskLoading" class="mt10 text-c text-info">
        <van-divider
          >待办已结束，请前往已办页面查看，<el-link @click="gotoHistory" type="primary"
            >点击跳转</el-link
          ></van-divider
        >
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { Message } from 'element-ui';
import { Dialog } from 'vant';
import { findIndex, cloneDeep } from 'lodash';
import { mapGetters } from 'vuex';
import UserSelect from '@/components/UserSelect';
import {
  getDataByNbroker,
  handoverTodo,
  queryNowN4,
  queryAllN4,
  getDataByNbrokerUrl,
  getVariables,
  feedbackTicketInfo,
} from '@/api/tickets/ticketsApi';
import { getTodoList } from '@/api/tickets/todoApi';
import { getFlowLogById, getSchemeDefinition } from '@/api/appDispose/planApi';
import TodoForm from '@/components/Formily/formilyTodoForm.vue';
import TodoView from './todoView.vue';
import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

export default {
  name: 'CommonTodo',
  components: { TodoForm, UserSelect, TodoView, quillEditor },
  async mounted() {
    this.troubleData = JSON.parse(this.getConfigItem('problem_type')).map((item) => item.label);
    this.getLocalData();
    // this.getFlowHistory(this.instanceId);
    await this.getTodoDataList();
    this.handleScroll();

    document.addEventListener('scroll', this.handleScroll);
    this.saver = setInterval(() => {
      try {
        const todos = this.todoComponent.map(({ operateViewsData, taskId }) => {
          const todoRef = this.$refs[`todo-form-${taskId}`][0];
          return { operateViewsData, operateFormData: todoRef.getValues(), taskId };
        });
        localStorage.setItem(`taskId=${this.taskId}`, JSON.stringify(this.deleteNull(todos)));
      } catch (e) {}
    }, 2000);
    if (this.isRefresh) {
      this.timer = setInterval(() => {
        this.getTodoDataList();
      }, 20000);
    }
  },
  destroyed() {
    document.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    ...mapGetters(['getConfigItem']),
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    if (this.saver) {
      clearInterval(this.saver);
    }
    if (this.todoTimer.length) {
      this.todoTimer.forEach((item) => {
        clearInterval(item);
      });
    }
  },
  data() {
    return {
      formData: {
        problem_type: '',
        problem_desc: '',
        fileList: [],
      },
      questionDialog: false,
      showPicker: false,
      isShowPreview: false,
      troubleData: [],
      ImgData: [],
      imageUrl: '',
      taskLoading: true,
      todoList: [],
      todoComponent: [],
      transferDialog: false,
      transferPerson: {
        name: '',
      },
      taskId: this.$route.query.taskId || '',
      schemeManager: '',
      currTodo: {},
      timer: null,
      nodeList: [],
      localData: [],
      fullscreenLoading: false,
      transDisabled: false,
      username: sessionStorage.getItem('userid'),
      flowLogData: [],
      todoTimer: [],
      currTodoComponent: {},
      container: document.querySelector('body'),
      showButton: false,
      // sessionStorage
    };
  },
  props: {
    ticketId: {
      type: String,
      default: '',
    },
    instanceId: {
      type: String,
      default: '',
    },
    ticketStatus: {
      type: String,
      default: '',
    },
    todoId: {
      type: String,
      default: '',
    },
  },
  methods: {
    // 选择当前全部处理人
    allProcessor() {
      this.$notify({ type: 'success', message: '已选择当前工单全部处理人' });
      this.transferPerson.name = this.todoComponent[0].ProcessUsers
    },

    feedback() {
      this.questionDialog = true;
    },
    onConfirms(value) {
      this.formData.problem_type = value;
      this.showPicker = false;
    },
    onSubmit() {
      Dialog.confirm({
        message: '提交后将自动拉群跟进工单问题，是否确认？',
      })
        .then(async () => {
          await feedbackTicketInfo({
            SceneType: '平台自用',
            TicketDescription: `异常工单问题反馈，问题描述：${this.formData.problem_desc}，工单号：${this.ticketId}`,
            ProcessDefinitionKey: 'trouble_ticket_feedback',
            TicketLevel: '3',
            TicketTitle: `【${this.ticketId}】异常工单问题反馈`,
            UserInfo: {
              Concern: this.$store.state.user.name,
              Creator: this.$store.state.user.name,
              Deal: this.$store.state.user.name,
            },
            SchemeNameCn: '工单问题反馈',
            CustomVariables: {
              problem_type: this.formData.problem_type,
              problem_ticket_id: this.ticketId,
              problem_img: this.ImgData,
              problem_desc: this.formData.problem_desc,
            },
            VueOperateUser: this.$store.state.user.name,
          });
          this.$toast('异常反馈成功');
          this.questionDialog = false;
        })
        .catch(() => {});
    },
    onClose() {
      this.questionDialog = false;
      this.formData.problem_type = this.formData.problem_desc = '';
      this.formData.fileList = [];
    },
    afterRead(file) {
      console.log('开始执行', file);
      if (Array.isArray(file)) {
        file.forEach((item) => {
          item.status = 'uploading';
          item.message = '上传中...';
          This.uploadMaterialImg(item);
        });
      } else {
        file.status = 'uploading';
        file.message = '上传中...';
        this.uploadMaterialImg(file);
      }
    },

    // 图片上传
    uploadMaterialImg(imgFile) {
      let This = this;
      let reqData = new FormData();
      let UserId = sessionStorage.getItem('userid');
      if (imgFile.length) {
        imgFile.map((item) => {
          let newName = this.type + item.file.name;
          const newFile = new File([item.file], newName);
          reqData.append('file', item.file);
        });
      } else {
        let newName = this.type + imgFile.file.name;
        const newFile = new File([imgFile.file], newName);
        reqData.append('file', newFile);
      }
      axios
        .post(`/relay/upload/img?UserId=${UserId}`, reqData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          if (res.status == 200) {
            imgFile.status = 'done';
            imgFile.message = '上传成功';
            this.formData.fileList.push({ url: res.data[0].path, name: imgFile.file.name });
            this.ImgData.push({
              response: {
                FileList: [
                  {
                    name: imgFile.file.name,
                    status: imgFile.status,
                    uid: imgFile.uid,
                    url: res.data[0].path,
                  },
                ],
              },
            });
            This.$toast('照片上传成功');
          } else {
            This.$toast('照片上传失败，请重新上传！');
          }
          console.log(this.formData, 'formData');
        })
        .catch((error) => {
          imgFile.status = 'failed';
          imgFile.message = '上传失败';
          This.$toast('照片上传失败，请重新上传！');
        });
    },

    onOversize(file) {
      this.$toast('文件大小不能超过 10M');
    },

    beforeDelete() {
      return true;
    },
    //删除图片
    onDelete() {
      console.log(this.formData, '???????');
    },
    //点击图片放大浏览
    previewImage(url) {
      console.log(url, 'url');
      this.isShowPreview = true;
      this.imageUrl = [url];
    },

    beforeRead(file) {
      console.log(file, 'file');
      const type = ['image/jpeg', 'image/png', 'image/jpg'];

      let isImage = true;
      if (file.length) {
        isImage = file.find((files) => type.includes(files.type));
      } else {
        isImage = type.includes(file.type);
      }
      // const isLt2M = file.size / 1024 / 1024 < 100;

      if (!isImage) {
        this.$toast('上传图片格式不正确');
      }

      // if (!isLt2M) {
      //   this.$toast('图片大小不能超过 100MB!');
      // }

      return isImage;
    },
    handleScroll() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop; // 滚动条距离顶部的距离
      let windowHeight = document.documentElement.clientHeight || document.body.clientHeight; // 可视区的高度
      let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight; //dom元素的高度，包含溢出不可见的内容
      // 滚动条到底部的条件scrollTop + windowHeight === scrollHeight
      if (scrollHeight - 80 <= scrollTop + windowHeight) {
        this.$refs.button.classList.add('hide');
        this.$refs.transfer.classList.add('hide');
        // this.$refs.feedback.classList.add('hide');
      } else {
        this.$refs.button.classList.remove('hide');
        this.$refs.transfer.classList.remove('hide');
        // this.$refs.feedback.classList.remove('hide');
      }
    },

    gotoHistory() {
      const url = this.$router.resolve({
        query: {
          ...this.$route.query,
          isHistory: '1',
        },
      });
      console.log(url);
      location.href = url.href;
    },
    scrollBottom(e) {
      scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
    },
    getLocalData() {
      try {
        const localStr = localStorage.getItem(`taskId=${this.taskId}`) || '[]';
        this.localData = JSON.parse(localStr);
      } catch {}
    },
    isUserHasAuth() {
      this.todoComponent.forEach((todoItem) => {
        const nowUser = this.username;
        // 如果是配置了管理员，则管理员拥有所有权限
        if (this.schemeManager && this.schemeManager.includes(nowUser)) {
          todoItem.disabled = false;
        }
        // 如果是之前没有配置权限的待办，则不做权限限制
        if (!todoItem.todoRole.name && todoItem.AjaxService !== 'AjaxExceptionTodo') {
          todoItem.disabled = false;
        }
        // 如果是之前没有配置权限的待办，则不做权限限制
        if (todoItem.todoRole.name === 'no' && todoItem.AjaxService !== 'AjaxExceptionTodo') {
          todoItem.disabled = false;
        }
        if (
          todoItem.todoRole.name === 'onlyDealer' &&
          todoItem.AjaxService !== 'AjaxExceptionTodo'
        ) {
          const nowUser = this.username;
          const pattern = /\(.+\)/;
          let users = todoItem.ProcessUsers;
          // 字符串情况统一处理成数组
          if (typeof todoItem.ProcessUsers === 'string') {
            users = todoItem.ProcessUsers.split(';');
          }
          users.forEach((user) => {
            if (user.replace(pattern, '') === nowUser) {
              todoItem.disabled = false;
            }
          });
        } else if (todoItem.todoRole.name === 'onlyNowN4') {
          const nowUser = this.username;
          queryNowN4().then((res) => {
            const users = res.data.FuncResult.Data;
            if (users.includes(nowUser)) {
              todoItem.disabled = false;
            }
          });
        } else if (todoItem.todoRole.name === 'allN4') {
          const nowUser = this.username;
          queryAllN4().then((res) => {
            const users = res.data.FuncResult.Data;
            if (users.includes(nowUser)) {
              todoItem.disabled = false;
            }
          });
        } else if (todoItem.todoRole.name === 'perm') {
          const nowUser = this.username;
          // const positionName = todoItem.todoRole.authProp.positionName;
          const { className } = todoItem.todoRole.authProp;
          for (let i = 0; i < className.length; i++) {
            const queryParams = {
              // PositionName: positionName,
              PositionName: 'Flowable待办权限角色控制',
              Properties: {
                待办分组名称: className[i],
              },
            };
            queryUserListByRole(queryParams).then((res) => {
              const users = res.data.Result;
              if (users.includes(nowUser)) {
                todoItem.disabled = false;
              }
            });
          }
        }
      });
    },
    handleCheckAllChange(checked, todoIndex, opIndex) {
      // 这里支持点击全选框后一键全选和反选
      this.todoComponent[todoIndex].operateViewsData[opIndex].data = checked
        ? this.todoComponent[todoIndex].operateViewsData[opIndex].options.map((x) => x.data)
        : [];
      this.isIndeterminate = false;
    },

    // 获取待办信息
    async getTodoDataList() {
      // if (this.todoId) {
      //   this.getTodoTaskDataById();
      // } else {
      await this.getTodoTaskData();
      // }
    },

    // 获取流程历史
    async getFlowHistory(instanceId) {
      try {
        const result = await getFlowLogById(instanceId, true);
        this.flowLogData = result.data;
        if (this.ticketStatus === 'END' && this.isForceEnd === '1') {
          this.flowLogData.push({
            TaskName: '流程结束(强制结单)',
            TaskStatus: '已完成',
            Note: this.forceEndReason,
            Operator: this.forceEndOperator,
            CreateTime: this.endTime,
            EndTime: this.endTime,
          });
        }
      } catch (err) {}
    },

    // 通过todoId取得待办数据
    async getTodoTaskDataById() {
      try {
        const result = await getTodoList({ TaskId: this.todoId });

        const { List } = result.data;
        // this.todoComponent = [];
        if (List.length) {
          this.todoList = List;
          if (!this.todoComponent.some((item) => item.taskId === List[0].TaskId)) {
            await this.getTodoTaskConfig(List[0], 0, '');
          }
        }
        this.isUserHasAuth();
      } catch (err) {}
    },

    // 获取该工单下的待办数据
    async getTodoTaskData() {
      try {
        this.taskLoading = true;
        const result = await getTodoList({ TaskId: this.todoId });
        const todoList = result.data.List;

        // 没有data，则该单是已结单状态
        if (todoList) {
          // const todoList = data;
          // this.todoComponent = [];
          if (todoList.length) {
            this.todoList = todoList;
            // 去掉完成的待办
            this.todoComponent = this.todoComponent.filter(
              (item) =>
                todoList.some((todo) => todo.TaskId === item.taskId) ||
                !todoItem.ProcessUsers.split(';').includes(this.username)
            );

            // 检查taskId，新增的才更新
            for (let i = 0; i < todoList.length; i++) {
              const todo = todoList[i];
              const schemeQueryData = {
                ResultColumns: {
                  FlowData: '',
                  Manager: '',
                },
                SearchCondition: {
                  SchemeName: todo.ProcessDefinitionKey,
                },
                Sorts: [],
                Limit: {
                  Size: 20,
                  Start: 0,
                },
              };
              const schemeFlowData = await getSchemeDefinition(schemeQueryData);
              this.schemeManager = schemeFlowData.data.List[0].Manager;
              const { nodeList } = schemeFlowData.data.List[0].FlowData.childShapes;
              this.nodeList = nodeList || [];
              const todoAuthRole = {
                name: '',
                authProp: {
                  positionName: '',
                  className: '',
                },
              };
              for (let j = 0; j < nodeList.length; j++) {
                if (nodeList[j].id === todo.TaskKey) {
                  if (nodeList[j].properties.authPermRole) {
                    todoAuthRole.name = nodeList[j].properties.authPermRole;
                  }
                  if (nodeList[j].properties.positionName) {
                    todoAuthRole.authProp.positionName = nodeList[j].properties.positionName;
                  }
                  if (nodeList[j].properties.className) {
                    todoAuthRole.authProp.className = nodeList[j].properties.className;
                  }
                  break;
                }
              }
              if (!this.todoComponent.some((item) => item.taskId === todo.TaskId)) {
                await this.getTodoTaskConfig(todo, i, todoAuthRole);
              }
            }
            this.isUserHasAuth();
            this.$emit('title-change', this.todoComponent[0] && this.todoComponent[0].todoName);
          } else {
            this.todoComponent = [];
          }
        } else {
          this.todoComponent = [];
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.taskLoading = false;
      }
    },

    // 通过nbroker取得配置中心的待办信息
    async getTodoTaskConfig(todoData, index, todoRole) {
      console.log('触发了111');
      try {
        let todoConfigResult = {};
        const params = {
          context: {
            service_name: todoData.AjaxService,
            method_name: 'query',
            instanceId: todoData.InstanceId,
            taskId: todoData.TaskId,
            parent_id: todoData.ParentInstanceId,
            parent_task_id: todoData.ParentTaskId,
            svckey: todoData.FlowServiceKey || 'flowable',
          },
          args: {},
        };
        if (todoData.TodoKey === 'ExternalTodo') {
          params.context.service_name = 'ExternalTodo';
        }

        // 这里不再直接通过http请求的方式访问nBroker接口，而是通过relay进行转发
        if (todoData.HandleUrl) {
          // todoConfigResult = await axios.post(todoData.HandleUrl, params);
          todoConfigResult = await getDataByNbrokerUrl(params, todoData.HandleUrl);
        } else {
          todoConfigResult = await getDataByNbroker(params);
        }

        const { result, exc_info: errInfo } = todoConfigResult.data;
        if (errInfo) {
          throw new Error(JSON.stringify(errInfo));
        }
        if (result.operate_views) {
          for (let i = 0; i < result.operate_views.length; i++) {
            const operateItem = result.operate_views[i];
            if (operateItem.type == 'Select' && operateItem.options_url != '') {
              result.operate_views[i] = await this.getSelectRemoteData(
                operateItem,
                operateItem.options_url
              );
            }
            if (operateItem.type == 'Upload' && !Array.isArray(operateItem.data)) {
              result.operate_views[i].data = [];
            }
          }
        }
        this.handleTodoComponentData(result, todoData, index, todoRole);
      } catch (err) {
        console.log(err);
        this.$toast({
          type: 'error',
          message: `从nbroker获取待办配置信息接口出错：${err.message}`,
        });
      }
    },

    deleteNull(obj) {
      try {
        return JSON.parse(JSON.stringify(obj), (key, value) => {
          if (value === null || value === '' || (Array.isArray(value) && value.length === 0))
            return undefined;
          return value;
        });
      } catch (e) {
        return {};
      }
    },

    // 处理配置中心返回的待办处理数据
    handleTodoComponentData(protocol, todoData, index, todoRole) {
      this.localData.find(({ taskId }) => console.log(taskId, todoData.TaskId)) || {};
      console.log(this.localData, todoData.TaskId, 222);
      const localData = this.localData.find(({ taskId }) => taskId === todoData.TaskId) || {};
      if (protocol) {
        const todoTmp = {
          nodeBasicInfo: [],
          todoViewsData: [],
          operateFormSchema: {},
          operateFormData: {},
          nodeButton: [],
          extraButton: [],
          operateViewsData: [],
        };
        if (todoRole.name === 'allN4') {
          todoTmp.roleLabel = '仅N4人员';
        } else if (todoRole.name === 'onlyNowN4') {
          todoTmp.roleLabel = '仅当前时段N4值班人员';
        } else if (todoRole.name === 'onlyDealer') {
          todoTmp.roleLabel = '仅处理人';
        } else if (todoRole.name === 'perm') {
          const classNameString = todoRole.authProp.className.join(';');
          todoTmp.roleLabel = `仅${classNameString}人员`;
        } else if (todoData.AjaxService) {
          todoTmp.roleLabel = '仅管理员';
        } else {
          todoTmp.roleLabel = ''; // 如果是没有配置，则这里置为空
        }
        todoTmp.taskId = todoData.TaskId;
        todoTmp.todoName = todoData.TodoName;
        todoTmp.taskName = todoData.TaskName;
        todoTmp.ProcessUsers = todoData.ProcessUsers;
        todoTmp.CreateTime = todoData.CreateTime;
        todoTmp.ParentTaskId = todoData.ParentTaskId;
        todoTmp.instanceId = todoData.InstanceId;
        todoTmp.AjaxService = todoData.AjaxService;
        todoTmp.ParentTaskId = todoData.ParentTaskId;
        todoTmp.FlowServiceKey = todoData.FlowServiceKey;
        const { properties } = this.nodeList.find((item) => item.id === todoData.TaskKey) || {};
        todoTmp.TodoType = properties && properties.todoType;
        todoTmp.nodeBasicInfo = protocol.basic_views;
        const taskName = this.getParentTaskName(todoData.ParentTaskId);
        todoTmp.nodeBasicInfo.push({
          data: taskName,
          name: '父节点名称',
        });
        todoTmp.todoViewsData = protocol.todo_views;
        todoTmp.ServiceName = protocol.service_name;
        todoTmp.ServiceUrl = protocol.service_url;

        let closeSaver = false;
        protocol.operate_views.forEach((item) => {
          if (item.type === 'Button') {
            todoTmp.nodeButton.push(item);
            item.callbackLink && (todoTmp.link = item.callbackLink);
            todoTmp.submitTips = item.submitTips;
          } else if (item.type === 'upload') {
            todoTmp.nodeButton.push(item);
          } else if (item.type === 'download') {
            todoTmp.nodeButton.push(item);
          } else if (item.type === 'cancel') {
            todoTmp.extraButton.push(item);
          } else if (item.type === 'handover') {
            todoTmp.hideHandover = item.hidden;
            this.todoList[index].handoverMethod = item.callback_method;
          } else if (item.type === 'reset') {
            todoTmp.hideReset = item.hidden;
          } else if (item.type === 'closeSaver') {
            closeSaver = item.value;
          } else {
            todoTmp.operateViewsData.push(item);
          }
        });
        if (protocol.operate_form_schema) {
          todoTmp.operateFormSchema = protocol.operate_form_schema;
          todoTmp.operateFormData =
            properties && properties.optimized
              ? {
                  ...this.deleteNull(protocol.operate_form_data),
                  ...((properties && properties.closeSaver) || closeSaver
                    ? {}
                    : this.deleteNull(localData.operateFormData || {})),
                }
              : this.deleteNull(protocol.operate_form_data);
          todoTmp.initData = cloneDeep(todoTmp.operateFormData);
          this.startPolling(todoData.TaskKey, Object.keys(protocol.operate_form_data), todoTmp);
        }
        todoTmp.todoRole = todoRole;
        todoTmp.disabled = true;
        this.todoComponent.splice(index, 0, todoTmp);
        if (properties && (properties.closeSaver || properties.optimized)) return;

        setTimeout(() => {
          this.todoComponent[index].operateFormData = Object.keys(localData.operateFormData || {})
            .length
            ? {
                ...this.deleteNull(protocol.operate_form_data),
                ...this.deleteNull(localData.operateFormData),
              }
            : this.deleteNull(protocol.operate_form_data);
        }, 100);
      }
    },
    onClearForm(index) {
      this.$dialog
        .alert({
          message: '重置表单数据为默认值，是否确认？',
          type: 'warning',
          showCancelButton: true,
        })
        .then(() => {
          this.$refs[`todo-form-${this.todoComponent[index].taskId}`][0].reset();
          this.todoComponent[index].operateFormData = {
            ...cloneDeep(this.todoComponent[index].initData),
          };
          this.$refs[`todo-form-${this.todoComponent[index].taskId}`][0].setValues(
            cloneDeep(this.todoComponent[index].initData)
          );
          localStorage.removeItem(`taskId=${this.taskId}`);
          // clearInterval(this.saver);
        })
        .catch(() => {});
    },
    async startPolling(todoKey, dataList, todoTmp) {
      const todoData = todoTmp;
      const { properties } = this.nodeList.find((item) => item.id === todoKey) || {};
      if (properties && properties.real_time_values && properties.real_time_values.length) {
        const timer = setInterval(async () => {
          try {
            const res = (await getVariables(properties.real_time_values, this.instanceId)) || {};
            const value = this.$refs[`todo-form-${todoTmp.taskId}`][0].getValues();
            // todoTmp.operateFormData = {
            //   ...todoTmp.operateFormData,
            //   ...this.deleteNull(res),
            // };
            this.$nextTick(() => {
              this.$refs[`todo-form-${todoTmp.taskId}`][0].values = this.deleteNull({
                ...this.deleteNull(res),
              });
              // todoTmp.operateFormData = this.$refs[`todo-form-${todoTmp.taskId}`][0].getValues()
            });
          } catch (e) {}
        }, 5000);
        todoData.realTime = true;
        this.todoTimer.push(timer);
      }
    },
    getParentTaskName(id) {
      let res = '';
      this.flowLogData.map((item) => {
        if (item.TaskId == id) {
          res = item.TaskName;
        }
      });
      return res;
    },

    // 提交待办操作
    async submitOperation(item, todoIndex, skipValidate = false) {
      const currTodo = this.todoList[todoIndex];
      const currTodoComponent = this.todoComponent[todoIndex];
      let processData = {};
      if (currTodoComponent.operateFormSchema && currTodoComponent.operateFormSchema.schema) {
        const todoForm = this.$refs[`todo-form-${currTodo.TaskId}`][0];
        if (!skipValidate) {
          try {
            await todoForm.validate();
          } catch (e) {
            this.$message.error('请填写必填项后再提交');
            const el = document.querySelector('.formily-todo div[validatestatus="error"]');
            window?.scrollTo?.({
              top: el.offsetTop, //需要父元素设置postion(relative、absolute、fixed)
              behavior: 'smooth',
            });
            return;
          }
        }
        processData = todoForm.getValues();
      } else {
        currTodoComponent.operateViewsData.forEach((operateItem) => {
          processData[operateItem.key] = operateItem.data;
        });
      }
      // 待办数据
      const params = {
        context: {
          instanceId: currTodo.InstanceId,
          method_name: currTodo.TodoKey === 'ExternalTodo' ? 'end' : item.callback_method,
          parent_id: currTodo.ParentInstanceId,
          parent_task_id: currTodo.ParentTaskId,
          service_name: currTodo.AjaxService,
          taskId: currTodo.TaskId,
          svckey: currTodo.FlowServiceKey || 'flowable',
        },
        args: {
          process_user: this.username,
          process_data: processData,
        },
      };
      if (currTodo.TodoKey === 'ExternalTodo') {
        params.context.service_name = 'ExternalTodo';
        params.args.url = currTodoComponent.ServiceUrl;
        params.args.service_name = currTodoComponent.ServiceName;
        params.args.method_name = item.callback_method;
      }

      // 待办提交弹窗
      let dataMsg = '';
      Object.keys(processData).forEach((key) => {
        if (typeof processData[key] === 'object') {
          return;
        }
        dataMsg += `<p> <b>${key} : ${processData[key]}</b> </p>`;
      });
      // const msg = `
      //     <p>待办名称：${currTodo.TodoName}</p>
      //     <p>待办提交数据：${dataMsg}</p>
      //     <br>
      //     <p>请确认操作的数据 <b>是否正确</b> 后提交！</p>`;
      this.$dialog
        .alert({
          message: currTodoComponent?.submitTips || '是否确认提交？',
          showCancelButton: true,
          type: 'warning',
        })
        .then(() => {
          this.todoCommit(params, currTodo.HandleUrl, todoIndex, currTodo.TaskId);
        })
        .catch(() => {});
    },

    // 点击转派
    showHandoverTodo(todoIndex) {
      // this.submitTransferForm();
      this.transferDialog = true;
      this.currTodo = this.todoList[todoIndex];
      this.currTodoComponent = this.todoComponent[todoIndex];
    },

    // 提交转派
    async submitTransferForm() {
      const { currTodoComponent } = this;
      const { currTodo } = this;
      let processData = {};
      if (currTodoComponent.operateFormSchema && currTodoComponent.operateFormSchema.schema) {
        const todoForm = this.$refs[`todo-form-${currTodo.TaskId}`][0];
        processData = todoForm.getValues();
      } else {
        currTodoComponent.operateViewsData.forEach((operateItem) => {
          processData[operateItem.key] = operateItem.data;
        });
      }
      this.$refs.transferForm[0].validate(async (valid) => {
        try {
          if (valid) {
            this.transDisabled = true;
            if (currTodo.handoverMethod) {
              const { data } = await getDataByNbrokerUrl(
                {
                  args: {
                    target_user: this.transferPerson.name,
                    now_person: sessionStorage.getItem('userid'),
                    process_data: processData,
                  },
                  context: {
                    instanceId: currTodo.InstanceId,
                    method_name: currTodo.handoverMethod,
                    parent_id: currTodo.ParentInstanceId,
                    parent_task_id: currTodo.ParentTaskId,
                    service_name: currTodo.AjaxService,
                    taskId: currTodo.TaskId,
                    svckey: currTodo.FlowServiceKey || 'flowable',
                  },
                },
                this.currTodo.HandleUrl
              );
            } else {
              await handoverTodo(
                this.transferPerson.name,
                sessionStorage.getItem('userid'),
                this.currTodo.TaskId
              );
            }
            this.transferDialog = false;
            this.transDisabled = false;
            this.$message.success('转派成功');
            this.$router.back();
          }
          this.$emit('fatherTodo');
        } catch (err) {
          this.transDisabled = false;
          this.$message.error(`转派失败: ${err.message}`);
        } finally {
          localStorage.removeItem(`taskId=${this.taskId}`);
          clearInterval(this.saver);
          this.refreshOneTodo(findIndex(this.todoComponent, { taskId: this.currTodo.TaskId }));
        }
      });
    },

    refreshOneTodo(index) {
      this.todoComponent.splice(index, 1);
      this.getTodoDataList();
    },

    // 操作提交待办
    async todoCommit(params, url, index, taskId) {
      try {
        this.fullscreenLoading = true;
        let queryResult = {};
        if (url) {
          // todo 这里修改待办的操作接口
          // queryResult = await axios.post(url, params);
          queryResult = await getDataByNbrokerUrl(params, url);
        } else {
          queryResult = await getDataByNbroker(params);
        }
        const { exc_info: excInfo, result } = queryResult.data;
        this.refreshOneTodo(index);
        if (result && result.code === -1 && result.msg) {
          this.fullscreenLoading = false;
          this.$dialog
            .alert({
              title: '提交失败',
              message: result.msg,
            })
            .then(() => {
              this.$refs[`todo-form-${taskId}`][0].values = params.args.process_data;
            });
          return;
        }
        if (excInfo) {
          throw new Error(excInfo.traceback ? excInfo.traceback : excInfo);
        }
        if (this.todoComponent[index] && this.todoComponent[index].link)
          location.href = this.todoComponent[index].link;
        this.$toast.success('待办操作成功！');
        this.$router.back();
      } catch (err) {
        Message.closeAll();
        this.$toast.fail(`待办操作失败: ${err.message}`);
        this.$router.back();
      } finally {
        this.fullscreenLoading = false;
        localStorage.removeItem(`taskId=${this.taskId}`);
        clearInterval(this.saver);
      }
    },
  },
};
</script>
<style scoped>
.todo-wrapper {
  width: 100vw !important;
  margin: 0;
  padding: 0;
}
.text-info {
  color: #909399;
}
.hide {
  visibility: hidden;
}
.backBtn {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  /* box-shadow: 1px 3px 10px 5px #ddd; */
  border: 1px solid #07c160;
  font-size: 18px;
  padding: 10px;
}
.footerDialog {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
</style>