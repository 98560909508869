import Vue from "vue";
import {
  Tab,
  Tabs,
  Card,
  Button,
  Tag,
  Icon,
  Divider,
  Tabbar,
  TabbarItem,
  NavBar,
  Collapse,
  CollapseItem,
  Switch,
  Cell,
  CellGroup,
  Grid,
  GridItem,
  Col,
  Row,
  Radio,
  RadioGroup,
  Field,
  Toast,
  Progress,
  Image as VanImage,
  Search,
  PullRefresh,  
  List,
  Uploader,
  Form,
  Dialog,
  Notify,
  ImagePreview,
  Loading,
  Overlay,
  Popup,
  Cascader,
  Checkbox,
  Picker,
  DropdownMenu,
  DropdownItem,
  Sidebar,
  SidebarItem,
  Calendar,
  Pagination,
  CheckboxGroup,
  NoticeBar,
  CountDown,
  Lazyload,
  Sticky,
  ActionSheet 
} from "vant";
// 注册
Vue.use(Sidebar);
Vue.use(SidebarItem);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Tag);
Vue.use(Card);
Vue.use(Button);
Vue.use(Icon);
Vue.use(Divider);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(NavBar);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Switch);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Col);
Vue.use(Row);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Field);
Vue.use(Notify);
Vue.use(Toast);
Vue.use(Progress);
Vue.use(VanImage);
Vue.use(Search);
Vue.use(PullRefresh);
Vue.use(List);
Vue.use(Uploader);
Vue.use(Form);
Vue.use(Dialog);
Vue.use(ImagePreview);
Vue.use(Loading);
Vue.use(Overlay);
Vue.use(Popup);
Vue.use(Cascader);
Vue.use(Checkbox);
Vue.use(Picker);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Calendar);
Vue.use(Pagination);
Vue.use(CheckboxGroup);
Vue.use(NoticeBar);
Vue.use(CountDown);
Vue.use(Lazyload);
Vue.use(Sticky);
Vue.use(ActionSheet);

