const getters = {
  getConfigItem: (state) => (key) => {
    if (key) {
      const [result] = state.config.items.filter((item) => item.key === key);
      return result ? result.value : '';
    }
    return '';
  },
};

export default getters;
