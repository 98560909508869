// import Vue from './composition.ts'
import '@/plugins/index.js';
import FastClick from 'fastclick';
import Vue from 'vue';

import App from '@/App.vue';
import '@/permission.js';
import router from '@/router/router.js';
import store from '@/store';
import VConsole from 'vconsole';
import '@/styles/index.less';
import '@/styles/transition.css';

// 组件注册

// element
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import '@/styles/index.less';

// 适配
import 'amfe-flexible';
import vueEsign from 'vue-esign';
import VueCompositionApi from '@vue/composition-api';
Vue.use(vueEsign);
Vue.use(ElementUI, { size: 'small' });
Vue.use(VueCompositionApi);
// FastClick.attach(document.body);
// 或者使用配置参数来初始化，详情见文档
if (process.env.NODE_ENV === 'development') {
  const vConsole = new VConsole({ theme: 'dark' });
}

const { fetch: originalFetch } = window;
window.fetch = async (...args) => {
  let [resource, config ] = args;

  config.headers = {
    ...config.headers,
    userid: sessionStorage.getItem('userid') || ''
  }
  // request interceptor ends
  const response = await originalFetch(resource, config);
  // response interceptor here
  return response;
};

// 只需修改xxxxx值，其余无需修改
Vue.prototype.resetSetItem = function (key, newVal) {
  if (key === 'TaskTypeList') {
    // 创建一个StorageEvent事件
    const newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val);
        // 初始化创建的事件
        newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
        // 派发对象
        window.dispatchEvent(newStorageEvent);
      },
    };
    return storage.setItem(key, newVal);
  }
};

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
