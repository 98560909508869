import { defineComponent, inject, ref, watch, onMounted, computed, onUnmounted } from '@vue/composition-api';
import { Card, Empty, Row, Button } from 'element-ui';
import { clone, isValid } from '@formily/shared';
import { useField, useFieldSchema, RecursionField, h } from '@formily/vue';
import { observer } from '@formily/reactive-vue';
import { stylePrefix } from '@formily/element/lib/__builtins__/configs';
import { ArrayBase } from '@formily/element';
import { composeExport } from '@formily/element/lib/__builtins__/shared';
import { List, Toast } from 'vant';

const isAdditionComponent = (schema) => schema['x-component']?.indexOf('Addition') > -1;

const isIndexComponent = (schema) => schema['x-component']?.indexOf('Index') > -1;

const isRemoveComponent = (schema) => schema['x-component']?.indexOf('Remove') > -1;

const isMoveUpComponent = (schema) => schema['x-component']?.indexOf('MoveUp') > -1;

const isMoveDownComponent = (schema) => schema['x-component']?.indexOf('MoveDown') > -1;

const isOperationComponent = (schema) =>
  isAdditionComponent(schema) ||
  isRemoveComponent(schema) ||
  isMoveDownComponent(schema) ||
  isMoveUpComponent(schema);
const ArrayCardsInner = observer(
  defineComponent({
    name: 'FArrayCards',
    props: ['lazy'],
    setup(props, { attrs }) {
      const fieldRef = useField();
      const field = fieldRef.value;
      const schemaRef = useFieldSchema();
      const prefixCls = `${stylePrefix}-array-cards`;
      const { getKey, keyMap } = ArrayBase.useKey(schemaRef.value);
      const lazy = props.lazy || attrs.lazy || fieldRef.value.length;
      const dataSource = ref([]);
      const value = computed(() => {
        return Array.isArray(field.value) ? Array.from(field.value) : [];
      });

      watch(() => attrs.value, (newValue, oldValue) => {
        console.log(222)
        if (lazy && newValue?.length && !oldValue?.length) addCard()
      }, {
        deep: true, immediate: true
      })
      const toast = ref(null);
      const finished = ref(false);
      const loading = ref(false);
      const addCard = () => {

        loading.value = true;
        // toast.value = Toast.loading({
        //   duration: 0,
        //   overlay: true,
        //   forbidClick: true,
        //   message: '数据加载中',
        // });
        // setTimeout(() => {
          dataSource.value.push(
            ...(Array.isArray(field.value) ? Array.from(field.value) : []).slice(
              dataSource.value.length,
              dataSource.value.length + lazy
            )
          );
        //   loading.value = false;
        //   toast.value?.clear();
        //   toast.value = null;
        // }, 1);
      };
      const handleScroll = () => {
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop; // 滚动条距离顶部的距离
        let windowHeight = document.documentElement.clientHeight || document.body.clientHeight; // 可视区的高度
        let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight; //dom元素的高度，包含溢出不可见的内容
        if (scrollHeight - 300 <= scrollTop + windowHeight) {
          console.log(dataSource.value.length, value.value.length);
          if (finished.value || dataSource.value.length >= value.value.length) {
            finished.value = true;
            toast.value?.clear();
            toast.value = null;
            return;
          }
          addCard();
        }
      };
      if (lazy) {
        document.addEventListener('scroll', handleScroll);
        onUnmounted(() => {
          document.removeEventListener('scroll', handleScroll);
        })
      }

      return () => {
        // const field = fieldRef.value;
        const schema = schemaRef.value;

        const value = Array.isArray(field.value) ? Array.from(field.value) : [];
        // const startNum = ref(parseInt(lazy) || value.length)
        // const dataSource = ref(value.slice(0, startNum.value));

        if (!schema) throw new Error('can not found schema object');
        const renderItem = (data) =>
          data?.map((item, index) => {
            const items = Array.isArray(schema.items)
              ? schema.items[index] || schema.items[0]
              : schema.items;

            const title = h(
              'span',
              {},
              {
                default: () => [
                  h(
                    RecursionField,
                    {
                      props: {
                        schema: items,
                        name: index,
                        filterProperties: (schema) => {
                          if (!isIndexComponent(schema)) return false;
                          return true;
                        },
                        onlyRenderProperties: true,
                      },
                    },
                    {}
                  ),
                  (attrs.titleDesc && item[attrs.titleDesc]) || attrs.title || field.title,
                ],
              }
            );
            const extra = h(
              'span',
              {},
              {
                default: () => [
                  h(
                    RecursionField,
                    {
                      props: {
                        schema: items,
                        name: index,
                        filterProperties: (schema) => {
                          if (!isOperationComponent(schema)) return false;
                          return true;
                        },
                        onlyRenderProperties: true,
                      },
                    },
                    {}
                  ),
                  attrs.extra,
                ],
              }
            );
            const content = h(
              RecursionField,
              {
                props: {
                  schema: items,
                  name: index,
                  filterProperties: (schema) => {
                    if (isIndexComponent(schema)) return false;
                    if (isOperationComponent(schema)) return false;
                    return true;
                  },
                },
              },
              {}
            );

            return h(
              ArrayBase.Item,
              {
                key: getKey(item, index),
                value: loading,
                finished,
                finishedText: '没有更多了',
                props: {
                  index,
                  record: item,
                },
              },
              {
                default: () =>
                  h(
                    Card,
                    {
                      class: [`${prefixCls}-item`],
                      attrs: {
                        shadow: 'never',
                        ...attrs,
                      },
                    },
                    {
                      default: () => [content],
                      header: () =>
                        h(
                          Row,
                          {
                            props: {
                              type: 'flex',
                              justify: 'space-between',
                            },
                          },
                          {
                            default: () => [title, extra],
                          }
                        ),
                    }
                  ),
              }
            );
          });

        // const cardItems = computed(() => );
        const renderAddition = () =>
          schema.reduceProperties((addition, schema) => {
            if (isAdditionComponent(schema)) {
              return h(
                RecursionField,
                {
                  props: {
                    schema,
                    name: 'addition',
                  },
                },
                {}
              );
            }
            return addition;
          }, null);
        const renderEmpty = () => {
          if (!renderAddition() && lazy ? (dataSource.value?.length || (!dataSource.value?.length && value?.length)): value?.length) return;
          return h(
            Card,
            {
              class: [`${prefixCls}-item`],
              attrs: {
                shadow: 'never',
                ...attrs,
                header: attrs.title || field.title,
              },
            },
            {
              default: () => h(Empty, { props: { description: '暂无数据', imageSize: 100 } }, {}),
            }
          );
        };

        return h(
          !renderAddition() && lazy ? ArrayBase : 'div',
          {
            class: [prefixCls],
          },
          {
            default: () =>
              h(
                !renderAddition() && lazy ? List : ArrayBase,
                {
                  attrs: {
                    offset: 30,
                    loading: loading.value,
                    finished: finished.value,
                    finishedText: '没有更多了',
                  },
                  props: {
                    keyMap,
                  },
                  on: {
                    // load: (e) => {
                    //   loading.value = true;
                    //   if (dataSource.value.length >= value.length) {
                    //     finished.value = true;
                    //   toast.value?.clear()
                    //   toast.value = null
                    //     return;
                    //   }
                    //   loading.value = true;
                    //   // if (!toast.value) {
                    //   //   toast.value  = Toast.loading({
                    //   //     duration: 0,
                    //   //     overlay: true,
                    //   //     forbidClick: true,
                    //   //     message: '数据加载中',
                    //   //   })
                    //   // }
                    //   setTimeout(() => {
                    //     dataSource.value.push(
                    //       ...(Array.isArray(field.value) ? Array.from(field.value) : []).slice(
                    //         dataSource.value.length,
                    //         dataSource.value.length + lazy
                    //       )
                    //     );
                    //     loading.value = false;
                    //   }, 0);
                    // },
                  },
                },
                {
                  default: () => [
                    renderEmpty(),
                    renderItem(!renderAddition() && lazy ? dataSource.value : value),
                    renderAddition(),
                  ],
                }
              ),
          }
        );
      };
    },
  })
);

const ArrayBaseSymbol = Symbol('ArrayBaseContext');
const ItemSymbol = Symbol('ItemContext');

const useArray = () => {
  return inject(ArrayBaseSymbol, null);
};

const getDefaultValue = (defaultValue, schema) => {
  if (isValid(defaultValue)) return clone(defaultValue);
  if (Array.isArray(schema?.items)) return getDefaultValue(defaultValue, schema.items[0]);
  return {};
};

const ArrayBaseAddition = defineComponent({
  name: 'ArrayBaseAddition',
  props: ['title', 'method', 'defaultValue'],
  setup(props, { listeners }) {
    const self = useField();
    const array = ArrayBase.useArray();
    const prefixCls = `${stylePrefix}-array-base`;
    return () => {
      if (array?.field.value.pattern !== 'editable') return null;
      return h(
        Button,
        {
          class: `${prefixCls}-addition`,
          attrs: {
            type: 'ghost',
            icon: 'qax-icon-Alone-Plus',
            ...props,
          },
          on: {
            ...listeners,
            click: (e) => {
              console.log(array);
              if (array.props?.disabled) return;
              const defaultValue = getDefaultValue(props.defaultValue, array?.schema.value);
              if (props.method === 'unshift') {
                array?.field?.value.unshift(defaultValue);
                array.listeners?.add?.(0);
              } else {
                array?.field?.value.push(defaultValue);
                array.listeners?.add?.(array?.field?.value?.value?.length - 1);
              }
              if (listeners.click) {
                listeners.click(e);
              }
            },
          },
        },
        {
          default: () => [self.value.title || props.title],
        }
      );
    };
  },
});

export const ArrayCards = composeExport(ArrayCardsInner, {
  Index: ArrayBase.Index,
  SortHandle: ArrayBase.SortHandle,
  Addition: ArrayBaseAddition,
  Remove: ArrayBase.Remove,
  MoveDown: ArrayBase.MoveDown,
  MoveUp: ArrayBase.MoveUp,
  useArray: ArrayBase.useArray,
  useIndex: ArrayBase.useIndex,
  useRecord: ArrayBase.useRecord,
});

export default ArrayCards;