<template>
  <el-upload
    style="display: inline"
    action=""
    accept=".xlsx,.xls"
    :auto-upload="false"
    :show-file-list="false"
    :on-change="(e) => importData(e, true)"
  >
    <van-button :disabled="disabled" :type="type" :size="size" class="mr10" @click="importData">{{ content }}</van-button>
  </el-upload>
</template>

<script>
import XLSX from 'xlsx';

export default {
  name: 'ImportButton',
  props: {
    content: {
      type: String,
      required: true,
      default: '导入',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'normal',
    },
    tableData: {
      type: Array,
      required: true,
      default: () => [],
    },
    headerData: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    type: {
      type: String,
      default: 'primary',
    },
    controlledMessage: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async importData(file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const data = new Uint8Array(e.target.result);
          const wb = XLSX.read(data, { type: 'array' });
          const TABLE_KEYS = Object.entries(this.headerData);
          const sheet = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
          const res = sheet.map((item) => {
            const tmp = {};
            TABLE_KEYS.forEach(([key, val]) => {
              tmp[key] = typeof item[val] === 'string' ? item[val].replace(/[\t\r\n]/, '').trim() : item[val];
            });
            return tmp;
          });
          this.$emit('change', res);
          !this.controlledMessage && this.$message.success('导入成功');
        } catch (err) {
          this.$message({
            message: `批量导入失败，请检查数据格式： ${err.message}`,
            type: 'error',
          });
        }
      };
      reader.readAsArrayBuffer(file.raw);
    },
  },
};
</script>
