import { observer } from '@formily/reactive-vue';
import { connect, mapProps, mapReadPretty, h, useField } from '@formily/vue';
import { ref, defineComponent } from '@vue/composition-api';
import { DatetimePicker as VanDatetimePicker, Popup as VanPopup } from 'vant';

import { formilyFormItem } from './index';
import { PreviewText } from '@formily/vant';
import dayjs from 'dayjs';

const BaseDatetimePicker = observer(defineComponent({
  name: 'FBaseDatetimePicker',
  setup(props, { attrs, emit, slots }) {
    const {
      formItemProps = {},
      popupProps = {},
      datetimePickerProps = {},
      fieldListeners = {},
      popupListeners = {},
      datetimePickerListeners = {},
    } = attrs;
    const show = ref(false);
    const field = useField();
    return () => h(
      'div',
      {},
      {
        default: () => [
          h(
            formilyFormItem,
            {
              attrs: {
                value: attrs.value,
                isLink: field.value.pattern === 'editable',
                ...formItemProps,
                readonly: true,
              },
              on: {
                click: () => {
                  if (field.value.pattern !== 'editable') return;
                  show.value = true;
                },
                ...fieldListeners,
              },
            },
            slots,
          ),
          h(
            VanPopup,
            {
              attrs: {
                value: show.value,
                round: true,
                position: 'bottom',
                ...popupProps,
              },
              on: {
                input: (val) => {
                  show.value = val;
                },
                ...popupListeners,
              },
            },
            {
              default: () => [
                h(
                  VanDatetimePicker,
                  {
                    attrs: {
                      showToolbar: true,
                      ...datetimePickerProps,
                      value: attrs.value ? dayjs(attrs.value).toDate() : new Date(),
                      type: field.value.componentType === 'DatePicker' ? 'datetime' : 'time',
                      formatter,
                    },
                    on: {
                      cancel: () => {
                        show.value = false;
                      },
                      confirm: (val) => {
                        const value = field.value.componentType === 'TimePicker' ? formatTime(val, attrs.format) : formatDate(val, attrs.format);
                        emit('change', value);
                        show.value = false;
                      },
                      ...datetimePickerListeners,
                    },
                  },
                  {},
                ),
              ],
            },
          ),
        ],
      },
    );
  },
}));

const formatter = (type, val) => {
  if (type === 'year') {
    return `${val}年`;
  }
  if (type === 'month') {
    return `${val}月`;
  }
  if (type === 'day') {
    return `${val}日`;
  }
  if (type === 'minute') {
    return `${val}分`;
  }
  if (type === 'hour') {
    return `${val}时`;
  }
  return val;
};

const formatTime = (val, format) => {
  const [hour, min] = val.split(':');
  const time = new Date(0, 0, 0, hour, min, 0);
  const value = dayjs(time).format(format || 'HH:mm:ss');
  return value;
};

const formatDate = (val, format) => dayjs(val).format(format?.replace?.('yyyy', 'YYYY')?.replace?.('dd', 'DD') || 'YYYY-MM-DD HH:mm:ss');

export const DatetimePicker = connect(
  BaseDatetimePicker,
  mapProps({ readOnly: 'readonly' }),
  mapReadPretty(PreviewText.DatetimePicker),
);

export default DatetimePicker;
