<template>
  <div class="wrapper">
    <div v-for="(item, index) in value" :key="item.url + item.name">
      <el-image
        :ref="'img' + index"
        :alt="item.name || item.url"
        class="image"
        :src="JSON.parse(JSON.stringify(item.url))"
        :style="{ ...item }"
        :preview-src-list="[item.url]"
        @load="setWidth(index)"
      />
      <div
        v-if="item.name"
        :ref="'text' + index"
        :class="['ellipse', 'text']"
        :style="{
          fontSize,
          width: item.width,
        }"
      >
        <span :class="['ellipse']" :style="{ fontSize, width: item.width }">
          {{
            parseInt(item.maxLen) && parseInt(item.maxLen) < item.name.length
              ? item.name.slice(0, parseInt(item.maxLen)) + '...'
              : item.name
          }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      default: () => [],
      type: Array,
    },
    fontSize: {
      default: '12px',
      type: String,
    },
  },
  methods: {
    setWidth(i) {
      this.$refs[`text${i}`][0].style.width = this.$refs[`img${i}`][0].$el.offsetWidth
        ? `${this.$refs[`img${i}`][0].$el.offsetWidth}px`
        : null;
    },
  },
};
</script>
<style scoped lang="less">
.image {
  max-width: 100%;
  /deep/ .el-image__inner {
    width: auto;
  }
}
.wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center
}
.ellipse {
  overflow: hidden;
  word-wrap: break-word;
  white-space: normal;
}
</style>
