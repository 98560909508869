<template>
  <Steps :active="value" :direction="direction">
    <template v-if="direction == 'horizontal'">
      <Step v-for="item in options" :key="item.title">{{ item.title }}</Step>
    </template>
    <template v-if="direction == 'vertical'">
      <Step v-for="item in options" :key="item.title">
        <div>{{ item.title }}</div>
        <div>{{ item.description }}</div>
      </Step>
    </template>
  </Steps>
</template>

<script>
import { Steps, Step } from 'vant';
export default {
  components: { Steps, Step },
  props: {
    value: {
      type: Number,
    },
    direction: {
      type: String,
      default: 'horizontal',
    },
    options: {
      type: Object,
      default: [],
    },
  },
};
</script>
<style lang='less' scoped></style>