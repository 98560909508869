<template>
  <div class="monaco-container">
    <div ref="monacoContainer" class="monaco-editor" :style="{ height: height + 'px' }"></div>
  </div>
</template>
<script>
import * as monaco from 'monaco-editor';
// import 'monaco-editor/esm/vs/editor/contrib/find/findController.js';

export default {
  name: 'MonacoJsonEditor',
  props: {
    // 编辑器中呈现的内容
    content: {
      default: '',
    },
    initValue: {
      type: Boolean,
      default: true,
    },
    // 高度
    height: {
      type: Number,
      default: 350,
    },
    // 配置
    editorOption: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      monacoEditor: null,
      // 默认配置
      defaultOption: {
        selectOnLineNumbers: true,
        roundedSelection: false,
        readOnly: false, // 只读
        cursorStyle: 'line', // 光标样式
        automaticLayout: true, // 自动布局
        glyphMargin: true, // 字形边缘
        useTabStops: false,
        fontSize: 16, // 字体大小
        autoIndent: false,
        minimap: {
          enabled: true,
        },
      },
      timer: null,
    };
  },
  watch: {
    content(newVal) {
      try {
        const jsonVal = JSON.stringify(newVal, null, 2);
        this.monacoEditor.setValue(jsonVal);
      } catch (err) {}
    },
  },
  mounted() {
    this.initEditor();
  },
  destroyed() {
    clearTimeout(this.timer);
  },
  methods: {
    initEditor() {
      const options = { ...this.defaultOption, ...this.editorOption };
      this.monacoEditor = monaco.editor.create(this.$refs.monacoContainer, {
        value: '', // 见props
        language: 'json',
        theme: 'vs-dark', // 编辑器主题：vs, hc-black, or vs-dark，更多选择详见官网
        ...options,
      });
      if (this.initValue) {
        this.monacoEditor.setValue(this.content);
      }
      // this.monacoEditor.getAction(['editor.action.formatDocument'])._run();
      // this.monacoEditor.trigger('anyString', 'editor.action.formatDocument');;
      this.timer = setTimeout(() => {
        this.tidy();
      }, 100);

      this.monacoEditor.onDidChangeModelContent(() => {
        this.$emit('json-value-change', this.monacoEditor.getValue());
      });
    },
    tidy() {
      this.monacoEditor.trigger('anyString', 'editor.action.formatDocument');
    },
  },
};
</script>
