<!-- 设施资产入库 -->
<template>
  <div class="content">
    <!-- <el-tag type="warning">Tips:【管理方式为计量的数据,确认无误之后,请设置验收结果】</el-tag>
    <el-button type="success" @click="setCheckResult">设置验收结果</el-button> -->
    <div class="table">
      <el-table
        v-loading="is_loading"
        key="tableKey"
        ref="tableData"
        :data="pageData"
        tooltip-effect="dark"
        style="width: 100%"
        border
        :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
        @selection-change="onSelect"
      >
        <el-table-column
          type="selection"
          min-width="40"
          :show-overflow-tooltip="true"
          fixed="left"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="seq_id"
          label="序号"
          min-width="60"
          :show-overflow-tooltip="true"
          fixed="left"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="assets_type"
          min-width="120"
          label="资产类型"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <!-- <el-table-column
          v-if="isTool"
          prop="sub_category"
          min-width="120"
          label="工具类别"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column> -->
        <el-table-column
          prop="asset_manage_mode"
          min-width="80"
          label="管理方式"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="pn"
          min-width="120"
          label="型号/PN"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          v-if="isTool"
          prop="logic_name"
          min-width="80"
          label="逻辑库"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="enabled"
          min-width="80"
          label="是否可用"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="asset_id"
          min-width="120"
          label="固资"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>

        <el-table-column
          prop="sn"
          min-width="120"
          label="SN"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="asset_count"
          min-width="60"
          label="数量"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          v-if="!isApproval"
          prop="scan_sn"
          min-width="120"
          label="扫描SN"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          v-if="!isApproval"
          prop="store_name"
          min-width="120"
          label="资产库"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          v-if="!isApproval"
          prop="rack_name"
          min-width="100"
          label="资产架"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          v-if="!isApproval"
          prop="pos_name"
          min-width="90"
          label="资产位"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <!-- <el-table-column
          prop="check_result"
          min-width="100"
          label="验收结果"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column> -->
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
        :current-page="currentPage"
        :page-sizes="pageSize"
        @current-change="changePage"
      >
      </el-pagination>
      <!-- <el-dialog
        title="设置本次验收结果"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose"
      >
        <span>
          <el-form ref="form" :model="formParms" label-width="80px">
            <el-form-item label="验收结果">
              <el-select
                v-model="formParms.checkResult"
                @change="changeStatus"
                placeholder="请选择验收结果"
              >
                <el-option
                  v-for="item in checkOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option> </el-select
            ></el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit">确 定</el-button>
              <el-button @click="dialogVisible = false">取消</el-button>
            </el-form-item>
          </el-form>
        </span>

      </el-dialog> -->
    </div>
  </div>
</template>
  <script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    context: {
      type: Object,
      default: () => ({}),
    },
    toggle: {
      type: Boolean,
      default: false,
    },
    is_loading: {
      type: Boolean,
      default: false,
    },
    isTool: {
      type: Boolean,
      default: false,
    },
    isApproval: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tableData: [],
      quantityStorage: 0,
      currentPage: 1,
      pageSize: [50],
      pageData: [],
      formParms: {
        checkResult: '',
      },
      checkOptions: [
        {
          value: '验收成功',
          label: '验收成功',
        },
      ],
      dialogVisible: false,
      selectData: [],
      selectVal: '',
    };
  },

  watch: {
    value: {
      handler(val) {
        this.$nextTick(() => {
          this.tableData = [...val];
          this.changePage(this.currentPage);
        });
      },
      deep: true,
    },
    toggle: {
      handler() {
        this.$nextTick(() => {
          this.$refs.tableData.doLayout();
          this.tableData = [...this.tableData];
          this.changePage(this.currentPage);
        });
      },
      deep: true,
      immediate: false,
    },
  },
  mounted() {
    this.$emit('selection-change', this.tableData);
  },
  methods: {
    onSelect(val) {
      this.selectData = val;
      this.$emit('selection-change', val);
    },

    changePage(page) {
      this.currentPage = page;
      const startIndex = (this.currentPage - 1) * this.pageSize[0];
      const endIndex = startIndex + this.pageSize[0];
      this.pageData = this.tableData.slice(startIndex, endIndex);
    },
    // 设置验收结果
    // setCheckResult() {
    //   if (this.selectData.length === 0) {
    //     this.$message.error('请选择要验收的数据!');
    //     return;
    //   }
    //   this.formParms.checkResult = '';
    //   this.dialogVisible = true;
    // },
    // changeStatus(val) {
    //   this.selectVal = val;
    // },
    // onSubmit() {
    //   for (const item of this.selectData) {
    //     this.$set(item, 'check_result', this.selectVal);
    //   }
    //   this.$message.success(`设置成功`);
    //   this.dialogVisible = false;
    // },
    // handleClose(done) {
    //   this.$confirm('确认关闭？')
    //     .then((_) => {
    //       done();
    //     })
    //     .catch((_) => {});
    // },
  },
};
</script>
  
  <style scoped lang="less">
.content {
  .table {
    width: 100%;
    overflow-x: auto;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
  