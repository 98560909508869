import { Schema, SchemaKey } from '@formily/json-schema';
import { model } from '@formily/reactive';
import { observer } from '@formily/reactive-vue';
import { Fragment, h, RecursionField, useField, useFieldSchema } from '@formily/vue';
import { Badge, Tab as TabPane, Tabs } from 'vant';
import { computed, defineComponent, reactive } from 'vue-demi';
// import type { Tab as TabPaneProps, Tabs as TabsProps } from 'vant';
import { cloneDeep } from 'lodash';

function composeExport(s0, s1) {
  return Object.assign(s0, s1);
}
const tabValidator = {};

const useTabs = () => {
  const tabsField = useField().value;
  const schema = useFieldSchema().value;
  const tabs = reactive([]);
  schema.mapProperties((schema, name) => {
    const field = tabsField.query(tabsField.address.concat(name)).take();
    if (field?.display === 'none' || field?.display === 'hidden') return;
    if (schema['x-component']?.indexOf('TabPane') > -1) {
      tabs.push({
        name,
        props: {
          name: schema?.['x-component-props']?.name || name,
          ...schema?.['x-component-props'],
        },
        schema,
      });
    }
  });
  return tabs;
};

const createFormTab = (defaultActiveKey) => {
  const formTab = model({
    activeKey: defaultActiveKey,
    setActiveKey(key) {
      formTab.activeKey = key;
    },
  });
  return formTab;
};

const FormTabInner = observer(
  defineComponent({
    name: 'FFormTab',
    props: ['formTab'],
    setup(props, { attrs, listeners, parent }) {
      const field = useField().value;
      const formTabRef = computed(() => props.formTab ?? createFormTab());
      const prefixCls = 'formily-element-form-tab';
      return () => {
        const formTab = formTabRef.value;
        const tabs = useTabs();
        const activeKey = props.value || formTab?.activeKey || tabs?.[0]?.name;
        field.form.setValuesIn(parent?.fieldProps?.name, activeKey);
        const badgedTab = (key, props) => {
          const errors = field.form.queryFeedbacks({
            type: 'error',
            address: `${field.address.concat(key)}.*`,
          });
          if (errors.length) {
            return () =>
              h(
                Badge,
                {
                  class: [`${prefixCls}-errors-badge`],
                  props: {
                    value: errors.length,
                  },
                },
                { default: () => props.label }
              );
          }
          return () => props.label;
        };

        const getTabs = (tabs) =>
          tabs.map(({ props, schema, name }, key) =>
            h(
              TabPane,
              {
                key,
                props,
              },
              {
                default: () => [
                  h(
                    RecursionField,
                    {
                      props: {
                        schema:
                          field.componentProps.validateAll || activeKey === name
                            ? schema
                            : cloneDeep({ ...schema, properties: {} }),
                        name,
                      },
                    },
                    {}
                  ),
                ],
                title: () => [h('div', {}, { default: badgedTab(name, props) })],
              }
            )
          );

        return h(
          Tabs,
          {
            class: [prefixCls],
            style: attrs.style,
            props: {
              ...attrs,
              value: activeKey,
              animated: true,
              lazyRender: false,
            },
            on: {
              ...listeners,
              input: (key) => {
                listeners.input?.(key);
                formTab.setActiveKey?.(key);
                if (activeKey === key || field.componentProps.validateAll) return;
                field.form.clearErrors();
                Object.entries(field.form.fields).forEach(([name, item]) => {
                  const nameList = name?.split('.');
                  if (
                    nameList?.includes(parent?.fieldProps?.name) &&
                    nameList?.includes(activeKey)
                  ) {
                    tabValidator[name] = cloneDeep(item.validator);
                    item.validator = null;
                  }
                });
                Object.keys(tabValidator).forEach((name) => {
                  const nameList = name?.split('.');
                  if (nameList?.includes(parent?.fieldProps?.name) && nameList?.includes(key)) {
                    field.form.fields[name].validator = cloneDeep(tabValidator[name]);
                  }
                });
              },
            },
          },
          {
            default: () => getTabs(tabs),
          }
        );
      };
    },
  })
);

const FormTabPane = defineComponent({
  name: 'FFormTabPane',
  setup(_props, { slots }) {
    return () => h(Fragment, {}, slots);
  },
});

export const FormTab = composeExport(FormTabInner, {
  TabPane: FormTabPane,
  createFormTab,
});

export default FormTab;
