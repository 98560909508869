<!-- 按箱入库表格 -->
<template>
  <div class="content">
    <!-- <el-tag type="warning">没勾选数据点击删除功能,默认为全部删除</el-tag> -->
    <div class="table">
      <el-table 
       v-loading="is_loading" 
       key="tableKey" 
       ref="tableData" 
       :data="pageData" 
       tooltip-effect="dark"
        style="width: 100%" border :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
        @selection-change="onSelect">
        <el-table-column type="selection" min-width="40" :show-overflow-tooltip="true" fixed="left"> </el-table-column>
        <el-table-column prop="seq_id" label="序号" min-width="60" :show-overflow-tooltip="true" fixed="left"></el-table-column>
        <el-table-column prop="box_num" min-width="160" label="箱编号" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="factory" min-width="100" label="生产厂商" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="product_model" min-width="270" label="箱规格" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="idc_unit" min-width="220" label="机房管理单元" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="logic_warehouse" min-width="100" label="逻辑库" :show-overflow-tooltip="true">
        </el-table-column>
        <!-- <el-table-column prop="pn" min-width="150" label="出库逻辑" :show-overflow-tooltip="true">
        </el-table-column> -->
       <el-table-column prop="scan_box_num" min-width="160" label="扫描箱编号" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="store_name" min-width="250" label="资产库" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="shelf_name" min-width="130" label="资产架" :show-overflow-tooltip="true"> </el-table-column>
        <el-table-column prop="pos_name" min-width="110" label="资产位" :show-overflow-tooltip="true">
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination 
        background layout="total, sizes, prev, pager, next, jumper" 
        :total="tableData.length"
        :current-page="currentPage" 
        :page-sizes="pageSize" 
        @current-change="changePage">
      </el-pagination>
    </div>
  </div>
</template>
<script>


export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    context: {
      type: Object,
      default: () => ({}),
    },
    toggle: {
      type: Boolean,
      default: false,
    },
    is_loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableData: [],
      quantityStorage: 0,
      currentPage: 1,
      pageSize: [50],
      pageData: [],
    };
  },

  watch: {
    value: {
      handler(val) {
        this.$nextTick(() => {
          this.tableData = [...val];
          this.changePage(this.currentPage)
        });
      },
      deep: true,
    },
    toggle: {
      handler() {
        this.$nextTick(() => {
          this.$refs.tableData.doLayout();
          this.tableData = [...this.tableData];
          console.log(this.tableData,'this.tableData') 
          this.changePage(this.currentPage)
        })
      },
      deep: true,
      immediate: false,
    },
  },
  mounted() {
    this.$emit('selection-change', this.tableData);
  },
  methods: {
    onSelect(val) {
      this.$emit('selection-change', val);
    },

    changePage(page) {
      this.currentPage = page
      const startIndex = (this.currentPage - 1) * this.pageSize[0];
      const endIndex = startIndex + this.pageSize[0];
      this.pageData = this.tableData.slice(startIndex, endIndex);
    },

  },
};
</script>

<style scoped lang="less">
.content {
  .table {
    width: 100%;
    overflow-x: auto;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
  }

}
</style>
