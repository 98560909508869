<template>
  <div class="w">
    <div v-loading.fullscreen.lock="loading" class="flex-h-between flex-vc">
      <el-button :type="type" :size="size" :disabled="disabled" @click="getTime">
        <span>{{ content }}</span>
      </el-button>
    </div>
    <div>{{ value }}</div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
export default {
  props: {
    content: {
      type: String,
      default: '记录时间',
    },
    type: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: '',
    },
    clearble: {
      default: true,
      type: Boolean,
    },
    format: {
      default: 'YYYY-MM-DD HH:mm:ss',
      type: String,
    },
    value: {
      default: '',
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      signatrue: '',
      address: '',
      loading: false,
      timestamp: '',
      latitude: '',
      longitude: '',
      time: '',
      id: '',
    };
  },
  created() {
  },
  methods: {

    async getTime() {
      this.$emit('change', dayjs(new Date()).format(this.format || 'YYYY-MM-DD HH:mm:ss'));
    },
  },
};
</script>

<style lang="less" scoped>
.btn {
  height: 50px;
  flex: 1 0 auto;
}
.lh15 {
  line-height: 15px;
}

.desc {
  display: flex;
  flex-direction: column;
  height: 50px;
  justify-content: space-between;
}
</style>
