<template>
  <!-- 基础信息 -->
  <!-- SLA时间进度条 -->
  <div>
    <div class="SLA-box" v-if="OLAstatus">
      <van-progress
        :percentage="percentage"
        stroke-width="10"
        :track-color="trackcolor"
        :pivot-text="pivottext"
        :pivot-color="pivotcolor"
        :color="color"
      />
      <div class="timeInfo">
        <span>剩余时间：</span
        ><van-count-down
          :time="countDown"
          format="HH 时 mm 分 ss 秒"
          
          ref="countdowns"
        />
      </div>
    </div>

    <p class="section-title ml10">工单信息</p>
    <van-collapse v-model="activeName" accordion>
      <van-collapse-item
        class="ticket-info"
        :title="activeName === '1' ? '点击收起工单信息' : '点击展开工单信息'"
        name="1"
      >
        <van-cell title="工单号" :value="ticketData.TicketId" />
        <van-cell
          title="工单标题"
          title-style="min-width: 20%; min-width: 20%"
          title-class="w10"
          :value="ticketData.Title"
        />
        <van-cell title="工单等级" :value="SLA_LEVEL[ticketData.SLALevel]" />
        <van-cell title="描述" :value="ticketData.Description" />
        <!-- <van-cell title="标题:" :value="ticketData.Title" /> -->
        <van-cell title="单据状态" :value="TICKET_STATUS[ticketData.TicketStatus]" />
        <van-cell v-if="otherTicket && otherTicket.length" title="关联工单">
          <template>
            <div class="flex">
              <el-link
                v-for="link in otherTicket"
                :key="link.taskId"
                class="mr10"
                type="primary"
                target="_blank"
                :href="`/todo-detail?ticketId=${link.TicketId}&taskId=${link.TaskId}`"
              >
                <span class="color-brand">{{ link.Title || link.TicketId }}</span>
              </el-link>
            </div>
          </template>
        </van-cell>
      </van-collapse-item>
    </van-collapse>
    <van-collapse v-model="activeNameArgs" accordion v-if="showsData && Object.keys(showsData).length">
      <van-collapse-item
        :title="activeNameArgs === '1' ? '点击收起流程中间数据' : '点击展开流程中间数据'"
        name="1"
        class="ticket-info"
      >
        <div v-if="showsData.Normal">
          <!-- kv类型 兼容流程中间数据 -->
          <van-cell
            v-for="viewItem in showsData.Normal"
            :key="viewItem.Key"
            :title="viewItem.name ? viewItem.name + ':' : viewItem.KeyName + ':'"
            label-width="120px"
            :value="viewItem.data || viewItem.Value"
          />
        </div>
        <div v-if="showsData.MultiLineKv">
          <van-cell
            v-for="viewItem in showsData.MultiLineKv"
            :key="viewItem.Key"
            :title="viewItem.name ? viewItem.name + ':' : viewItem.KeyName + ':'"
            label-width="120px"
            :value="viewItem.data || viewItem.Value"
          >
            <span class="pre-line">{{ item.Value }}</span>
          </van-cell>
        </div>

        <!-- link类型 兼容流程中间数据 -->
        <div v-if="showsData.Link">
          <van-cell
            is-link
            v-for="viewItem in showsData.Link"
            :key="viewItem.Key"
            :title="viewItem.KeyName ? viewItem.KeyName + ':' : viewItem.name + ':'"
          >
            <a
              v-if="viewItem.Value"
              :href="viewItem.Value"
              class="f12"
              type="primary"
              target="_blank"
            >
              {{ viewItem.Value }}
            </a>
            <a v-else :href="viewItem.data" class="f12" type="primary" target="_blank">
              {{ viewItem.data }}
            </a>
          </van-cell>
        </div>
        <!-- json类型 -->
        <div v-if="showsData.Json" label-width="120px">
          <van-field
            readonly
            label-width="0"
            v-for="viewItem in showsData.Json"
            :key="viewItem.Key"
          >
            <template #input>
              <div class="w">
                <div class="mb10">{{ viewItem.KeyName ? viewItem.KeyName : viewItem.name }}</div>
                <MonacoJsonEditor
                  style="width: 100%; height: 100px"
                  :content="getJSON(viewItem.Value ? viewItem.Value : viewItem.data)"
                  :editorOption="{
                    theme: 'vs',
                    readOnly: true,
                    fontSize: 13,
                  }"
                />
              </div>
            </template>
          </van-field>
        </div>

        <!-- 无序table类型 -->
        <div v-if="showsData.Table">
          <van-field label-width="0" style="padding: 0">
            <template #input>
              <van-collapse class="w" v-model="tableOpenStatus">
                <van-collapse-item
                  v-for="viewItem in showsData.Table"
                  :key="viewItem.Key"
                  :name="viewItem.Key"
                >
                  <template #title>
                    <span class="mb10 mr20">{{
                      viewItem.KeyName ? viewItem.KeyName : viewItem.name
                    }}</span>
                  </template>
                  <div class="w">
                    <el-table
                      v-if="Array.isArray(viewItem.data) && !viewItem.Value"
                      :data="viewItem.data"
                      :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
                      border
                      class="w"
                      width="100%"
                    >
                      <el-table-column
                        v-for="(val, key) in viewItem.data[0]"
                        :key="key"
                        :prop="key"
                        :label="key"
                        align="center"
                      >
                        <template v-slot="{ row }">
                          <div :class="checkLineWrap(row[key])">
                            {{ row[key] }}
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                    <el-table
                      v-else
                      :data="JSON.parse(viewItem.Value)"
                      :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
                      border
                    >
                      <el-table-column
                        v-for="(val, key) in JSON.parse(viewItem.Value)[0]"
                        :key="key"
                        :prop="key"
                        :label="key"
                        align="center"
                      >
                        <template v-slot="{ row }">
                          <div :class="checkLineWrap(row[key])">
                            {{ row[key] }}
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </van-collapse-item>
              </van-collapse>
            </template>
          </van-field>
        </div>
        <!-- 有序OrderTable类型 -->
        <div v-if="showsData.OrderTable">
          <van-field label-width="0" style="padding: 0">
            <template #input>
              <van-collapse class="w" v-model="OrderTableOpenStatus">
                <van-collapse-item
                  v-for="viewItem in showsData.OrderTable"
                  :key="viewItem.Key"
                  :name="viewItem.Key"
                >
                  <template #title>
                    <span class="mb10 mr20">{{
                      viewItem.KeyName ? viewItem.KeyName : viewItem.name
                    }}</span>
                  </template>
                  <div class="w">
                    <el-table
                      v-if="Array.isArray(viewItem.data) && !viewItem.Value"
                      :data="viewItem.data"
                      :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
                      border
                      class="w"
                      width="100%"
                    >
                      <el-table-column
                        v-for="(key, index) in viewItem.header"
                        :key="index"
                        :prop="key"
                        :label="key"
                        align="center"
                      >
                        <template v-slot="{ row }">
                          <div :class="checkLineWrap(row[key])">
                            {{ row[key] }}
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                    <el-table
                      v-else
                      :data="JSON.parse(viewItem.Value).detail"
                      :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
                      border
                    >
                      <el-table-column
                        v-for="(key, index) in JSON.parse(viewItem.Value).header"
                        :key="index"
                        :prop="key"
                        :label="key"
                        align="center"
                      >
                        <template v-slot="{ row }">
                          <div :class="checkLineWrap(row[key])">
                            {{ row[key] }}
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </van-collapse-item>
              </van-collapse>
            </template>
          </van-field>
        </div>
      </van-collapse-item>
    </van-collapse>
  </div>
</template>

<script>
import { getRelateTicket, getSLAdata } from '@/api/tickets/ticketsApi';
import { TICKET_STATUS, SLA_LEVEL } from './constant';
import { mapActions, mapGetters } from 'vuex';
import MonacoJsonEditor from '@/components/MonacoJsonEditor';

export default {
  name: 'TicketDetail',
  components: { MonacoJsonEditor },
  props: {
    ticketData: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      TICKET_STATUS,
      SLA_LEVEL,
      activeName: '',
      activeNameArgs: '',
      otherTicket: [],
      showsData: [],
      tableOpenStatus: [],
      OrderTableOpenStatus: [],
      countDown: '',
      percentage: 0,
      trackcolor: '',
      pivottext: '',
      pivotcolor: '#1989fa',
      color: '',
      states: false,
      OLAstatus: null,
    };
  },
    computed: {
      ...mapGetters(['getConfigItem']),
    },

  created(){
    try {
      const key =  this.$store.state.config.ProcessDefinitionKey
      const processArr = JSON.parse(this.getConfigItem('ProcessDefinitionKey'));
      if (processArr.process.includes(key)) {
            this.OLAstatus = false
          } else {
            this.OLAstatus = true
          }
    } catch (error) {
      this.OLAstatus = true
      console.log(error);
    }
  },
  mounted() {
    this.getRelateTicket();
    this.dealShowsData();
    this.getSLAdata();
  },
  methods: {
    ...mapActions(['setTimeStatus']),
    async getRelateTicket() {
      try {
        const { data } = await getRelateTicket(this.$route.query.ticketId);
        this.otherTicket = [
          ...(data.OtherTicketInfo.filter((item) => item.TicketId && item.TaskId) || []),
        ];
        if (
          data.ParentTicketInfo &&
          data.ParentTicketInfo.TicketId &&
          data.ParentTicketInfo.TaskId
        ) {
          this.otherTicket.push(data.ParentTicketInfo);
        }
      } catch (e) {
        console.log(e);
      }
    },
    dealShowsData() {
      if (!this.ticketData.CustomKV) return;
      const sortData = {
        Normal: [],
        MultiLineKv: [],
        Link: [],
      };
      this.ticketData.CustomKV.forEach((item) => {
        if (!item.Type || item.Type === 'KVField') {
          if (item.Value.includes('\n')) {
            sortData.MultiLineKv.push(item);
          } else {
            sortData.Normal.push(item);
          }
        } else if (item.Type === 'Link' || item.Type === 'Url') {
          sortData.Link.push(item);
        } else if (sortData[item.Type]) {
          sortData[item.Type].push(item);
        } else {
          sortData[item.Type] = [item];
        }
      });
      // 清除空白属性
      if (sortData.Normal.length === 0) {
        delete sortData.Normal;
      }
      if (sortData.Link.length === 0) {
        delete sortData.Link;
      }
      if (sortData.MultiLineKv.length === 0) {
        delete sortData.MultiLineKv;
      }
      this.showsData = sortData;
    },
    dealOpenStatus() {
      const { Table, OrderTable } = this.showsData;
      if (Table) {
        Table.forEach((item) => {
          if (item.IsHidden === '0' || item.openStatus) {
            this.tableOpenStatus.push(item.Key);
          }
        });
      }
      if (OrderTable) {
        OrderTable.forEach((item) => {
          if (item.IsHidden === '0' || item.openStatus) {
            this.OrderTableOpenStatus.push(item.Key);
          }
        });
      }
    },
    // 展开Table详情
    handleTableChange(val, todoItem) {
      todoItem.openStatus = !todoItem.openStatus;
      if (!val || todoItem.initStatus) {
        return;
      }
      if (!todoItem.data) {
        todoItem.data = [];
      }
      if (!todoItem.header) {
        const [headerData = {}] = todoItem.data;
        this.$set(todoItem, 'header', todoItem.data.length ? headerData : []);
      }
      todoItem.initStatus = true;
    },
    checkLineWrap(val) {
      if (typeof val === 'string') {
        return val.includes('\n') ? 'pre-line' : '';
      }
      return '';
    },
    getJSON(str) {
      try {
        const newStr = str
          .replace(/'/g, '"')
          .replace(/True/g, true)
          .replace(/False/g, false)
          .replace(/None/g, null);
        return JSON.stringify(JSON.parse(newStr), null, 2);
      } catch (err) {}
    },
    //获取待办工单流程时间
    async getSLAdata() {
      const TaskId = this.$route.query.taskId;
      try {
        const result = await getSLAdata(TaskId);
        const { data, status } = result;
        this.onload(data)
        if(status === 0){
          // let endTime = 100
        // let StartTime = 0
        // let nowTime = 80
        const intervalId = setInterval(() => {
          const nowTime = Math.floor(new Date().getTime() / 1000);
          const total = data.EndTime - data.StartTime;
          const elapsed = nowTime - data.StartTime;
          const progress = Math.floor(Math.min((elapsed / total) * 100, 100));
          // debugger
          
          if (nowTime >= data.EndTime) {
            clearInterval(intervalId);
            this.percentage = progress;
            this.trackcolor = '#ee0a24';
            this.pivottext = `${progress}%超时`;
            this.pivotcolor = '#ee0a24';
            this.color = '#ee0a24';
          } else if (progress >= data.OverDesc * 100) {
            this.percentage = progress;
            this.pivottext = `${progress}%即将超时`;
            this.pivotcolor = '#e6a23c';
            this.color = '#e6a23c';
          } else {
            this.percentage = progress;
            this.pivottext = `${progress}%`;
            // console.log(this.percentage, '百分比');
          }
        }, 1000);
        }
      } catch (e) {
        console.log(e);
      }
    },

    onload(data) {
      //组件需转换成毫秒
      const nowTime = Math.floor(new Date().getTime() / 1000);
      const timeDiff = data.EndTime - nowTime;
      // 如果当前时间早于开始时间，则将开始时间作为倒计时的起点
      if (nowTime < data.StartTime) {
        timeDiff = data.StartTime - nowTime;
        this.countDown = timeDiff * 1000;
      } else {
        this.countDown = timeDiff * 1000;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.van-cell__value {
  min-width: 70%;
}
.ticket-info.van-collapse-item {
  /deep/ .van-collapse-item__content {
    padding: 0;
  }
  /deep/ .van-collapse-item__title span {
    color: #969799;
  }
}
.SLA-box {
  padding-bottom: 10px;
}
.timeInfo {
  display: flex;
  padding: 10px 0 0 5px;
  align-items: center;
}
</style>
