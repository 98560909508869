import { formatApi, postAny } from '../baseApi';
import { Message } from 'element-ui';

export async function addSignUp(Columns) {
  const result = await formatApi({
    Action: 'OperateData',
    Method: 'run',
    SystemId: '2',
    SchemaId: 'location_sign',
    OpType: 'Add',
    Columns,
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`打卡接口调用失败：${data.msg}`);
    throw new Error(data.msg);
  }

  return data.data;
}

export async function deleteSignUp(Operator, Id) {
  const result = await formatApi({
    Action: 'OperateData',
    Method: 'run',
    SystemId: '2',
    SchemaId: 'location_sign',
    OpType: 'Delete',
    AppendInfo: {
      Operator,
    },
    Conditions: {
      Id,
    },
  });

  const { data } = result;
  if (data.status !== 0) {
    Message.error(`删除打卡接口调用失败：${data.msg}`);
    throw new Error(data.msg);
  }

  return data.data;
}
