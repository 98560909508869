import { Message } from 'element-ui';
import { postRelay } from '@/api/baseApi';

// 定义的state初始值
const state = {
  items: [],
  group: '',
  version_id: '',
  ProcessDefinitionKey: ''
};

// 定义的state的初始值方法，传入state或者额外的方法，然后利用vuex的双向数据驱动进行值的改变
// 可通过this.$store.commit(' ')调用，但是触发的是同步事件
const mutations = {
  SET_APP_CONFIG(state, config) {
    state.items = config.items;
    state.group = config.group;
    state.version_id = '';
  },
};

// 使用actions的好处在于不会触发同步时间，而是异步事件
// actions里面自定义的函数接收一个context参数和要变化的形参，context与store实例具有相同的方法和属性，所以它可以执行context.commit(' ')
const actions = {
  // 从七彩石配置中心获取配置
  async getConfigFromRainbow(context) {
    try {
      const params = {
        group: process.env.NODE_ENV === 'development' ? 'test' : 'prod',
      };
      const data = await postRelay('/pull/rainbow', params);
      const resData = data.data;
      if (resData.status != 0) {
        throw new Error(resData.msg);
      }
      const items = [];
      const allKeys = Object.keys(resData.data.items);
      for (const key of allKeys) {
        items.push({
          key: key,
          value: resData.data.items[key],
        });
      }
      const configData = {
        items: items,
        group: process.env.VUE_APP_CONFIG_GROUP,
      };
      context.commit('SET_APP_CONFIG', configData);
    } catch (err) {
      Message.error({ message: `获取配置中心接口错误：${err.message}` });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
