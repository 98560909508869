import { formatApi } from '../baseApi';
// 情计无Pn情况下获取全部PN
export async function getPnList(asset_dict, parmas) {
  try {
    const result = await formatApi({
      Action: 'Nbroker',
      Method: 'UrlProxy',
      NbrokerData: {
        context: {
          method_name: 'according_asset_type_get_pn',
          ...parmas,
        },
        args: { asset_dict },
      },
      ServiceUrl: 'http://ibroker-asset:8080/nBroker/api/v1/task',
      SystemId: '2',
    });
    return result.data.data;
  } catch (error) {}
}

// 获取一级机房
export async function getMachineRoom(campusName) {
  try {
    const result = await formatApi({
      Action: 'Nbroker',
      Method: 'UrlProxy',
      NbrokerData: {
        context: {
          service_name: 'GoStorage',
          method_name: 'req_idcdb',
        },
        args: {
          campus_name: campusName,
        },
      },
      ServiceUrl: 'http://ibroker-asset:8080/nBroker/api/v1/task',
      SystemId: '2',
    });
    return result.data.data;
  } catch (error) {}
}

// 获取资产位置
export async function getAssetLocation(primaryMachineroom) {
  try {
    const result = await formatApi({
      Action: 'Nbroker',
      Method: 'UrlProxy',
      NbrokerData: {
        context: {
          service_name: 'EsdbLib',
          method_name: 'getPosInfo',
        },
        args: {
          type: 'name',
          data: primaryMachineroom || [],
        },
      },
      ServiceUrl: 'http://ibroker-corebiz:8080/nBroker/api/v1/task',
      SystemId: '2',
    });
    return result.data.data;
  } catch (error) {}
}
// 保存流程
export async function saveProcess(variablesDict, instanceId) {
  try {
    const result = await formatApi({
      Action: 'Nbroker',
      Method: 'UrlProxy',
      NbrokerData: {
        context: {
          service_name: 'GoStorage',
          method_name: 'variable_data_write',
        },
        args: {
          variables_dict: variablesDict,
          instance_id: instanceId,
        },
      },
      ServiceUrl: 'http://ibroker-asset:8080/nBroker/api/v1/task',
      SystemId: '2',
    });
    return result.data.data.result;
  } catch (error) {}
}
// 拉取流程
export async function pullOutProcess(variableNameList, instanceId) {
  try {
    const result = await formatApi({
      Action: 'Nbroker',
      Method: 'UrlProxy',
      NbrokerData: {
        context: {
          service_name: 'GoStorage',
          method_name: 'variable_data_read',
        },
        args: {
          variable_name_list: variableNameList,
          instance_id: instanceId,
        },
      },
      ServiceUrl: 'http://ibroker-asset:8080/nBroker/api/v1/task',
      SystemId: '2',
    });
    return result.data.data.result;
  } catch (error) {}
}
