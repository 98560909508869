<!-- 资产入库表格 -->
<template>
  <div class="content">
    <div class="search" v-if="isShowSearch">
      <el-form label-position="right" style="width:25%;margin-top:10px" @submit.native.prevent>
        <el-form-item label="PN搜索:" label-width="80px">
          <el-input v-model="searchPn" placeholder="请输入PN"></el-input>
        </el-form-item>
      </el-form>
      <el-button @click="debounceSearchData" type="primary" style="height:30px;margin-left:10px"
        >查询</el-button
      >
      <el-button @click="debounceRestData" style="height:30px">重置</el-button>
    </div>
    <h5 style="margin-left:5px" v-if="isShowSearch">
      温馨提示:
      <span style="color: red">点击【重置按钮】不会对已操作过的数据进行清除!只是恢复全量数据</span>
    </h5>
    <div class="table" v-loading="is_loading">
      <el-table
        key="tableKey"
        ref="tableData"
        :data="pageData"
        tooltip-effect="dark"
        style="width: 100%"
        border
        @selection-change="onSelect"
      >
        <el-table-column type="selection" min-width="40" :show-overflow-tooltip="true" fixed="left">
        </el-table-column>
        <el-table-column
          prop="seqId"
          label="序号"
          min-width="75"
          :show-overflow-tooltip="true"
          fixed="left"
          align="center"
        >
        </el-table-column
        ><el-table-column
          prop="assets_type"
          min-width="130"
          label="资产类型"
          :show-overflow-tooltip="true"
          fixed="left"
          align="center"
        >
        </el-table-column
        ><el-table-column
          prop="mode"
          min-width="80"
          label="管理方式"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column
        ><el-table-column
          prop="manufacturer"
          min-width="80"
          label="厂商"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column
        ><el-table-column
          prop="pn"
          min-width="150"
          label="PN"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column
        ><el-table-column
          prop="inLib"
          min-width="90"
          label="逻辑库"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column
        ><el-table-column
          prop="dept_name"
          min-width="90"
          label="部门"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column
        ><el-table-column
          prop="outway"
          min-width="90"
          label="出库方式"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column
        ><el-table-column
          prop="enabled"
          min-width="80"
          label="是否可用"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column
        ><el-table-column
          prop="amount"
          min-width="65"
          label="数量"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column
        ><el-table-column
          prop="assetId"
          min-width="120"
          label="固资"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column
        ><el-table-column
          prop="sn"
          min-width="150"
          label="SN"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column
        ><el-table-column
          prop="Actual_PN"
          min-width="200"
          label="实际PN"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template v-slot="{ row }">
            <el-select
              :disabled="row.mode === '计件' && !row.isSelect"
              :value="row.Actual_PN"
              filterable
              :loading="selectLoading"
              @change="(val) => onPnChange(row, val)"
              @focus="getPnlist(row)"
            >
              <el-option
                v-for="(item, index) in pnList"
                :key="index"
                :value="item.value"
                :label="item.label"
              >
              </el-option>
            </el-select>
          </template> </el-table-column
        ><el-table-column
          prop="verify"
          min-width="80"
          label="确认数量"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column
        ><el-table-column
          prop="scan_sn"
          min-width="150"
          label="扫描SN"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template v-slot="{ row, $index }">
            <span v-if="pageData[$index].scan_sn"> {{ pageData[$index].scan_sn }}</span>
          </template> </el-table-column
        ><el-table-column
          prop="asset_bank"
          min-width="150"
          label="资产库"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="asset_shelf"
          min-width="100"
          label="资产架"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="asset_position"
          min-width="90"
          label="资产位"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
      </el-table>
    </div>

    <div class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
        :current-page="currentPage"
        :page-sizes="pageSize"
        @current-change="changePage"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getPnList, getPN } from '@/api/toStorageTable/index';
import { debounce } from 'lodash';
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    context: {
      type: Object,
      default: () => ({}),
    },
    toggle: {
      type: Boolean,
      default: false,
    },
    ActualPnList: {
      type: Array,
      default: () => [],
    },
    scan_snVal: {
      type: String,
      default: '',
    },
    initVal: {
      type: Number,
      default: 0,
    },
    is_ReceivedList: {
      type: Array,
      default: () => [],
    },
    is_loading: {
      type: Boolean,
      default: false,
    },
    business_scenario: {
      type: String,
      default: '',
    },
    start_time: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tableData: [],
      pnList: [],
      selectLoading: false,
      quantityStorage: 0,
      currentPage: 1,
      pageSize: [50],
      pageData: [],
      pagerCount: 2,
      searchPn: '',
      searchPnList: [],
      tempData: [],
      tags: [], // 标签数组
      // 是否显示筛选pn
      isShowSearch: false,
    };
  },

  watch: {
    value: {
      handler(val) {
        this.$nextTick(() => {
          this.tableData = [...val];
          this.tempData = [...val];
          this.changePage(this.currentPage);
        });
      },
      deep: true,
    },
    toggle: {
      handler() {
        this.$nextTick(() => {
          this.$refs.tableData.clearSelection();
          this.$refs.tableData.doLayout();
          this.tableData = [...this.tableData];
          this.changePage(this.currentPage);
        });
      },
      deep: true,
      immediate: false,
    },

    is_ReceivedList: {
      handler() {
        const is_ReceivedNums = this.tableData.filter((item) => item.scan_sn);
        this.quantityStorage = this.initVal + is_ReceivedNums.length;
        this.$emit('changeQuantityStorage', this.quantityStorage);
      },
      deep: true,
      immediate: false,
    },
    // 2024年7.2号的工单不显示筛选功能
    start_time: {
      handler(val) {
        this.isShowSearch = new Date(val) > new Date('2024-07-02 23:59:59');
      },
      deep: true,
      immediate: false,
    },
  },

  mounted() {
    this.$emit('selection-change', this.tableData);
  },
  methods: {
    onSelect(val) {
      this.$emit('selection-change', val);
    },
    async onPnChange(row, val) {
      try {
        this.tableData = [...this.tableData];
        const { pn, mode } = row;
        if (!pn) {
          if (mode === '计件') {
            row.scan_sn = this.scan_snVal;
          }
          row.is_add = true;
          row.is_Received = true;
          row.is_PN = true;
          this.$emit('changeReceivedList', this.quantityStorage);
          this.$emit('changeIsaddSn');
        }
        // 减容场景判断pn是否存在
        if (mode === '计件' && pn && this.business_scenario === '减容') {
          this.is_loading = true;
          const { result: pnList, code } = await getPN(row);
          if (code === 0) {
            this.is_loading = false;
            if (pnList.includes(val)) {
              this.$message.success(`新增成功`);
            } else {
              this.$message.error(
                `【扫描SN:${this.scan_snVal}】在ndb和amdb都查不到,无法新增,请联系集中服务台`
              );
              return;
            }
          } else {
            this.is_loading = false;
          }
        }
        // 判断是否为新插入的扫描sn
        if (this.scan_snVal) {
          row.scan_sn = this.scan_snVal;
          row.is_add = true;
          this.$emit('changeIsaddSn');
        }
        row.Actual_PN = val;
        row.pn = val;
        this.$message.success(`新增成功`);
        this.$emit('change', this.tableData);
      } catch (error) {
        console.log(error);
      }
    },
    // 获取实际PN
    async getPnlist(val) {
      try {
        const { mode, pn, is_PN } = val;
        const pnData = val.original_pn ? val.original_pn.split('|||') : [];
        // 计量无Pn
        if (mode === '计量' && !pn) {
          this.selectLoading = true;
          const data = await getPnList(val, this.context);
          const { result, code } = data;
          if (code === 0) {
            this.pnList = result.map((item) => ({
              label: item,
              value: item,
            }));
            this.selectLoading = false;
          } else {
            this.selectLoading = true;
          }
        } else if (pn) {
          if (is_PN) {
            this.selectLoading = true;
            const data = await getPnList(val, this.context);
            const { result, code } = data;
            if (code === 0) {
              this.pnList = result.map((item) => ({
                label: item,
                value: item,
              }));
              this.selectLoading = false;
            } else {
              this.selectLoading = true;
            }
            return;
          }
          this.selectLoading = false;
          this.pnList = pnData.map((item) => ({
            label: item,
            value: item,
          }));
        } else if (mode === '计件' && !pn) {
          this.selectLoading = false;
          this.pnList = this.ActualPnList.map((item) => ({
            label: item,
            value: item,
          }));
        }
      } catch (error) {
        this.selectLoading = true;
        this.$message.error(error);
      }
    },

    changePage(page) {
      this.currentPage = page;
      const startIndex = (this.currentPage - 1) * this.pageSize[0];
      const endIndex = startIndex + this.pageSize[0];
      this.pageData = this.tableData.slice(startIndex, endIndex);
    },
    debounceSearchData: debounce(function() {
      this.searchData();
    }, 300),
    debounceRestData: debounce(function() {
      this.restData();
    }, 300),
    searchData() {
      try {
        if (!this.searchPn) {
          this.$message.error('请输入PN');
          return;
        }
        this.tableData = this.tempData.filter(
          (item) => item.original_pn && item.original_pn.includes(this.searchPn.trim())
        );
        this.currentPage = 1;
        this.changePage(1);
        this.$emit('changeSearch', {
          isSearch: true,
          tableData: this.tableData,
        });
      } catch (error) {
        console.log(error);
      }
    },
    restData() {
      this.searchPn = '';
      this.tableData = this.tempData;
      this.changePage(1);
      this.$emit('changeSearch', {
        isSearch: false,
        tableData: [],
      });
    },
  },
};
</script>

<style scoped lang="less">
::v-deep .el-textarea__inner {
  min-height: 22px;
  height: 22px;
  font-size: 11px;
}
.content {
  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .search {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
}
</style>
