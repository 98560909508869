<template>
  <div class="wrapper">
    <!-- <h1>camera扫一扫模块</h1> -->
    <van-field
      ref="inputValue"
      v-model="inputValue"
      class="scanner-field"
      :placeholder="placeholder || '请扫描'"
      autocomplete="off"
      validate-first
      :disabled="disabled"
      :readonly="readonly"
      @keyup.enter="onEnter"
      @input="onInput"
    >
      <template #right-icon>
        <div @click="toScan">
          <van-icon name="scan" size="30px" />
        </div>
      </template>
    </van-field>
  </div>
</template>

<script>
// import wx from "weixin-js-sdk";
import axios from 'axios';
import { mapGetters } from 'vuex';
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 实时签名
      signatrue: '',
      timestamp: '',
      // 扫描数据
      inputValue: '',
    };
  },
  watch: {
    value: {
      handler(newVal) {
        if (!this.multiple) {
          this.inputValue = newVal;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.getSignatrue();
  },
  computed: {
    ...mapGetters(['getConfigItem']),
  },
  methods: {
    onEnter() {
      this.$emit('enter', this.inputValue);
      if (this.$refs.inputValue) {
        this.$refs.inputValue?.focus();
      } else {
        return;
      }
      if (this.multiple) {
        this.inputValue = '';
        this.$emit('input', this.inputValue);
      }
    },
    onInput() {
      this.$emit('input', this.inputValue);
    },
    getSignatrue() {
      const data = {
        Url: window.location.href,
        Noncestr: 'otob',
        Timestamp: `${Math.round(new Date() / 1000)}`,
      };
      // 获取触发接口时的时间戳
      this.timestamp = data.Timestamp * 1;
      axios
        .post(this.getConfigItem('signature'), data, {
          headers: {
            authToken: sessionStorage.getItem('AuthToken'),
                userid: sessionStorage.getItem('userid'),
          },
        })
        .then((res) => {
          this.signatrue = res.data.signature;
        })
        .catch((err) => {
          console.log(err);
          err && alert(JSON.stringify(err));
        });
    },

    toScan() {
      try {
        window.wx.config({
          beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
          debug: false, // 在测试没问题之后，关闭debug模式，防止弹窗
          appId: this.getConfigItem('corpID'), // 必填，企业微信的corpID
          timestamp: this.timestamp, // 必填，生成签名的时间戳
          nonceStr: 'otob', // 必填，生成签名的随机串
          signature: this.signatrue, // 必填，签名，见 附录-JS-SDK使用权限签名算法
          jsApiList: ['scanQRCode'], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
        });
        wx.error((e) => {
          e && alert(JSON.stringify(e));
        });
        window.wx.ready((e) => {
          window.wx.scanQRCode({
            desc: 'scanQRCode desc',
            needResult: 1, // 默认为0，扫描结果由企业微信处理，1则直接返回扫描结果，
            scanType: ['qrCode', 'barCode'], // 可以指定扫二维码还是条形码（一维码），默认二者都有
            success: (res) => {
              // 回调
              const result = res.resultStr; // 当needResult为1时返回处理结果
              // 扫描结果传递到父组件
              this.inputValue = result;
              this.onEnter();
            },
            error(res) {
              res && alert(JSON.stringify(res));
              if (res.errMsg.indexOf('function_not_exist') > 0) {
                alert('版本过低请升级');
              }
            },
          });
        });
      } catch (e) {
        e && alert(JSON.stringify(err));
      }
    },
  },
};
</script>
<style lang="less" scoped>
.wrapper {
  width: 100%;
  .van-cell {
    padding: 0;
    &::after {
      display: none !important;
    }
  }
}
.scanner-field.van-cell.van-field {
  /deep/ .van-cell__value.van-cell__value--alone.van-field__value {
    overflow: visible;
  }
}
</style>
