<!-- 服务器直配资产位置确认 -->
<template>
  <div class="content">
    <el-tag type="primary">温馨提示:没勾选数据设置验收结果,默认为全部勾选</el-tag>
    <el-button type="success" @click="checkoutResult">设置验收结果</el-button>
    <div class="table">
      <el-table
        v-loading="is_loading"
        key="tableKey"
        ref="tableData"
        :data="pageData"
        tooltip-effect="dark"
        style="width: 100%"
        border
        :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
        @selection-change="onSelect"
      >
        <el-table-column
          type="selection"
          min-width="40"
          :show-overflow-tooltip="true"
          fixed="left"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="seq_id"
          label="序号"
          min-width="50"
          :show-overflow-tooltip="true"
          align="center"
          fixed="left"
        ></el-table-column>
        <el-table-column
          prop="confirm_result"
          min-width="100"
          label="验收结果"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="confirm_fail_reason"
          min-width="150"
          label="原因"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="asset_id"
          min-width="130"
          label="固资号"
          align="center"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="sn"
          min-width="130"
          label="SN"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="idc_name"
          min-width="140"
          label="机房管理单元"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="rack_name"
          min-width="100"
          label="机架"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="pos_code"
          min-width="80"
          label="机位"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="server_model"
          min-width="120"
          label="设备型号"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="device_type"
          min-width="100"
          label="设备类型"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="model_name"
          min-width="90"
          label="标准机型"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="logic_area"
          min-width="150"
          label="逻辑区域"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="is_special_wiring"
          min-width="80"
          label="特殊接线"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="is_sanjiang"
          min-width="70"
          label="是否为三江源"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="sanjiang_assetid"
          min-width="120"
          label="三江源固资"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
        :current-page="currentPage"
        :page-sizes="pageSize"
        @current-change="changePage"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="设置本次验收结果"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <span>
        <el-form ref="formRef" :model="formParms" label-width="100px">
          <el-form-item
            label="验收结果"
            prop="checkResult"
            :rules="{ required: true, message: '请选择', trigger: 'blur' }"
          >
            <el-select v-model="formParms.checkResult" placeholder="请选择">
              <el-option
                v-for="item in checkOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option> </el-select
          ></el-form-item>
          <el-form-item
            label="验收失败原因"
            prop="errorResult"
            v-if="this.formParms.checkResult == '验收失败'"
            :rules="{ required: true, message: '请选择', trigger: 'blur' }"
          >
            <el-select
              v-model="formParms.errorResult"
              @change="changeErrorResult"
              placeholder="请选择"
            >
              <el-option
                v-for="item in checkReason"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option> </el-select
          ></el-form-item>
          <el-form-item style="margin-top:20px">
            <el-button type="primary" @click="onSubmit('formRef')">确 定</el-button>
            <el-button @click="dialogVisible = false">取消</el-button>
          </el-form-item>
        </el-form>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    context: {
      type: Object,
      default: () => ({}),
    },
    toggle: {
      type: Boolean,
      default: false,
    },
    is_loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableData: [],
      quantityStorage: 0,
      currentPage: 1,
      pageSize: [100],
      pageData: [],
      dialogVisible: false,
      formParms: {
        checkResult: '',
        errorResult: '',
      },
      checkOptions: [
        {
          value: '验收成功',
          label: '验收成功',
        },
        {
          value: '验收失败',
          label: '验收失败',
        },
        {
          value: '延迟验收',
          label: '延迟验收',
        },
      ],
      checkReason: [],
      selectData: [],
    };
  },
  computed: {
    ...mapGetters(['getConfigItem']),
  },
  watch: {
    value: {
      handler(val) {
        this.$nextTick(() => {
          this.tableData = [...val];
          this.changePage(this.currentPage);
        });
      },
      deep: true,
    },
    toggle: {
      handler() {
        this.$nextTick(() => {
          this.$refs.tableData.doLayout();
          this.tableData = [...this.tableData];
          this.changePage(this.currentPage);
        });
      },
      deep: true,
      immediate: false,
    },
  },
  mounted() {
    this.$emit('selection-change', this.tableData);
  },
  methods: {
    // 设置验收结果
    checkoutResult() {
      const { checkReason } = JSON.parse(this.getConfigItem('checkReason'));
      this.checkReason = checkReason;
      this.dialogVisible = true;
    },
    changeErrorResult(val) {
      this.errorVal = val;
    },
    onSubmit(formName) {
      try {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            if (this.selectData.length > 0) {
              for (const item of this.selectData) {
                this.$set(item, 'confirm_result', this.formParms.checkResult);
                this.formParms.checkResult == '验收失败'
                  ? this.$set(item, 'confirm_fail_reason', this.errorVal)
                  : this.$set(item, 'confirm_fail_reason', '');
              }
            } else {
              for (const item of this.tableData) {
                this.$set(item, 'confirm_result', this.formParms.checkResult);
                this.formParms.checkResult == '验收失败'
                  ? this.$set(item, 'confirm_fail_reason', this.errorVal)
                  : this.$set(item, 'confirm_fail_reason', '');
              }
            }
            this.formParms.checkResult = '';
            this.formParms.errorResult = '';
            this.formParms.checkResult = '';
            this.$refs.tableData.clearSelection();
            this.$message.success(`设置成功`);

            this.dialogVisible = false;
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    onSelect(val) {
      this.selectData = val;
      this.$emit('selection-change', val);
    },
    changePage(page) {
      this.currentPage = page;
      const startIndex = (this.currentPage - 1) * this.pageSize[0];
      const endIndex = startIndex + this.pageSize[0];
      this.pageData = this.tableData.slice(startIndex, endIndex);
    },
  },
};
</script>

<style scoped lang="less">
.content {
  .table {
    width: 100%;
    overflow-x: auto;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
