export const SIGNUP_TYPE = {
  in: {
    text: '到场',
    icon: 'user',
  },
  out: {
    text: '离场',
    icon: 'truck',
  },
  common: {
    text: '打卡',
    icon: 'location',
  },
};
