<template>
<FormProvider :form="form">
      <FormLayout :labelCol="0" :wrapperCol="0" :labelWidth="120">
        <SchemaField :schema="operateFormSchema || {}"/>
      </FormLayout>
    </FormProvider>
</template>
<script>
import Space from './Space';
import Input from './Input';
import Upload from './Upload';
import Picker from './Picker';
import TimePicker from './TimePicker';
import Quill from '@/components/Formily/quill';
import Text from './text';
import Link from './link';
import Images from './images.ts';
import Button from './button';
import FormTab from './formTab';
import Scanner from './scanner.js';
import Export from './export.ts';
import Import from './import.ts';
import Location from './location.ts';
import Contact from './contact.ts';
import FormCollapse from './FormCollapse.js';
import Signature from './signature.ts';
import ArrayCards from './card.js';
import BackTop from './backTop.js';
import TimeButton from './timeButton.ts';
import ToStorageTable from '@/components/Formily/toStorageTable.ts';
import { searchTemplate } from '@/api/appDispose/pageTemplateApi';
import { postAny } from '@/api/baseApi';
import { createForm, onFormValuesChange } from '@formily/core';
import {
  formilyStepper,
  formilyCascader,
  formilyTimePicker,
  formilyFormItem,
  formilySwitch,
  formilyFormGrid,
} from './index.ts';
import CommonSelect from '@/components/Formily/CommonSelect';
import UserSelect from '@/components/Formily/userSelect';
import { FormProvider, createSchemaField } from '@formily/vue';
import {
  Form,
  Group,
  Submit,
  // ArrayTable,
  // Input,
  // Editable,
  PreviewText,
  Checkbox,
  // FormGrid,
  // Select,
  // Picker,
  // ArrayItems,
  // Space,
  FormLayout,
  // Reset,
  // Cascader,
  Radio,
  // Password,
  // TimePicker,
  // DatetimePicker,
} from '@formily/vant';
import { ArrayTable } from '@formily/element';
// import Breadcrumb from '@/components/Breadcrumb';
import { Message, Dialog, MessageBox } from 'element-ui';

const fields = createSchemaField({
  components: {
    Form,
    Submit,
    FormItem: formilyFormItem,
    ArrayTable,
    ArrayCards,
    Input,
    // Editable,
    PreviewText,
    Checkbox,
    FormGrid: formilyFormGrid,
    Select: Picker,
    // ArrayItems,
    Space,
    FormLayout,
    // Reset,
    // Text,
    // Link,
    // Button,
    Radio,
    // Password,
    TimePicker,
    DatePicker: formilyTimePicker,
    TextArea: Input,
    Switch: formilySwitch,
    Password: Input,
    FormCollapse,
    Location,
    // FormTab,
    // ArrayCards,
    Upload,
    Cascader: formilyCascader,
    Quill,
    Text,
    Link,
    FormTab,
    Button,
    NumberPicker: formilyStepper,
    UserSelect,
    CommonSelect,
    Images,
    Scanner,
    Signature,
    Export,
    Import,
    Contact,
    BackTop,
    TimeButton,
    ToStorageTable,
    // ArrayCollapse,
    // Quill,
    // UserSelect,
    // CommonSelect,
    // HtmlText,
  },
  scope: {
    ding: document.querySelector('#ding-mp3'),
    alarm: document.querySelector('#alarm-mp3'),
    finish: document.querySelector('#finish-mp3'),
    Message,
    Dialog,
    MessageBox,
  },
});

let vm;

export default {
  name: 'RenderPage',
  components: { FormProvider, FormLayout, ...fields },

  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    id: {
      type: String,
      default: '',
    },
    tplKey: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
    params: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      form: createForm({
        effects() {
          onFormValuesChange((form) => {
            vm && vm.$emit('change', form.values);
          });
        },
      }),
      pageId: '',
      operateFormSchema: {},
      values: {},
    };
  },
  async created() {
    vm = this;
    await this.getTemplate();
    if (this.value && Object.keys(this.value).length) {
      this.form.setValues({ ...this.value });
    } else this.getParams();
  },
  methods: {
    async getTemplate() {
      try {
        const { data } = await searchTemplate({ SearchCondition: { Id: this.tplKey ? '' : this.id, TemplateKey: this.tplKey } });
        const [{ TemplateStyle, TemplateParams }] = data.List;
        const res = this.getOperateFormSchema(TemplateStyle);
        this.operateFormSchema = res
        console.log(res, 55)
        if (!this.value || !Object.keys(this.value).length) this.form.setValues({ ...JSON.parse(TemplateParams || '{}') });
        this.$emit('ready', this.form);
      } catch (e) {
        this.$message.error(e);
      }
    },
    formatSchema(val) {
      try {
        this.recursiveDelete(val.schema ? val.schema : val);
        this.disabled && this.recursiveDisable(val.schema ? val.schema : val);
        const str = JSON.stringify(val, this.formatJson).replace(
          /\/relay\/api/g,
          '/relay/ifob/api'
        );

        const res = JSON.parse(str);
        if (res && res.schema) {
          return res.schema;
        }
        return res || {};
      } catch (e) {
        console.log(e);
        return {};
      }
    },
    formatJson(key, value) {
      if (key === 'labelWidth') return undefined;
      if (key === 'x-component' && value === 'Input.TextArea') return 'TextArea';
      if (key === 'enum' && Array.isArray(value))
        return value.map((item) =>
          typeof item === 'object' ? { ...item, name: item.name || item.value } : item
        );
        
      return value;
    },
    recursiveDelete(obj) {
      if (!obj) return;
      if (obj.properties) {
        this.recursiveDelete(obj.properties);
      }
      Object.values(obj).map((property) => {
        if (property.properties) {
          this.recursiveDelete(property.properties);
        }
        if (property.items) {
          this.recursiveDelete(property.items);
        }
        if (
          [
            'DatePicker',
            'Select',
            'TimePicker',
            'Input',
            'Input.TextArea',
            'Cascader',
            'Space',
            'FormGrid',
          ].includes(property['x-component'])
        ) {
          const decorator = property;
          decorator['x-decorator'] = null;
        }
        if (!property.title && ['ArrayTable', 'ArrayCard', 'ToStorageTable'].includes(property['x-component'])) {
          const decorator = property;
          decorator['x-decorator'] = null;
        }
        if (property['x-component'] === 'ArrayTable.Column' && Object.values(obj).length > 2) {
          const decorator = property;
          decorator['x-component-props'] = {
            ...decorator['x-component-props'],
            width: decorator['x-component-props'].width || 150,
          };
        }
      });
    },
    recursiveDisable(obj) {
      if (!obj) return;
      if (obj.properties) {
        this.recursiveDisable(obj.properties);
      }
      Object.values(obj).map((property) => {
        if (property.properties) {
          this.recursiveDisable(property.properties);
        }
        if (property.items) {
          this.recursiveDisable(property.items);
        }
        if (property['x-component']) {
          const item = property;
          item['x-pattern'] = 'readonly';
        }
      });
    },
    async getParams() {
      if (!this.url) return;
      try {
        const { data } = await postAny(this.url, this.params);
        if (!!data && !!Object.keys(data).length) this.form.setValues({ ...data.data });
      } catch (e) {
        this.$message.error(e);
      }
    },
    getOperateFormSchema(data) {
      try {
        const formSchema = this.formatSchema(JSON.parse(data || '{}'));
        console.log(formSchema, 66)
        return formSchema || {};
      } catch (e) {
        console.log(e)
        return {};
      }
    },
  },
};
</script>
<style lang="less" scoped>

.formily-todo {
  /deep/ .ql-picker-label {
    display: inline-flex;
    align-items: center;
  }
  /deep/ .ql-picker-options {
    z-index: 999;
  }
  /deep/ .ql-picker {
    display: inline-flex;
    align-items: center;
  }
  /deep/ .formily-vant-preview-text::after,
  /deep/ .van-cell::after {
    display: block;
  }
  /deep/ .van-cell__value .van-radio-group,
  /deep/ .van-cell__value .van-checkbox-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }
  /deep/ .van-field__label .van-cell {
    padding: 0;
    overflow: inherit;
    white-space: pre-line;
  }
  /deep/ .van-stepper__input {
    width: auto;
  }
  /deep/ .formily-element-array-table,
  /deep/ .formily-element-array-cards {
    width: 100%;
  }
  /deep/ .formily-vant-preview-input-title {
    width: auto;
  }
  /deep/ .van-field__label .van-cell::after {
    border: none;
  }
  /deep/ .van-field--disabled {
    background: #f7f8fa;
    transition: background 0.2s ease-out;
  }
  /deep/ .van-field {
    transition: background 0.2s ease-out;
  }
}
</style>
