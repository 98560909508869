"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("@formily/vue");
var composition_api_1 = require("@vue/composition-api");
var Text = (0, composition_api_1.defineComponent)({
    name: 'Text',
    props: ['value', 'style'],
    setup: function (curProps, _a) {
        var attrs = _a.attrs;
        return function () {
            var props = __assign(__assign(__assign({}, attrs), curProps), { style: 'white-space: pre-wrap;word-break:break-all' });
            return (0, vue_1.h)(attrs.mode === 'normal' || !attrs.mode ? 'div' : attrs.mode, {
                attrs: props,
            }, {
                default: function () { return [props.value || attrs.content]; },
            });
        };
    },
});
exports.default = Text;
