/**
 * 工单是否有异常待办
 */
export const TICKET_EXCEPTION_END_TASK = [
  {
    value: 'exception,',
    label: '全部',
  },
  {
    value: 'exception',
    label: '有',
  },
  {
    value: '',
    label: '没有',
  },
];

/**
 * 工单状态
 */
export const TICKET_STATUS = {
  OPEN: '运行中',
  END: '已结单',
};

/**
 * 工单状态列表
 */
export const TICKET_STATUS_LIST = [
  {
    value: 'OPEN',
    label: '运行中',
  },
  {
    value: 'END',
    label: '已结单',
  },
];

/**
 * 是否强制结单
 */
export const TICKET_IS_FORCE_END = [
  {
    value: '1',
    label: '是',
  },
  {
    value: '0',
    label: '否',
  },
];

/**
 * 工单等级
 */
export const SLA_LEVEL = {
  1: '一级',
  2: '二级',
  3: '三级',
  4: '四级',
  5: '五级',
};

/**
 * 设备字典表
 */
export const NDV_KEY_NAME_MAP = {
  LINE: {
    port_with_devname: '设备名+端口',
    port_with_devip: '设备IP+端口',
    line_name: '专线名',
    line_no: '专线编号',
    devname: '设备名',
  },
  DEVICE: {
    devname: '设备名',
  },
};

/**
 * 告警字典表
 */
export const EVENT_KEY_NAME_MAP = {
  ALERT: {
    alarm_id: '告警ID',
  },
};

/**
 * 工单字段字典表
 */
export const TICKET_KEY_NAME_MAP = {
  TicketId: '工单号',
  Title: '工单标题',
  SceneType: '场景类型',
  ProcessDefinitionKey: '流程标识',
  Creator: '创建人',
  Handler: '处理人',
  SLALevel: '工单等级',
  TicketStatus: '工单状态',
  CreateTime: '创建时间',
  EndTime: '结单时间',
};
