<template>
  <div>
    <van-button @click="onCopy" type="primary">复制code</van-button>
  </div>
</template>

<script>
export default {
  name: 'Testing',
  mounted() {
    this.code = this.$route.query?.code || sessionStorage.getItem('AuthToken') || '';
  },
  methods: {
    onCopy() {
      try {
        const url = location.origin + '/loading?path=todo&state=STATE&code=' + this.code
        if (navigator.clipboard) {
          // clipboard api 复制
          navigator.clipboard.writeText(url);
        } else {
          var textarea = document.createElement('textarea');
          document.body.appendChild(textarea);
          // 隐藏此输入框
          textarea.style.position = 'fixed';
          textarea.style.clip = 'rect(0 0 0 0)';
          textarea.style.top = '10px';
          // 赋值
          textarea.value = url;
          // 选中
          textarea.select();
          // 复制
          document.execCommand('copy', true);
          // 移除输入框
          document.body.removeChild(textarea);
        }
        this.$message.success('复制成功！');
      } catch (e) {
        this.$message.error('复制失败');
      }
    },
  },
};
</script>

<style></style>
