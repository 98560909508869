<!-- 网络资产出库计件表格 -->
<template>
  <div class="content">
    <el-tag
      >温馨提示:鼠标经过资产类型和SN字段,会对应带出资产的推荐位置(位置推荐是在创建工单那刻生成,会存在偏差,仅供参考)</el-tag
    >
    <div class="table">
      <el-table
        v-loading="is_loading"
        key="tableKey"
        ref="tableData"
        :data="pageData"
        tooltip-effect="dark"
        style="width: 100%"
        border
        :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
        @selection-change="onSelect"
      >
        <el-table-column
          prop="seqId"
          label="序号"
          min-width="50"
          :show-overflow-tooltip="true"
          fixed="left"
          align="center"
        >
        </el-table-column
        ><el-table-column
          prop="assets_type"
          min-width="130"
          label="资产类型"
          fixed="left"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              :content="scope.row.position || scope.row.assets_type"
              placement="top"
            >
              <span>{{ scope.row.assets_type }}</span>
            </el-tooltip> </template
          >" </el-table-column
        ><el-table-column
          prop="mode"
          min-width="80"
          label="管理方式"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column
        ><el-table-column
          prop="type"
          min-width="80"
          label="类型"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column
        ><el-table-column
          prop="PN"
          min-width="250"
          label="型号"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column
        ><el-table-column
          prop="nowinLib"
          min-width="90"
          label="现部门"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column
        ><el-table-column
          prop="inLib"
          min-width="90"
          label="逻辑库"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="deptName"
          min-width="90"
          label="部门"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="outway"
          min-width="110"
          label="出库方式"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column
        ><el-table-column
          prop="assetId"
          min-width="150"
          label="固资"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column
        ><el-table-column
          prop="sn"
          min-width="150"
          label="SN"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope" v-if="scope.row.sn">
            <el-tooltip
              class="item"
              effect="dark"
              :content="scope.row.position || scope.row.sn"
              placement="top"
            >
              <span>{{ scope.row.sn }}</span>
            </el-tooltip> </template
          >"
        </el-table-column>
        <el-table-column
          prop="amount"
          min-width="60"
          label="申请数量"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
      </el-table>
    </div>

    <div class="pagination" v-if="tableData.length >= 10">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
        :current-page="pageInfo.currentPage"
        :page-sizes="pageInfo.pageSize"
        @size-change="changeSize"
        @current-change="changePage"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    context: {
      type: Object,
      default: () => ({}),
    },
    toggle: {
      type: Boolean,
      default: false,
    },
    is_loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableData: [],
      pageData: [],
      pageInfo: {
        currentPage: 1,
        pageSize: [10, 20, 30, 50],
        size: 10,
      },
    };
  },

  watch: {
    value: {
      handler(val) {
        this.$nextTick(() => {
          this.tableData = [...val];
          this.changePage(this.pageInfo.currentPage);
        });
      },
      deep: true,
    },
    toggle: {
      handler() {
        this.$nextTick(() => {
          this.$refs.tableData.doLayout();
          this.tableData = [...this.tableData];
          this.changePage(this.pageInfo.currentPage);
        });
      },
      deep: true,
      immediate: false,
    },
  },
  mounted() {
    this.$emit('selection-change', this.tableData);
  },
  methods: {
    onSelect(val) {
      this.$emit('selection-change', val);
    },
    changePage(page) {
      this.pageInfo.currentPage = page;
      const startIndex = (this.pageInfo.currentPage - 1) * this.pageInfo.size;
      const endIndex = startIndex + this.pageInfo.size;
      this.pageData = this.tableData.slice(startIndex, endIndex);
    },
    changeSize(size) {
      this.pageInfo.size = size;
      this.changePage(1);
    },
  },
};
</script>

<style scoped lang="less">
.content {
  .table {
    width: 100%;
    overflow-x: auto;
  }
  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
