<template>
  <!-- 基础信息 -->
  <div class="ticket-detail">
    <!-- <p class="section-title ml10">基础信息</p> -->
    <h3 style="text-align: center">{{ title || ticketBaseData.Title }}</h3>
    <TicketInfo v-if="ticketData.TicketId" :ticketData="ticketData" />
    <TodoOperation
      v-if="ticketData.TicketStatus && !isHistory"
      @title-change="val => (title = val)"
      :instanceId="ticketData.InstanceId"
      :ticketId="ticketId"
      :todoId="taskId"
      :ticketStatus="ticketData.TicketStatus"
    />
    <TodoHistory
      v-if="ticketData.TicketStatus && isHistory"
      @title-change="val => (title = val)"
      :instanceId="ticketData.InstanceId"
      :ticketId="ticketId"
      :taskId="taskId"
      :ticketStatus="ticketData.TicketStatus"
    />
  </div>
</template>

<script>
import TicketInfo from './ticketInfo.vue';
import TodoOperation from './complexTodo';
import TodoHistory from './complexDone';
import {
  getTicketData,
} from '@/api/tickets/ticketsApi';
export default {
  name: 'TicketDetail',
  components: {
    TicketInfo,
    TodoOperation,
    TodoHistory
  },
  created() {
    this.getTicketData(this.ticketId);
  },
  data() {
    return {
      ticketId: this.$route.query.ticketId,
      taskId: this.$route.query.taskId,
      ticketData: {},
      ticketBaseData: {},
      title: '',
      isHistory: this.$route.query.isHistory === '1'
    };
  },
  //   computed: {
  //     ...mapGetters(['getConfigItem']),
  //   },
  methods: {
    async getTicketData(TicketId) {
      try {
        this.loading = true;
        const ticketResult = await getTicketData({
          ResultColumns: {
            AdditionInfo: '',
            CreateTime: '',
            CurrentTasks: '',
            CustomKV: '',
            CustomRequestVarKV: '',
            Description: '',
            EndTime: '',
            EventInfo: '',
            ExceptionEndTaskId: '',
            InstanceId: '',
            IsForceEnd: '',
            NdvInfo: '',
            NetFaultRecoverTime: '',
            ProcessDefinitionKey: '',
            SceneType: '',
            SchemeNameCn: '',
            SLALevel: '',
            ServiceRecoverTime: '',
            StartProcessTime: '',
            StatusTag: '',
            TicketId: '',
            TicketStatus: '',
            Title: '',
            UserInfo: '',
            SchemeId: '',
            ForceEndReason: '',
            ForceEndOperator: '',
            OutputResultDatas: '',
            CurrentAllProcessUsers: '',
            Manager: '',
          },
          SearchCondition: { TicketId },
        });

        const { List } = ticketResult.data;

        const [data] = [...List];
        this.ticketData = data || {};
        // 存储流程标识
        this.$store.state.config.ProcessDefinitionKey = data?.ProcessDefinitionKey
        // 基本信息编辑
        this.ticketBaseData.Title = data.Title;
        this.ticketBaseData.Description = data.Description;

        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.ticket-detail {
  min-height: 100vh;

        overflow-y: hidden;
        background-color: #f7f8fa;
        // margin-top: -5px
}
</style>
