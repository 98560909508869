import { observer } from '@formily/reactive-vue';
import { h, useField, connect, mapProps, mapReadPretty } from '@formily/vue';
import { defineComponent, ref } from 'vue-demi';
// @ts-ignore
import Scanner from './Scanner.vue';
import { PreviewText } from '@formily/element';

function debounce(func1, func2) {
  let timer;  // 定时器

  return function (val) {
    const context = this;  // 记录 this 值,防止在回调函数中丢失

    // 如果定时器存在，则清除定时器(如果没有,也没必要进行处理)
    timer ? clearTimeout(timer) : null;
    func1.call(context, val);
    timer = setTimeout(() => {
      // 防止 this 值变为 window
      func2.apply(context);
    }, 400);
  };
}

const formilyScanner = observer(defineComponent({
  name: 'Scanner',
  props: ['value'],
  setup(curProps, { attrs, emit }) {
    const field = useField();
    const timeStamp = ref(0);
    const resList = ref([]);
    const changeVal = debounce(
      (val) => {
        val && resList.value.push(val);
      },
      () => {
        emit('enter', resList.value);
        emit('change', resList.value);
        resList.value = [];
      },
    );
    return () => h(
      Scanner,
      {
        attrs: { ...curProps, ...attrs },
        on: {
          enter: (val) => {
            if (field.value.componentProps.debounce) {
              const nowStamp = Number(new Date());
              // const diff = nowStamp - timeStamp.value;

              changeVal(val);
              timeStamp.value = nowStamp;
              // console.log(nowStamp, timeStamp.value, diff);
            } else {
              emit('enter', val);
              emit('change', val);
            }

            // if (field.value.componentProps.debounce && val === field.value.value) return;
          },
          input: (val) => {
            emit('input', val);
          },
        },
      },
      {
        default: () => [curProps.value || attrs.content],
      },
    );
  },
}));

const ScannerCpn = connect(
  formilyScanner,
  mapProps({
    value: 'modelValue',
    readOnly: 'readonly',
  }),
  mapReadPretty(PreviewText.Input),
);

export default ScannerCpn;
