<template>
  <div :style="getHorizontalStyle" v-loading.fullscreen.lock="loading">
    <el-image v-if="value" :alt="'签名'" :src="value" class="prev" :preview-src-list="[value]">
    </el-image>
    <br v-if="value" />
    <el-button class="w100" :disabled="disabled" :readonly="readonly" @click="visible = true">
      {{ value ? '重新签名' : '开始签名' }}
    </el-button>
    <el-button v-if="value && clearable" @click="reset"> 清除 </el-button>
    <el-dialog
      title="请签名"
      width="90%"
      :visible.sync="visible"
      :close-on-click-modal="false"
      destroy-on-close
      append-to-body
      @opened="init"
    >
      <div :id="'canvasBox' + uid">
        <canvas :id="'canvas' + uid"></canvas>
        <div class="greet mt10">
          <el-button plain type="primary" @click.native="clear">清屏</el-button>
          <el-button type="success" @click.native="upload">确认</el-button>
          <el-button @click.native="visible = false">取消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Draw from './draw.js';

export default {
  name: 'Signature',
  components: {
    Draw,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uid: '',
      visible: false,
      loading: false,
      msg: 'Just use canvas to draw',
      degree: 0, // 屏幕整体旋转的角度, 可取 -90,90,180等值
      scope: [
        {
          value: 0,
          title: '正常',
        },
        {
          value: 90,
          title: '顺时针旋转90°',
        },
        {
          value: 180,
          title: '顺时针旋转180°',
        },
        {
          value: -90,
          title: '逆时针旋转90°',
        },
      ],
    };
  },
  computed: {
    getHorizontalStyle() {
      const d = document;
      const w = window.innerWidth || d.documentElement.clientWidth || d.body.clientWidth;
      const h = window.innerHeight || d.documentElement.clientHeight || d.body.clientHeight;
      let length = (h - w) / 2;
      let width = w;
      let height = h;

      switch (this.degree) {
        case -90:
          length = -length;
        case 90:
          width = h;
          height = w;
          break;
        default:
          length = 0;
      }
      if (this.canvasBox) {
        this.canvasBox.removeChild(document.querySelector('canvas'));
        this.canvasBox.appendChild(document.createElement('canvas'));
        setTimeout(() => {
          this.initCanvas();
        }, 200);
      }
      return {
        // transform: `rotate(${this.degree}deg) translate(${length}px,${length}px)`,
        // width: `${width}px`,
        // height: `${height}px`,
        // transformOrigin: 'center center',
      };
    },
  },
  created() {
    this.uid = this.guid();
  },

  methods: {
    guid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0;
        const v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },
    init() {
      // this.visible = true;
      this.canvasBox = document.getElementById(`canvasBox${this.uid}`);
      this.initCanvas();
    },
    initCanvas() {
      const canvas = document.getElementById(`canvas${this.uid}`);
      canvas.height = 300;
      this.draw = new Draw(canvas);
      // this.draw.scale(null, 300);
    },
    clear() {
      this.draw.clear();
    },
    reset() {
      this.$emit('change', '');
    },
    download() {
      this.draw.downloadPNGImage(this.draw.getPNGImage());
    },
    upload() {
      try {
        this.loading = true;
        const image = this.draw.getPNGImage();
        // const blob = this.draw.dataURLtoBlob(image);
        const url = '/relay/cosfile/index';
        const successCallback = (res) => {
          // const res = JSON.parse(response);
          this.$emit('change', res ? res.FileList[0].url : '');
          this.$toast.success('签名已保存');
          this.visible = false;
        };
        const failureCallback = (error) => {
          this.$toast.fail(`签名保存失败, 请稍后重试: ${JSON.stringify(error || '')}`);
        };
        this.draw.upload(image, url, successCallback, failureCallback);
      } catch (e) {
        this.$toast.fail(`签名保存失败, 请稍后重试: ${JSON.stringify(e || '')}`);
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>
#canvasBox {
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
  width: 100% !important;
}
/* .greet {
  padding: 20px;
  font-size: 20px;
  user-select: none;
}
.greet el-button {
  cursor: pointer;
}
.greet select {
  font-size: 18px;
} */
.prev {
  height: 100px;
  width: 80%;
}
canvas {
  /* flex: 1; */
  height: 300px !important;
  width: 100% !important;
  overflow: hidden;
  cursor: crosshair;
  background: #dcdfe6;
}
</style>
