import { observer } from '@formily/reactive-vue';
import { connect, mapProps, mapReadPretty, h, useField } from '@formily/vue';
import { ref, defineComponent } from '@vue/composition-api';
import { Calendar as VanCalendar } from 'vant';
import { formilyFormItem } from './index';
import { PreviewText } from '@formily/vant';
import dayjs from 'dayjs';

const BaseCalendar = observer(defineComponent({
  name: 'FBaseCalendar',
  setup(props, { attrs, emit, slots }) {
    const {
      formItemProps = {},
      calendarProps = {},
      fieldListeners = {},
      calendarListeners = {},
    } = attrs;
    const { format } = attrs;
    const show = ref(false);
    const field = useField();
    const { startDate, endDate, payload } = field.value.componentProps;
    let start;
    let end;
    if (startDate && endDate && parseInt(payload, 10) && parseInt(payload, 10) > 0) {
      end = dayjs(endDate)
        .add(parseInt(payload, 10), 'day')
        .toDate();
      start = dayjs(startDate)
        .toDate();
    } else if (startDate && endDate && parseInt(payload, 10) && parseInt(payload, 10) < 0) {
      start = dayjs(startDate)
        .subtract(-parseInt(payload, 10), 'day')
        .toDate();
      end = dayjs(endDate)
        .toDate();
    } else {
      start = startDate
        ? dayjs(startDate)
          .toDate()
        : new Date(2010, 1, 0);
      end = endDate
        ? dayjs(endDate)
          .toDate()
        : new Date(2030, 1, 0)
    }
    return () => h(
      'div',
      {},
      {
        default: () => [
          h(
            formilyFormItem,
            {
              attrs: {
                value: format ? format(attrs.value) : attrs.value,
                ...formItemProps,
                readonly: true,
                isLink: field.value.pattern === 'editable',
              },
              on: {
                click: () => {
                  if (field.value.pattern !== 'editable') return;
                  show.value = true;
                },
                ...fieldListeners,
              },
            },
            slots,
          ),
          h(
            VanCalendar,
            {
              attrs: {
                value: show.value,
                minDate: start,
                maxDate: end,
                ...calendarProps,
              },
              on: {
                input: (val) => {
                  show.value = val;
                },
                confirm: (val) => {
                  emit('change', val);
                  show.value = false;
                },
              },
              ...calendarListeners,
            },
            {},
          ),
        ],
      },
    );
  },
}));

export const Calendar = connect(
  BaseCalendar,
  mapProps({ readOnly: 'readonly' }),
  mapReadPretty(PreviewText.Calendar),
);

export default Calendar;
