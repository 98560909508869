<template>
      <van-button :type="type" :size="size" :disabled="disabled" @click="wxLocation">
        {{ content }}
      </van-button>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
export default {
  props: {
    content: {
      type: String,
      default: '拉群',
    },
    type: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: '',
    },
    clearble: {
      default: true,
      type: Boolean,
    },
    userIds: {
      default: '',
      type: String,
    },
    groupName: {
      default: '讨论组',
      type: String,
    },
    value: {
      default: '',
      type: String,
    },
    chatId: {
      default: '',
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      signatrue: '',
      address: '',
      loading: false,
      timestamp: '',
      latitude: '',
      longitude: '',
      time: '',
      id: '',
    };
  },
  watch: {
    value(newVal) {
      if (newVal.Id) {
        this.address = newVal.Address;
        this.time = newVal.SignTime;
        this.id = newVal.Id;
      } else {
        this.id = '';
        this.address = '';
        this.time = '';
      }
    },
  },
  created() {
    // 获取signature签名  不管是否过期
    this.getSignatrue();
  },
  computed: {
        ...mapGetters(['getConfigItem']),
    },
  methods: {
    async getSignatrue() {
      const data = {
        Url: window.location.href,
        Noncestr: 'otob',
        Timestamp: `${Math.round(new Date() / 1000)}`,
      };
      // 获取触发接口时的时间戳
      this.timestamp = data.Timestamp * 1;
      console.log(this.timestamp);
      try {
        const res = await axios.post(this.getConfigItem('signatureApi'), data, {
          headers: {
            authToken: sessionStorage.getItem('AuthToken'),
                userid: sessionStorage.getItem('userid'),
          },
        });
        this.signatrue = res.data.signature;
      } catch (e) {
        this.$toast.fail('初始化jssdk失败，请稍后再试');
      }
    },

    async wxLocation() {
      try {
        await wx.agentConfig({
          debug: true, // 在测试没问题之后，关闭debug模式，防止弹窗
          corpid:  this.getConfigItem('corpID'), // 必填，企业微信的corpID
          agentid: process.env.NODE_ENV === 'development' ? '1000036' : '1000035', // 必填，企业微信的corpID
          // timestamp: 1668153749, // 必填，生成签名的时间戳
          nonceStr: 'otob', // 必填，生成签名的随机串
          timestamp: this.timestamp * 1,
          signature: this.signatrue, // 必填，签名，见 附录-JS-SDK使用权限签名算法
          jsApiList: ['openEnterpriseChat'], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
          success: this.createChat,
          fail: (e) => console.log(e),
        });
      } catch (e) {
        console.log(e);
        e && alert(JSON.stringify(e));
      }
    },
    createChat() {
      try {
        wx.openEnterpriseChat({
          // 注意：userIds和externalUserIds至少选填一个。内部群最多2000人；外部群最多500人；如果有微信联系人，最多40人
          userIds: this.userIds, //参与会话的企业成员列表，格式为userid1;userid2;...，用分号隔开。
          // externalUserIds: 'wmEAlECwAAHrbWYDOK5u3Bf13xlYDAAA;wmEAlECwAAHibWYDOK5u3Af13xlYDAAA', // 参与会话的外部联系人列表，格式为userId1;userId2;…，用分号隔开。
          groupName: this.groupName || '讨论组', // 会话名称。单聊时该参数传入空字符串""即可。
          chatId: this.value || this.chatId || '', // 若要打开已有会话，需指定此参数。如果是新建会话，chatId必须为空串
          success: function (res) {
            const { chatId } = res; //返回当前群聊ID，仅当使用agentConfig注入该接口权限时才返回chatId
            this.$emit('change', chatId);
            // 回调
          },
          fail: function (res) {
            if (res.errMsg.indexOf('function not exist') > -1) {
              alert('版本过低请升级');
            }
            alert(JSON.stringify(res));
          },
        });
      } catch (e) {
        console.log(e);
        e && alert(JSON.stringify(e));
      }
    },
  },
};
</script>

<style lang="less" scoped>
.btn {
  height: 50px;
  flex: 1 0 auto;
}
.lh15 {
  line-height: 15px;
}

.desc {
  display: flex;
  flex-direction: column;
  height: 50px;
  justify-content: space-between;
}
</style>
