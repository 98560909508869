import request from '@/http/request';
import store from '@/store';

// const relayApi = store.getters.getConfigItem('relay_api');
console.log(`当前环境：${process.env.NODE_ENV}`);
console.log(`当前使用的配置中心分组：${process.env.VUE_APP_CONFIG_GROUP}`);
// console.log(`当前使用的中间层API地址：${relayApi}`);
const env = process.env.NODE_ENV === 'development' ? 'test' : 'product';

// 统一请求到nodejs接入层
export function formatApi(params) {
  return request({
    url: '/relay/ifob/api',
    method: 'POST',
    data: {
      SystemId: '2',
      ...params,
      // VueOperateUser: store.state.user.name,
    },
  });
}

export function formatOtobApi(params) {
  return request({
    url: '/relay/api',
    method: 'POST',
    data: {
      RequestJson: {
        SystemId: '2',
        ...params,
      },
      UserId: sessionStorage.getItem('userid'),
    },
  });
}

// nbrokergo转发
export function formatRApi(params) {
  return request({
    url: '/relay/rapi/nbrokergo',
    method: 'POST',
    data: {
      SystemId: '2',
      ...params,
      VueOperateUser: store.state.user.name,
    },
  });
}

// 请求到 netops-relay 接入层
export function postGoRelay(path, params) {
  return request({
    url: `/relay/rapi${path}`,
    method: 'POST',
    data: {
      SystemId: '2',
      ...params,
      VueOperateUser: store.state.user.name,
    },
  });
}

// 请求到 netops-relay 接入层
export function postRelay(path, params) {
  return request({
    url: `/relay/ifob${path}`,
    method: 'POST',
    data: {
      ...params,
      VueOperateUser: store.state.user.name,
    },
  });
}

// 请求到任意地址
export function postAny(url, params) {
  return request({
    url,
    method: 'POST',
    data: params,
  });
}

// 访问云函数
export function fetchScf(params) {
  return request({
    url: '/relay/api/antapi',
    method: 'POST',
    data: {
      SystemId: '8',
      RequestEnvironment: process.env.VUE_APP_CONFIG_GROUP === 'prod' ? 'product' : 'test',
      ...params,
      VueOperateUser: store.state.user.name,
    },
  });
}

// 访问鉴权系统
export function fetchPerm(params) {
  return request({
    url: '/relay/permission/api',
    method: 'POST',
    data: {
      Action: 'UserManageSystem',
      SystemId: '351',
      PrivSystemId: process.env.VUE_APP_CONFIG_GROUP === 'prod' ? '63' : '65',
      ...params,
    },
  });
}

// 获取用户信息
export function getLoginUserInfo() {
  return request({
    url: '/relay/user/info',
    method: 'get',
  });
}
// 新relay/api
export function formatNewOtobApi(params) {
  return request({
    url: '/relay/otob/api',
    method: 'POST',
    data: {
      SystemId: '2',
      ...params,
      UserId: sessionStorage.getItem('userid'),
    },
  });
}

// DeviceDB关联专用接口
export function formatApiDeviceDB(params) {
  return request({
    // url: `/relay/rapi/devicedb-mobile?method=${params.Action}-${params.Method}`,
    url: '/relay/rapi/devicedb-mobile',
    method: 'POST',
    data: {
      SystemId: '2',
      ...params,
      VueOperateUser: store.state.user.name,
    },
  });
}
export function formatApiDeviceDBSimple(params) {
  return request({
    // url: `/relay/rapi/devicedb-mobile?method=${params.Action}-${params.Method}[Schemald=device_simple]`,
    url: `/relay/rapi/devicedb-mobile`,
    method: 'POST',
    data: {
      SystemId: '2',
      ...params,
      VueOperateUser: store.state.user.name,
    },
  });
}
