<template>
  <div class="todo-wrapper" v-loading.lock.fullscreen="taskLoading">
    <div v-for="(todoItem, todoIndex) in todoComponent" :key="todoItem.taskId">
      <p class="section-title ml10" style="display: inline-block">已办详情</p>
      <el-tag class="ml20" type="warning">以下为已办详情，无需处理</el-tag>
      <!-- 待办信息 -->
      <div>
        <!-- <van-cell title="处理人" :value="todoItem.ProcessUsers" /> -->
        <van-cell title="创建时间" :value="todoItem.CreateTime" />
        <van-cell title="提交时间" :value="todoItem.EndTime" />
        <!-- <div v-for="(infoItem) in todoItem.nodeBasicInfo" :key="infoItem.name"> -->
        <van-cell
          v-show="
            infoItem.data &&
            infoItem.name &&
            !infoItem.name.includes('流程ID') &&
            infoItem.name !== '任务ID'
          "
          v-for="infoItem in todoItem.nodeBasicInfo"
          :key="infoItem.name"
          :title="infoItem.name"
          :value="infoItem.data || '-'"
        />
        <!-- </div> -->
        <TodoView :todoIndex="todoIndex" :todoViewsData="todoItem.todoViewsData" />
      </div>
      <!-- 待办操作 -->
      <div v-if="todoItem.operateFormSchema && todoItem.operateFormSchema.schema">
        <todo-form
          :ref="'todo-form-' + todoItem.taskId"
          :schema="todoItem.operateFormSchema.schema"
          :disabled="true"
          :values="{
            ...todoItem.operateFormData,
            $context: {
              instanceId: todoItem.instanceId,
              parent_id: todoItem.parentId,
              parent_task_id: todoItem.ParentTaskId,
              service_name: todoItem.AjaxService,
              taskId: todoItem.taskId,
              svckey: todoItem.FlowServiceKey || 'flowable',
              user: username,
            },
          }"
        />
      </div>
      <van-form v-for="operItem in todoItem.operateViewsData" v-else :key="operItem.name">
        <van-cell
          v-if="
            operItem.type === 'KVField' ||
            (!operItem.Type && !operItem.type) ||
            operItem.Type === 'KVField'
          "
          :label="operItem.name ? operItem.name + ':' : operItem.KeyName + ':'"
          label-width="120px"
          :value="operItem.data || operItem.Value"
        />

        <!-- input类型 -->
        <van-field
          v-else-if="operItem.type === 'TextArea'"
          v-model="operItem.data"
          type="textarea"
          :label="operItem.name + ':'"
        />

        <!-- link类型 兼容流程中间数据 -->
        <van-cell
          v-else-if="operItem.type === 'Link' || operItem.Type === 'Link'"
          :label="operItem.KeyName ? operItem.KeyName + ':' : operItem.name + ':'"
        >
          <el-link
            v-if="operItem.Value"
            :href="operItem.Value"
            class="f12"
            type="primary"
            target="_blank"
          >
            {{ operItem.Value }}
          </el-link>
          <el-link v-else :href="operItem.data" class="f12" type="primary" target="_blank">
            {{ operItem.data }}
          </el-link>
        </van-cell>

        <van-field
          readonly
          v-else-if="operItem.type === 'Radio'"
          :label="operItem.name ? operItem.name + ':' : ''"
        >
          <template #input>
            <van-radio-group v-model="operItem.data" class="flex-h-between w">
              <van-radio v-for="radio in operItem.options" :key="radio.name" :name="radio.data">
                {{ radio.name }}
              </van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          readonly
          v-else-if="operItem.type === 'Checkbox'"
          :label="operItem.name ? operItem.name + ':' : ''"
        >
          <template #input>
            <van-checkbox-group v-model="operItem.data" class="flex-h-between w">
              <van-checkbox v-for="checkbox in operItem.options" :key="checkbox.name" :name="checkbox.data">
                {{ checkbox.name }}
              </van-checkbox>
            </van-checkbox-group>
          </template>
        </van-field>
        <van-field
          v-else-if="operItem.type === 'RichEditor'"
          :label="operItem.name ? operItem.name + ':' : ''"
        >
            <quill-editor v-model="operItem.data" />
        </van-field>
      </van-form>
    </div>
    <van-divider><span v-if="operator">该待办已经由{{ operator }}提交</span><span v-else>该待办已结束，无需处理</span></van-divider>
  </div>
</template>
<script>
import { findIndex, cloneDeep } from 'lodash';
import { mapGetters } from 'vuex';
import UserSelect from '@/components/UserSelect';
import {
  getDataByNbroker,
  handoverTodo,
  queryNowN4,
  queryAllN4,
  getDataByNbrokerUrl,
} from '@/api/tickets/ticketsApi';
import {
  getTodoHistoryListByTicket,
} from '@/api/tickets/todoApi';
import { getFlowLogById, getSchemeDefinition } from '@/api/appDispose/planApi';
import TodoForm from '@/components/Formily/formilyTodoForm.vue';
import TodoView from './todoView.vue';
import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';


export default {
  name: 'CommonTodo',
  components: { TodoForm, UserSelect, TodoView, quillEditor },
  mounted() {
    // this.getFlowHistory(this.instanceId);
    this.getTodoDataList();
  },
  computed: {
    ...mapGetters(['getConfigItem']),
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    if (this.saver) {
      clearInterval(this.saver);
    }
  },
  data() {
    return {
      taskLoading: true,
      todoList: [],
      todoComponent: [],
      transferDialog: false,
      transferPerson: {
        name: '',
      },
      operator: '',
      schemeManager: '',
      currTodo: {},
      timer: null,
      nodeList: [],
      localData: [],
      fullscreenLoading: false,
      transDisabled: false,
      username: sessionStorage.getItem('userid'),
      flowLogData: [],
      todoTimer: [],
      // sessionStorage
    };
  },
  props: {
    ticketId: {
      type: String,
      default: '',
    },
    instanceId: {
      type: String,
      default: '',
    },
    ticketStatus: {
      type: String,
      default: '',
    },
    taskId: {
      type: String,
      default: '',
    },
  },
  methods: {
    isUserHasAuth() {
      this.todoComponent.forEach((todoItem) => {
        const nowUser = this.user.name;
        // 如果是配置了管理员，则管理员拥有所有权限
        if (this.schemeManager && this.schemeManager.includes(nowUser)) {
          todoItem.disabled = false;
        }
        // 如果是之前没有配置权限的待办，则不做权限限制
        if (!todoItem.todoRole.name) {
          todoItem.disabled = false;
        }
        // 如果是之前没有配置权限的待办，则不做权限限制
        if (todoItem.todoRole.name === 'no') {
          todoItem.disabled = false;
        }
        if (todoItem.todoRole.name === 'onlyDealer') {
          const nowUser = this.user.name;
          const pattern = /\(.+\)/;
          let users = todoItem.ProcessUsers;
          // 字符串情况统一处理成数组
          if (typeof todoItem.ProcessUsers === 'string') {
            users = todoItem.ProcessUsers.split(';');
          }
          users.forEach((user) => {
            if (user.replace(pattern, '') === nowUser) {
              todoItem.disabled = false;
            }
          });
        } else if (todoItem.todoRole.name === 'onlyNowN4') {
          const nowUser = this.user.name;
          queryNowN4().then((res) => {
            const users = res.data.FuncResult.Data;
            if (users.includes(nowUser)) {
              todoItem.disabled = false;
            }
          });
        } else if (todoItem.todoRole.name === 'allN4') {
          const nowUser = this.user.name;
          queryAllN4().then((res) => {
            const users = res.data.FuncResult.Data;
            if (users.includes(nowUser)) {
              todoItem.disabled = false;
            }
          });
        } else if (todoItem.todoRole.name === 'perm') {
          const nowUser = this.user.name;
          // const positionName = todoItem.todoRole.authProp.positionName;
          const { className } = todoItem.todoRole.authProp;
          for (let i = 0; i < className.length; i++) {
            const queryParams = {
              // PositionName: positionName,
              PositionName: 'Flowable待办权限角色控制',
              Properties: {
                待办分组名称: className[i],
              },
            };
            queryUserListByRole(queryParams).then((res) => {
              const users = res.data.Result;
              if (users.includes(nowUser)) {
                todoItem.disabled = false;
              }
            });
          }
        }
      });
    },
    handleCheckAllChange(checked, todoIndex, opIndex) {
      // 这里支持点击全选框后一键全选和反选
      this.todoComponent[todoIndex].operateViewsData[opIndex].data = checked
        ? this.todoComponent[todoIndex].operateViewsData[opIndex].options.map(x => x.data)
        : [];
      this.isIndeterminate = false;
    },

    // 获取待办信息
    getTodoDataList() {
        this.getTodoTaskData();
      
    },

    // 获取流程历史
    async getFlowHistory(instanceId) {
      try {
        const result = await getFlowLogById(instanceId, true);
        this.flowLogData = result.data;
        if (this.ticketStatus === 'END' && this.isForceEnd === '1') {
          this.flowLogData.push({
            TaskName: '流程结束(强制结单)',
            TaskStatus: '已完成',
            Note: this.forceEndReason,
            Operator: this.forceEndOperator,
            CreateTime: this.endTime,
            EndTime: this.endTime,
          });
        }
      } catch (err) {}
    },

    // 获取该工单下的待办数据
    async getTodoTaskData() {
      this.loadingTask = true;
      try {
        const result = await getTodoHistoryListByTicket({ TicketId: this.ticketId });

        const [data] = result.data.List;
        // 没有data，则该单是已结单状态
        if (data) {
          const todoList = data.HistoryList;
          if (todoList.length) {
            this.todoList = todoList;
            this.operator = this.todoList?.[0]?.RealOperator
            // 去掉完成的待办
            const list = todoList.filter(item => item.TaskId === this.taskId);
            for (let i = 0; i < list.length; i++) {
              const todo = list[i];
              const schemeQueryData = {
                ResultColumns: {
                  FlowData: '',
                  Manager: '',
                },
                SearchCondition: {
                  SchemeName: todo.ProcessDefinitionKey,
                },
                Sorts: [],
                Limit: {
                  Size: 20,
                  Start: 0,
                },
              };
              const schemeFlowData = await getSchemeDefinition(schemeQueryData);
              await this.getTodoTaskConfig(todo, i, this.todoAuthRole);
              this.schemeManager = schemeFlowData.data.List[0].Manager;
              const { nodeList } = schemeFlowData.data.List[0].FlowData.childShapes;
              const todoAuthRole = {
                name: '',
                authProp: {
                  positionName: '',
                  className: '',
                },
              };
              for (let j = 0; j < nodeList.length; j++) {
                if (nodeList[j].id === todo.TaskKey) {
                  if (nodeList[j].properties.authPermRole) {
                    // this.todoAuthRole.name = nodeList[j].properties.authPermRole;
                    todoAuthRole.name = nodeList[j].properties.authPermRole;
                  }
                  if (nodeList[j].properties.positionName) {
                    // this.todoAuthRole.authProp.positionName = nodeList[j].properties.positionName;
                    todoAuthRole.authProp.positionName = nodeList[j].properties.positionName;
                  }
                  if (nodeList[j].properties.className) {
                    // this.todoAuthRole.authProp.className = nodeList[j].properties.className;
                    todoAuthRole.authProp.className = nodeList[j].properties.className;
                  }
                  break;
                }
              }
              // if (!this.todoComponent.some(item => item.taskId === todo.TaskId)) {
              //   await this.getTodoTaskConfig(todo, i, todoAuthRole);
              // }
            }
            this.isUserHasAuth();
          } else {
            this.todoComponent = [];
          }
        } else {
          this.todoComponent = [];
        }
      } catch (err) {
      }
      this.loadingTask = false;
    },

    // 通过nbroker取得配置中心的待办信息
    async getTodoTaskConfig(todoData, index, todoRole) {
      try {
        let todoConfigResult = {};
        const params = {
          context: {
            service_name: todoData.AjaxService,
            method_name: 'query',
            instanceId: todoData.InstanceId,
            taskId: todoData.TaskId,
            parent_id: todoData.ParentInstanceId,
            parent_task_id: todoData.ParentTaskId,
            svckey: todoData.FlowServiceKey || 'flowable',
          },
          args: {},
        };
        // 这里不再直接通过http请求的方式访问nBroker接口，而是通过relay进行转发
        if (todoData.HandleUrl) {
          // todoConfigResult = await axios.post(todoData.HandleUrl, params);
          todoConfigResult = await getDataByNbrokerUrl(params, todoData.HandleUrl);
        } else {
          todoConfigResult = await getDataByNbroker(params);
        }

        const { result, exc_info: errInfo } = todoConfigResult.data;
        if (errInfo) {
          throw new Error(JSON.stringify(errInfo));
        }
        if (result.operate_views) {
          for (let i = 0; i < result.operate_views.length; i++) {
            const operateItem = result.operate_views[i];
            if (operateItem.type == 'Select' && operateItem.options_url != '') {
              result.operate_views[i] = await this.getSelectRemoteData(
                operateItem,
                operateItem.options_url,
              );
            }
            if (operateItem.type == 'Upload' && !Array.isArray(operateItem.data)) {
              result.operate_views[i].data = [];
            }
          }
        }
        this.handleTodoComponentData(result, todoData, index, todoRole);
      } catch (err) {
        console.log(err)
        // this.$message({
        //   type: 'error',
        //   message: `从nbroker获取待办配置信息接口出错：${err.message}`,
        // });
      } finally {
        this.taskLoading = false
      }
    },

    // 处理配置中心返回的待办处理数据
    handleTodoComponentData(protocol, todoData, index, todoRole) {
      if (protocol) {
        const todoTmp = {
          nodeBasicInfo: [],
          todoViewsData: [],
          operateFormSchema: {},
          operateFormData: {},
          nodeButton: [],
          operateViewsData: [],
        };
        todoTmp.taskId = todoData.TaskId;
        todoTmp.todoName = todoData.TodoName;
        todoTmp.taskName = todoData.TaskName;
        todoTmp.ProcessUsers = todoData.ProcessUsers;
        todoTmp.CreateTime = todoData.CreateTime;
        todoTmp.EndTime = todoData.EndTime;
        todoTmp.ParentTaskId = todoData.ParentTaskId;

        todoTmp.nodeBasicInfo = protocol.basic_views;
        const taskName = this.getParentTaskName(todoData.ParentTaskId);
        todoTmp.nodeBasicInfo.push({
          data: taskName,
          name: '父节点名称',
        });
        todoTmp.todoViewsData = protocol.todo_views;

        protocol.operate_views.forEach((item) => {
          if (item.type === 'Button') {
            todoTmp.nodeButton.push(item);
          } else if (item.type === 'upload') {
            todoTmp.nodeButton.push(item);
          } else if (item.type === 'download') {
            todoTmp.nodeButton.push(item);
          } else {
            todoTmp.operateViewsData.push(item);
          }
        });
        if (protocol.operate_form_schema) {
          todoTmp.operateFormSchema = protocol.operate_form_schema;
          todoTmp.operateFormData = protocol.operate_form_data;
        }
        todoTmp.todoRole = todoRole;
        todoTmp.disabled = true;

        this.todoComponent.splice(index, 0, todoTmp);
      }
    },
    getParentTaskName(id) {
      let res = '';
      this.flowLogData.map((item) => {
        if (item.TaskId == id) {
          res = item.TaskName;
        }
      });
      return res;
    },

    onCopy() {
      this.$message.success('复制成功！');
    },

    // 提交待办操作
    async submitOperation(item, todoIndex) {
      const currTodo = this.todoList[todoIndex];
      const currTodoComponent = this.todoComponent[todoIndex];
      let processData = {};
      if (currTodoComponent.operateFormSchema && currTodoComponent.operateFormSchema.schema) {
        const todoForm = this.$refs[`todo-form-${currTodo.TaskId}`][0];
        try {
          await todoForm.validate();
        } catch (e) {
          this.$message.error('请填写必填项后再提交');
          return;
        }
        processData = todoForm.getValues();
      } else {
        currTodoComponent.operateViewsData.forEach((operateItem) => {
          processData[operateItem.key] = operateItem.data;
        });
      }
      // 待办数据
      const params = {
        context: {
          instanceId: currTodo.InstanceId,
          method_name: item.callback_method,
          parent_id: currTodo.ParentInstanceId,
          parent_task_id: currTodo.ParentTaskId,
          service_name: currTodo.AjaxService,
          taskId: currTodo.TaskId,
          svckey: currTodo.FlowServiceKey || 'flowable',
        },
        args: {
          process_user: this.$store.state.user.name,
          process_data: processData,
        },
      };

      // 待办提交弹窗
      let dataMsg = '';
      Object.keys(processData).forEach((key) => {
        if (typeof processData[key] === 'object') {
          return;
        }
        dataMsg += `<p> <b>${key} : ${processData[key]}</b> </p>`;
      });
      const msg = `
          <p>待办名称：${currTodo.TodoName}</p>
          <p>待办提交数据：${dataMsg}</p>
          <br>
          <p>请确认操作的数据 <b>是否正确</b> 后提交！</p>`;
      this.$confirm('是否确认提交？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          this.todoCommit(params, currTodo.HandleUrl, todoIndex);
        })
        .catch(() => {});
    },

    // 上传操作
    async uploadOperation(item, todoIndex) {
      const currTodo = this.todoList[todoIndex];
      const currTodoComponent = this.todoComponent[todoIndex];
      let processData = {};
      if (currTodoComponent.operateFormSchema && currTodoComponent.operateFormSchema.schema) {
        const todoForm = this.$refs[`todo-form-${currTodo.TaskId}`];
        try {
          await todoForm.validate();
        } catch (e) {
          this.$message.error('请填写必填项后再提交');
          return;
        }
        processData = todoForm.getValues();
      } else {
        currTodoComponent.operateViewsData.forEach((operateItem) => {
          processData[operateItem.key] = operateItem.data;
        });
      }

      const params = {
        context: {
          instanceId: currTodo.InstanceId,
          method_name: item.callback_method,
          parent_id: currTodo.ParentInstanceId,
          parent_task_id: currTodo.ParentTaskId,
          service_name: currTodo.AjaxService,
          taskId: currTodo.TaskId,
          svckey: currTodo.FlowServiceKey || 'flowable',
        },
        args: {
          process_user: this.$store.state.user.name,
          process_data: this.fileList,
        },
      };

      // 待办上传弹窗
      Object.keys(processData).forEach((key) => {
        if (typeof processData[key] === 'object') {
          return;
        }
      });
      this.$confirm('操作提示！', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          this.todoCommitUpload(params, currTodo.HandleUrl, todoIndex);
        })
        .catch(() => {});
    },

    // 下载操作
    async downloadOperation(item, todoIndex) {
      const currTodo = this.todoList[todoIndex];
      const currTodoComponent = this.todoComponent[todoIndex];
      let processData = {};
      if (currTodoComponent.operateFormSchema && currTodoComponent.operateFormSchema.schema) {
        const todoForm = this.$refs[`todo-form-${currTodo.TaskId}`];
        try {
          await todoForm.validate();
        } catch (e) {
          this.$message.error('请填写必填项后再提交');
          return;
        }
        processData = todoForm.getValues();
      } else {
        currTodoComponent.operateViewsData.forEach((operateItem) => {
          processData[operateItem.key] = operateItem.data;
        });
      }
      const params = {
        context: {
          instanceId: currTodo.InstanceId,
          method_name: item.callback_method,
          parent_id: currTodo.ParentInstanceId,
          parent_task_id: currTodo.ParentTaskId,
          service_name: currTodo.AjaxService,
          taskId: currTodo.TaskId,
          svckey: currTodo.FlowServiceKey || 'flowable',
        },
        args: {
          process_user: this.$store.state.user.name,
          process_data: processData,
        },
      };

      // 待办下载弹窗
      let dataMsg = '';
      Object.keys(processData).forEach((key) => {
        if (typeof processData[key] === 'object') {
          return;
        }
        dataMsg += `<p> <b>${key} : ${processData[key]}</b> </p>`;
      });
      const msg = `
          <p>待办名称：${currTodo.TodoName}</p>
          <p>待办下载 数据：${dataMsg}</p>
          <br>
          <p>请确认下载的数据 <b>是否正确</b> 后提交！</p>`;
      this.$confirm(msg, '操作提示！', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          this.todoCommitDownload(params, currTodo.HandleUrl, todoIndex);
        })
        .catch(() => {});
    },

    // 点击转派
    showHandoverTodo(todoIndex) {
      this.transferDialog = true;
      this.currTodo = this.todoList[todoIndex];
    },

    // 提交转派
    submitTransferForm() {
      this.$refs.transferForm.validate(async (valid) => {
        try {
          if (valid) {
            await handoverTodo(
              this.transferPerson.name,
              this.$store.state.user.name,
              this.currTodo.TaskId,
            );
            this.transferDialog = false;
            this.$message.success('转派成功');
            this.refreshOneTodo(findIndex(this.todoComponent, { taskId: this.currTodo.TaskId }));
          }
        } catch (err) {}
      });
    },

    // 操作提交待办
    async todoCommit(params, url, index) {
      try {
        let queryResult = {};
        if (url) {
          // todo 这里修改待办的操作接口
          // queryResult = await axios.post(url, params);
          queryResult = await getDataByNbrokerUrl(params, url);
        } else {
          queryResult = await getDataByNbroker(params);
        }
        const { exc_info: excInfo } = queryResult.data;

        if (excInfo) {
          throw new Error(excInfo.traceback ? excInfo.traceback : excInfo);
        }
        this.$message.success('待办操作成功！');
        this.refreshOneTodo(index);
      } catch (err) {
        this.$message.error(`待办操作失败: ${err.message}`);
      }
    },

    // 操作上传待办
    async todoCommitUpload(params, url, index) {
      try {
        let queryResult = {};
        if (url) {
          // todo 这里修改待办的操作接口
          // queryResult = await axios.post(url, params);
          queryResult = await getDataByNbrokerUrl(params, url);
        } else {
          queryResult = await getDataByNbroker(params);
        }
        const { exc_info: excInfo } = queryResult.data;

        if (excInfo) {
          throw new Error(excInfo.traceback ? excInfo.traceback : excInfo);
        }
        this.$message.success('待办操作成功！');
        this.refreshOneTodo(index);
      } catch (err) {
        this.$message.error(`待办操作失败: ${err.message}`);
      }
    },

    // 操作下载待办
    async todoCommitDownload(params, url, index) {
      try {
        let queryResult = {};
        if (url) {
          // todo 这里修改待办的操作接口
          // queryResult = await axios.post(url, params);
          queryResult = await getDataByNbrokerUrl(params, url);
        } else {
          queryResult = await getDataByNbroker(params);
        }
        const { exc_info: excInfo } = queryResult.data;

        const file = new File([queryResult.data.result.data], queryResult.data.result.file_name, {
          type: 'text/plain',
        });
        if (
          file != '.jpg'
          && file != '.gif'
          && file != '.jpeg'
          && file != '.png'
          && file != '.bmp'
          && file != '.txt'
          && file != '.docx'
          && file != '.zip'
        ) {
          const tmpLink = document.createElement('a');
          const objectUrl = URL.createObjectURL(file);

          tmpLink.href = objectUrl;
          tmpLink.download = file.name;
          document.body.appendChild(tmpLink);
          tmpLink.click();

          document.body.removeChild(tmpLink);
          URL.revokeObjectURL(objectUrl);
        }

        if (excInfo) {
          throw new Error(excInfo.traceback ? excInfo.traceback : excInfo);
        }
        this.$message.success('待办操作成功！');
        this.refreshOneTodo(index);
      } catch (err) {
        this.$message.error(`待办操作失败: ${err.message}`);
      }
    },

    // 刷新某一条待办
    refreshOneTodo(index) {
      this.todoComponent.splice(index, 1);
      this.getTodoDataList();
    },

    // 到flowable查看流程变量
    checkFlowVar(id) {
      const gnetopsUrl = this.getConfigItem('gnetops_url');
      const url = `${gnetopsUrl}/console/index/index/3?ID=${id}&svckey=${this.schemeData.FlowServiceKey}`;
      window.open(url, '_blank');
    },
  },
};
</script>
<style scoped>
.todo-wrapper {
  width: 100vw !important;
  margin: 0;
  padding: 0;
}
</style>
