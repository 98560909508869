<!-- 网络资产出库计件表格 -->
<template>
  <div class="content" v-if="isShow">
    <el-tag type="warning">没勾选数据点击删除功能,默认为全部删除</el-tag>
    <div class="table">
      <el-table
        v-loading="is_loading"
        key="tableKey"
        ref="tableData"
        :data="pageData"
        tooltip-effect="dark"
        style="width: 100%"
        border
        :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
        @selection-change="onSelect"
      >
        <el-table-column type="selection" min-width="40" :show-overflow-tooltip="true" fixed="left">
        </el-table-column>
        <el-table-column
          prop="seqId"
          label="序号"
          min-width="60"
          :show-overflow-tooltip="true"
          fixed="left"
          align="center"
        >
        </el-table-column
        ><el-table-column
          prop="assets_type"
          min-width="130"
          label="资产类型"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column
        ><el-table-column
          prop="mode"
          min-width="80"
          label="管理方式"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column
        ><el-table-column
          prop="manufacturer"
          min-width="90"
          label="厂商"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column
        ><el-table-column
          prop="pn"
          min-width="150"
          label="型号"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column
        ><el-table-column
          prop="nowinLib"
          min-width="100"
          label="现部门"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column
        ><el-table-column
          prop="inLib"
          min-width="90"
          label="逻辑库"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="dept_name"
          min-width="100"
          label="部门"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="outway"
          min-width="90"
          label="出库方式"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column
        ><el-table-column
          prop="assetId"
          min-width="150"
          label="固资"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column
        ><el-table-column
          prop="sn"
          min-width="150"
          label="SN"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="amount"
          min-width="65"
          label="申请数量"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="scan_sn"
          min-width="200"
          label="扫描SN"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="type"
          min-width="100"
          label="类型"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
      </el-table>
    </div>

    <div class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
        :current-page="currentPage"
        :page-sizes="pageSize"
        @size-change="changeSize"
        @current-change="changePage"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    context: {
      type: Object,
      default: () => ({}),
    },
    toggle: {
      type: Boolean,
      default: false,
    },
    is_loading: {
      type: Boolean,
      default: false,
    },
    component_counts: {
      type: Number,
      default: 1,
    },
    complete_machine_counts: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      tableData: [],
      quantityStorage: 0,
      currentPage: 1,
      pageSize: [50, 100, 150],
      pageData: [],
      isShow: true,
      Size: 50,
    };
  },

  watch: {
    value: {
      handler(val) {
        this.$nextTick(() => {
          this.tableData = [...val];
          this.isShow =
            this.component_counts == 0 && this.complete_machine_counts == 0 ? false : true;
          this.changePage(this.currentPage);
        });
      },
      deep: true,
    },
    toggle: {
      handler() {
        this.$nextTick(() => {
          this.$refs.tableData.doLayout();
          this.tableData = [...this.tableData];
          this.changePage(this.currentPage);
        });
      },
      deep: true,
      immediate: false,
    },
  },
  mounted() {
    this.$emit('selection-change', this.tableData);
  },
  methods: {
    onSelect(val) {
      this.$emit('selection-change', val);
    },

    changePage(page) {
      this.currentPage = page;
      const startIndex = (this.currentPage - 1) * this.Size;
      const endIndex = startIndex + this.Size;
      this.pageData = this.tableData.slice(startIndex, endIndex);
    },
    changeSize(size) {
      this.Size = size;
      this.changePage(1);
    },
  },
};
</script>

<style scoped lang="less">
.content {
  .table {
    width: 100%;
    overflow-x: auto;
  }
  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
