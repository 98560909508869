var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.todoViewsData),function(viewItem){return _c('div',{key:viewItem.name},[(
        viewItem.type === 'KVField' ||
        (!viewItem.Type && !viewItem.type) ||
        viewItem.Type === 'KVField'
      )?_c('van-cell',{attrs:{"title":viewItem.name ? viewItem.name + ':' : viewItem.KeyName + ':',"label-width":"120px","value":viewItem.data || viewItem.Value}}):(viewItem.type === 'TextArea')?_c('van-field',{attrs:{"readonly":"","type":"textarea","autosize":"","label":viewItem.name + ':'},model:{value:(viewItem.data),callback:function ($$v) {_vm.$set(viewItem, "data", $$v)},expression:"viewItem.data"}}):(viewItem.type === 'Link' || viewItem.Type === 'Link')?_c('van-cell',{attrs:{"is-link":"","title":viewItem.KeyName ? viewItem.KeyName + ':' : viewItem.name + ':'}},[(viewItem.Value)?_c('a',{staticClass:"f12",attrs:{"href":viewItem.Value,"type":"primary","target":"_blank"}},[_vm._v(" "+_vm._s(viewItem.Value)+" ")]):_c('a',{staticClass:"f12",attrs:{"href":viewItem.data,"type":"primary","target":"_blank"}},[_vm._v(" "+_vm._s(viewItem.data)+" ")])]):(viewItem.type === 'Json' || viewItem.Type === 'Json')?_c('van-field',{attrs:{"readonly":"","label-width":"0"},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"w"},[_c('div',{staticClass:"mb10"},[_vm._v(_vm._s(viewItem.KeyName ? viewItem.KeyName : viewItem.name))]),_c('MonacoJsonEditor',{staticStyle:{"width":"100%","height":"100px"},attrs:{"content":_vm.getJSON(viewItem.Value ? viewItem.Value : viewItem.data),"editorOption":{
              theme: 'vs',
              readOnly: true,
              fontSize: 13,
            }}})],1)]},proxy:true}],null,true)}):(viewItem.type === 'Table' || viewItem.Type === 'Table')?_c('van-field',{staticStyle:{"padding":"0"},attrs:{"label-width":"0"},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('van-collapse',{staticClass:"w",attrs:{"accordion":"","value":viewItem.openStatus ? 'detail' : ''},on:{"change":function($event){return _vm.handleTableChange($event, viewItem)}}},[_c('van-collapse-item',{attrs:{"name":"detail"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"mb10 mr20"},[_vm._v(_vm._s(viewItem.KeyName ? viewItem.KeyName : viewItem.name))]),_c('span',{staticClass:"color-brand"},[_vm._v("查看详情")])]},proxy:true}],null,true)},[_c('div',{staticClass:"w"},[(Array.isArray(viewItem.data) && !viewItem.Value)?_c('el-table',{staticClass:"w",attrs:{"data":viewItem.data,"header-cell-style":{ background: '#eef1f6', color: '#606266' },"border":"","width":"100%"}},_vm._l((viewItem.data[0]),function(val,key){return _c('el-table-column',{key:key,attrs:{"prop":key,"label":key,"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('div',{class:_vm.checkLineWrap(row[key])},[_vm._v(" "+_vm._s(row[key])+" ")])]}}],null,true)})}),1):_c('el-table',{attrs:{"data":JSON.parse(viewItem.Value),"header-cell-style":{ background: '#eef1f6', color: '#606266' },"border":""}},_vm._l((JSON.parse(viewItem.Value)[0]),function(val,key){return _c('el-table-column',{key:key,attrs:{"prop":key,"label":key,"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('div',{class:_vm.checkLineWrap(row[key])},[_vm._v(" "+_vm._s(row[key])+" ")])]}}],null,true)})}),1)],1)])],1)]},proxy:true}],null,true)}):(viewItem.type === 'OrderTable' || viewItem.Type === 'OrderTable')?_c('van-field',{attrs:{"label-width":"0"},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('van-collapse',{staticClass:"w",attrs:{"accordion":"","value":viewItem.openStatus ? 'detail' : ''},on:{"change":function($event){return _vm.handleTableChange($event, viewItem)}}},[_c('van-collapse-item',{attrs:{"name":"detail"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"mb10 mr20"},[_vm._v(_vm._s(viewItem.KeyName ? viewItem.KeyName : viewItem.name))]),_c('span',{staticClass:"color-brand"},[_vm._v("查看详情")])]},proxy:true}],null,true)},[_c('div',{staticClass:"w"},[(Array.isArray(viewItem.data) && !viewItem.Value)?_c('el-table',{staticClass:"w",attrs:{"data":viewItem.data,"header-cell-style":{ background: '#eef1f6', color: '#606266' },"border":"","width":"100%"}},_vm._l((viewItem.header),function(key,index){return _c('el-table-column',{key:index,attrs:{"prop":key,"label":key,"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('div',{class:_vm.checkLineWrap(row[key])},[_vm._v(" "+_vm._s(row[key])+" ")])]}}],null,true)})}),1):_c('el-table',{attrs:{"data":JSON.parse(viewItem.Value).detail,"header-cell-style":{ background: '#eef1f6', color: '#606266' },"border":""}},_vm._l((JSON.parse(viewItem.Value).header),function(key,index){return _c('el-table-column',{key:index,attrs:{"prop":key,"label":key,"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('div',{class:_vm.checkLineWrap(row[key])},[_vm._v(" "+_vm._s(row[key])+" ")])]}}],null,true)})}),1)],1)])],1)]},proxy:true}],null,true)}):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }