import { formatApi } from '@/api/baseApi';

// 查询页面模版
export async function searchTemplate(conditions) {
  const params = {
    Action: 'QueryData',
    Method: 'run',
    SystemId: '2',
    SchemaId: 'page_template',
    ReturnTotalRows: 1,
    Data: {
      ResultColumns: {
        CreateTime: '',
        Creator: '',
        AdminList: '',
        ApiUrl: '',
        Parameters: '',
        Introduction: '',
        TemplateName: '',
        TemplateKey: '',
        Id: '',
        TemplateParams: '',
        TemplateStatus: '',
        TemplateStyle: '',
        UpdateTime: '',
      },
      Sorts: [
        {
          Column: 'Id',
          SortType: 'desc',
        },
      ],
      ...conditions,
    },
  };
  const { data } = await formatApi(params);
  return data;
}

// 新增页面模版
export async function addTemplate(Columns, Operator) {
  const params = {
    Action: 'OperateData',
    Method: 'run',
    SystemId: '2',
    SchemaId: 'page_template',
    OpType: 'Add',
    AppendInfo: { Operator },
    Columns: { TemplateParams: '{}', ...Columns  },
  };
  const { data } = await formatApi(params);
  return data;
}

// 更新页面模版
export async function updateTemplate(Columns, Operator) {
  const params = {
    Action: 'OperateData',
    Method: 'run',
    SystemId: '2',
    SchemaId: 'page_template',
    OpType: 'Update',
    AppendInfo: { Operator },
    Columns: { TemplateParams: '{}', ...Columns },
    Conditions: { Id: Columns.Id },
  };
  const { data } = await formatApi(params);
  return data;
}

// 删除页面模版
export async function deleteTemplate(Id, Operator) {
  const params = {
    Action: 'OperateData',
    Method: 'run',
    SystemId: '2',
    SchemaId: 'page_template',
    OpType: 'Delete',
    AppendInfo: { Operator },
    Conditions: { Id },
  };
  const { data } = await formatApi(params);
  return data;
}

// 同步至正式环境
export async function pushTemplate(TemplateId) {
  const params = {
    Action: 'PageTemplate',
    Method: 'PageTemplateToProductEnv',
    SystemId: '2',
    TemplateId,
  };
  const { data } = await formatApi(params);
  return data;
}

// 查询页面
export async function searchPage(conditions) {
  const params = {
    Action: 'QueryData',
    Method: 'run',
    SystemId: '2',
    SchemaId: 'page_template_generate',
    ReturnTotalRows: 1,
    Data: {
      ResultColumns: {
        CreateTime: '',
        GenerateUrl: '',
        Id: '',
        RelateApplication: '',
        RelateTicketUrl: '',
        ShowTimes: '',
        TemplateId: '',
        TemplateParams: '',
        TemplateStyle: '',
        UpdateTime: '',
        Creator: '',
        TemplateName: '',
      },
      Sorts: [
        {
          Column: 'Id',
          SortType: 'desc',
        },
      ],
      ...conditions,
    },
  };
  const { data } = await formatApi(params);
  return data;
}

// 更新页面
export async function updatePage(Columns, Operator) {
  const params = {
    Action: 'OperateData',
    Method: 'run',
    SystemId: '2',
    SchemaId: 'page_template_generate',
    OpType: 'Update',
    AppendInfo: { Operator },
    Columns: { ...Columns, TemplateParams: '{"content": "xxx"}' },
    Conditions: { Id: Columns.Id },
  };
  const { data } = await formatApi(params);
  return data;
}

// 删除页面
export async function deletePage(Id, Operator) {
  const params = {
    Action: 'OperateData',
    Method: 'run',
    SystemId: '2',
    SchemaId: 'page_template_generate',
    OpType: 'Delete',
    AppendInfo: { Operator },
    Conditions: { Id },
  };
  const { data } = await formatApi(params);
  return data;
}

// 渲染页面
export async function renderPage(pageData) {
  const params = {
    Action: 'PageTemplate',
    Method: 'RenderingTemplate',
    SystemId: 1,
    ...pageData,
  };
  const { data } = await formatApi(params);
  return data;
}

// 增加显示次数
export async function addPageCount(PageId) {
  const params = {
    Action: 'PageTemplate',
    Method: 'SetPageShowTime',
    SystemId: 1,
    PageId,
  };
  const { data } = await formatApi(params);
  return data;
}

