import router from './router/router.js';
import store from './store';

const { getters, dispatch, commit, state } = store;
const { config } = state;

const AEGIS = {
  test: 'EPqQ3cr2gqdnVJ4m6w',
  prod: '8lqYGtoyxbkqx51mw3',
};

const aegis = new Aegis({
  id: getters.getConfigItem('wechat_aegis_key'), // 上报 id
  uin: sessionStorage.getItem('userid') || undefined, // 用户唯一 ID（可选）
  reportApiSpeed: true, // 接口测速
  reportAssetSpeed: true, // 静态资源测速
  spa: true, // spa 应用页面跳转的时候开启 pv 计算
  hostUrl: 'https://rumt-zh.com',
});

router.beforeEach(async (to, from, next) => {
  // 从七彩石配置中心拉取配置
  document.title = getPageTitle(to.meta && to.meta.title);
  if (!config.group && !!sessionStorage.getItem('userid')) {
    try {
      await dispatch('config/getConfigFromRainbow');
      aegis.setConfig({
        id: getters.getConfigItem('wechat_aegis_key') || AEGIS[process.env.VUE_APP_CONFIG_GROUP], // 上报 id
        uin: sessionStorage.getItem('userid') || undefined,
      });
    } catch (e) {}
  }

  next();
});

const title = '腾讯工单';

const getPageTitle = (pageTitle) => (pageTitle ? `${pageTitle} - ${title}` : title);
